import { MouseEvent } from 'react';
import Supercluster from 'supercluster';

import { DiseaseOutbreak, Outbreak } from './disease';

export type MarkerClickHandler<T extends Marker<unknown>> = (
  event: MouseEvent<HTMLButtonElement>,
  data: T | T[]
) => void;

export const isCluster = <C, P>(
  cluster: Supercluster.ClusterFeature<C> | Supercluster.PointFeature<P>
): cluster is Supercluster.ClusterFeature<C> =>
  (cluster as Supercluster.ClusterFeature<C>).properties.cluster;

export interface Marker<A extends unknown> {
  lat: number;
  lng: number;
  countryId: string;
  countryName: string;
  data: A;
  stateName?: string;
}

export interface LayerInfo {
  source: string;
  sourceLayer?: string;
}

export type DiseaseOutbreaksData = Marker<DiseaseOutbreak>;

export type OutbreaksData = Marker<Outbreak>;

export interface MapPoint<A extends unknown, T extends Marker<A>> {
  point: T;
}

export interface MapCluster<A extends unknown, T extends Marker<A>> {
  points: T[];
}

export interface Viewport {
  zoom: number;
  longitude: number;
  latitude: number;
  width: number;
  height: number;
}

export const isOutbreaksData = (value: unknown): value is OutbreaksData => {
  return (
    !!value &&
    typeof value === 'object' &&
    !!(value as OutbreaksData).lat &&
    !!(value as OutbreaksData).lng &&
    !!(value as OutbreaksData).countryId &&
    !!(value as OutbreaksData).countryName &&
    Array.isArray((value as OutbreaksData).data)
  );
};

export const isOutbreaksStateArrayData = (value: unknown): value is OutbreaksData[] => {
  return Array.isArray(value) && value.every(v => (v as OutbreaksData).stateName);
};
