import { Span } from 'Atoms/text';
import { BreadcrumbSelect } from 'Molecules/select/BreadcrumbSelect';
import React, { FC, ReactNode, useMemo } from 'react';
import { OnChangeValue } from 'react-select';
import { useMobile } from 'services/useMobile';
import styled from 'styled-components/macro';
import { BreadcrumbSelectOption } from 'types/breadcrumb';
import { SelectOption } from 'types/select';

const classNamePrefix = 'year-range-selector';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 100%;
`;

const StyledBreadcrumbSelect = styled(BreadcrumbSelect)`
  width: 90px;

  .${classNamePrefix}__control {
    min-width: unset;
  }
`;

interface StyleProps {
  isToSelectShown: boolean;
}

const FromYearSelect = styled(StyledBreadcrumbSelect)<StyleProps>`
  margin: ${props => (props.isToSelectShown ? '0 5px' : '0 0 0 5px')};
`;

const ToYearSelect = styled(StyledBreadcrumbSelect)`
  margin: 0 0 0 5px;
`;

const SpanStyled = styled(Span)`
  display: flex;
`;

const RangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export interface YearRangeOption {
  year: number;
  disabled?: boolean;
}

export interface Range {
  value: string;
  label?: ReactNode;
  onChange: (value: OnChangeValue<BreadcrumbSelectOption, false>) => void;
}

interface Props {
  className?: string;
  options: YearRangeOption[];
  from: Range;
  to?: Range;
  orderYearsAsc?: boolean;
}

export const YearRangeSelector: FC<Props> = ({ className, options, from, to, orderYearsAsc }) => {
  const isMobile = useMobile('m');
  const isSingleSelector = !to;
  const fromValue = +from.value;
  const toValue = +(to?.value ?? 0);

  const selectOptions = useMemo(
    (): SelectOption[] =>
      [...options]
        .sort((l, r) => r.year - l.year)
        .map(option => ({
          disabled: option.disabled,
          label: option.year.toString(),
          value: option.year.toString(),
        })),
    [options]
  );

  const fromSortedOptions = orderYearsAsc
    ? [...selectOptions].sort((l, r) => (l.value > r.label ? 1 : l.value < r.label ? -1 : 0))
    : selectOptions;

  const fromOptions = useMemo(
    () =>
      isSingleSelector
        ? fromSortedOptions
        : fromSortedOptions.map(option => ({
            ...option,
            disabled: +option.value > toValue,
          })),
    [fromSortedOptions, isSingleSelector, toValue]
  );

  const toOptions = useMemo(
    () =>
      !isSingleSelector
        ? selectOptions.map(option => ({
            ...option,
            disabled: +option.value < fromValue,
          }))
        : [],
    [selectOptions, isSingleSelector, fromValue]
  );

  return (
    <Container className={className}>
      <RangeContainer>
        {from.label && <SpanStyled>{from.label}</SpanStyled>}
        <FromYearSelect
          options={fromOptions}
          onChange={from.onChange}
          isSearchable={false}
          classNamePrefix={classNamePrefix}
          value={from.value}
          menuPlacement={isMobile ? 'top' : 'bottom'}
          ariaLabel="From year"
          isToSelectShown={!!to}
        />
        {to && (
          <>
            {to.label && <SpanStyled>{to.label}</SpanStyled>}
            <ToYearSelect
              options={toOptions}
              onChange={to.onChange}
              isSearchable={false}
              classNamePrefix={classNamePrefix}
              value={to.value}
              menuPlacement={isMobile ? 'top' : 'bottom'}
              ariaLabel="To year"
            />
          </>
        )}
      </RangeContainer>
    </Container>
  );
};
