import { Size, Span } from 'Atoms/text/Span';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const Text = styled(Span)`
  &[data-text-align='left'] {
    text-align: left;
  }
  &[data-text-align='center'] {
    text-align: center;
  }
  &[data-text-align='right'] {
    text-align: right;
  }
`;

interface Props {
  className?: string;
  textAlign?: 'left' | 'center' | 'right';
  size?: Size;
}

export const CopyrightText: FC<Props> = ({ className, textAlign, size }) => (
  <Text className={className} size={size} color="navbar" data-text-align={textAlign}>
    Copyright © 1994 - {new Date().getFullYear()} GIDEON Informatics, Inc. All Rights Reserved.
  </Text>
);
