import { getPaginatedData } from 'services/axios.service';
import { UpdatesResponse } from 'Updates/types/updatedField';

import { UpdatedDTO } from './updates.dto';
import { mapUpdatedFields } from './updates.mapper';

export const getUpdates = async (from: number, to: number): Promise<UpdatesResponse> => {
  const updatedFields = await getPaginatedData<UpdatedDTO>(
    `/api/public/whatsnew?from=${from}&to=${to}`
  );

  return { updates: mapUpdatedFields(updatedFields.data), total: updatedFields.total, updateCount: updatedFields.updates };
};
