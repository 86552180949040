import { getAriaSort } from 'Atoms/buttons/SortButton';
import { Table, TD, TH, TR } from 'Atoms/table/Table';
import { P } from 'Atoms/text';
import { CellWithSort } from 'Molecules/compare/CellWithSort';
import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { CustomError } from 'types/errorTypes';
import { Crossborders, Outbreaks, Survey } from 'types/surveyTables';
import { Column, Row } from 'types/table';
import { SortState } from 'types/table';
import {
  getCrossBordersTableData,
  getOutbreaksTableData,
  getSurveyTableData,
} from 'utils/getSurveyTablesData';
import { sortTableRows } from 'utils/sortTableRows';

const StyledP = styled(P)`
  margin-top: 10px;
`;

const StyledTable = styled(Table)`
  margin-top: 10px;
`;

const StyledTH = styled(TH)`
  position: relative;
  padding: 8px;
`;

type TabelDataProps =
  | { surveyData: Survey[] }
  | { crossBordersData: Crossborders[] }
  | { outbreaksData: Outbreaks[] };

type Props = {
  className?: string;
  footnote1?: string;
  footnote2?: string;
  title?: string;
  subTitle?: string;
} & TabelDataProps;

const getTableColumnsAndRows = (props: TabelDataProps): [Column[], Row[], boolean] => {
  if ('surveyData' in props) {
    return getSurveyTableData(props.surveyData);
  }
  if ('crossBordersData' in props) {
    return getCrossBordersTableData(props.crossBordersData);
  }
  if ('outbreaksData' in props) {
    return getOutbreaksTableData(props.outbreaksData);
  }

  throw new CustomError('Missing data for survey table');
};

export const SurveyTable: FC<Props> = ({
  className,
  footnote1,
  footnote2,
  title,
  subTitle,
  ...rest
}) => {
  const [sort, setSort] = useState<SortState>({ asc: false, columnIndex: -1 });
  const [columns, rows, showPublicationFootnote] = getTableColumnsAndRows(rest);

  const onSortClick = (index: number): void => {
    if (index === sort.columnIndex) {
      setSort({ asc: !sort.asc, columnIndex: index });
    } else {
      setSort({ asc: true, columnIndex: index });
    }
  };

  const renderRows = useMemo(() => {
    const sortedRows: Row[] = sortTableRows(rows, sort.asc, sort.columnIndex);
    return sortedRows.map((row, i) => (
      <TR key={i}>
        {row.map((rowdata, j) => (
          <TD
            key={j}
            wrap={columns[j].enableWrap}
            minWidth={columns[j].minWidth}
            textAlign={columns[j].textAlign}
          >
            {rowdata.label ? rowdata.label : ''}
          </TD>
        ))}
      </TR>
    ));
  }, [columns, rows, sort]);

  return (
    <>
      {title && <b>{title}</b>}
      {subTitle && <p>{subTitle}</p>}
      <StyledTable
        className={className}
        headings={
          <TR>
            {columns.map((c, i) => (
              <StyledTH key={c.name} aria-sort={getAriaSort(i !== sort.columnIndex, sort.asc)}>
                <CellWithSort
                  text={c.name}
                  color="light"
                  showSort={rows.length > 1 && c.isSortable}
                  onClick={() => onSortClick(i)}
                  clicked={i === sort.columnIndex}
                  asc={sort.asc}
                />
              </StyledTH>
            ))}
          </TR>
        }
        rows={renderRows}
      />
      {footnote1 && showPublicationFootnote && <StyledP>{footnote1}</StyledP>}
      {footnote2 && <StyledP>{footnote2}</StyledP>}
    </>
  );
};
