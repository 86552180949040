import { ReactComponent as ClockSvg } from 'assets/UI/Clock.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Icon } from 'Atoms/Icon';
import { AccountInput } from 'Atoms/input/AccountInput';
import { Span } from 'Atoms/text';
import { FormMessage } from 'Auth/Molecules/FormMessage';
import { DatePicker } from 'Diagnose/molecules/datePicker/DatePicker';
import { Subsection } from 'Molecules/account/Subsection';
import { Moment } from 'moment';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const GenerateButton = styled(FilledButton)`
  margin: 20px 0 0 0;
  padding: 7px 14px;
  width: 100%;

  &:first-child {
    margin: 20px 10px 0 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 140px;

  margin: 20px 0 0 0;

  &:first-child {
    margin: 20px 35px 0 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

const CalendarButton = styled(InvisibleButton)`
  margin: 0 0 0 10px;

  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${props => props.theme.colors.text.lightLink};
    ${Span} {
      color: ${props => props.theme.colors.text.lightLink};
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 20px 0 0 0;
`;

const DatePickerStyled = styled(DatePicker)`
  height: 25px;

  border: 1px solid ${props => props.theme.colors.text.inactive};
  border-radius: 4px;

  padding: 0 5px 0 0;
  margin: 20px 0 0 0;

  .DateInput_input {
    font: ${props => props.theme.fonts.normalText};
  }

  ${Icon} {
    width: 30px;
  }
`;

const Input = styled(AccountInput)`
  width: 140px;
  margin: 20px 0 0 0;
  height: 25px;
  border: 1px solid ${props => props.theme.colors.text.inactive};

  ${Icon} {
    display: none;
  }

  input {
    padding: 0;
  }
`;

const IdColumn = styled(Column)`
  width: 50%;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

export interface Props {
  className?: string;
  onClickAccessLog: () => void;
  onClickSummary: () => void;
  isSubmiting?: boolean;
  startDate: Moment | null;
  setStartDate: (value: Moment | null) => void;
  endDate: Moment | null;
  setEndDate: (value: Moment | null) => void;
  isOutsideStartRange: (day: Moment) => boolean;
  isOutsideEndRange: (day: Moment) => boolean;
  custUserId?: { value?: string; onChange: (value: string) => void };
  groupId?: { value?: string; onChange: (value: string) => void };
  subscriptionId?: { value?: string; onChange: (value: string) => void };
  error?: string | null;
  onClickCalendarButton: (type: 'year' | '6 months' | 'month') => void;
}

export const UsageLogs: FC<Props> = ({
  className,
  onClickAccessLog,
  onClickSummary,
  isSubmiting,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  custUserId,
  groupId,
  subscriptionId,
  error,
  isOutsideStartRange,
  isOutsideEndRange,
  onClickCalendarButton,
}) => (
  <Subsection className={className} title="Usage logs">
    <Span size="normal">Max 24 months</Span>
    <Container>
      <Row>
        <Icon size="medium" svgComponent={ClockSvg} />
        <CalendarButton type="button" onClick={() => onClickCalendarButton('year')}>
          <Span size="normal">Last year</Span>
        </CalendarButton>
        <CalendarButton type="button" onClick={() => onClickCalendarButton('6 months')}>
          <Span size="normal">Last 6 months</Span>
        </CalendarButton>
        <CalendarButton type="button" onClick={() => onClickCalendarButton('month')}>
          <Span size="normal">Last month</Span>
        </CalendarButton>
      </Row>
      <ColumnContainer>
        <Column>
          <Span as="label" color="inactive" weight="600" htmlFor="start-date">
            Start date
          </Span>
          <DatePickerStyled
            id="start-date"
            value={startDate}
            onChange={setStartDate}
            isOutsideRange={isOutsideStartRange}
          />
        </Column>
        <Column>
          <Span as="label" color="inactive" weight="600" htmlFor="end-date">
            End date
          </Span>
          <DatePickerStyled
            id="end-date"
            value={endDate}
            onChange={setEndDate}
            isOutsideRange={isOutsideEndRange}
          />
        </Column>
      </ColumnContainer>
    </Container>
    {custUserId && (
      <Container>
        <IdColumn>
          <Span as="label" color="inactive" weight="600" htmlFor="cust-user-id">
            Cust/User ID <Span fontStyle="italic">(optional)</Span>
          </Span>
          <Input
            type="text"
            value={custUserId.value}
            onChange={e => custUserId.onChange(e.target.value)}
            id="cust-user-id"
          />
        </IdColumn>
      </Container>
    )}
    {groupId && (
      <Container>
        <IdColumn>
          <Span as="label" color="inactive" weight="600" htmlFor="cust-user-id">
            Group ID <Span fontStyle="italic">(optional)</Span>
          </Span>
          <Input
            type="text"
            value={groupId.value}
            onChange={e => groupId.onChange(e.target.value)}
            id="cust-user-id"
          />
        </IdColumn>
      </Container>
    )}
    {subscriptionId && (
      <Container>
        <IdColumn>
          <Span as="label" color="inactive" weight="600" htmlFor="cust-user-id">
            Subscription ID <Span fontStyle="italic">(optional)</Span>
          </Span>
          <Input
            type="text"
            value={subscriptionId.value}
            onChange={e => subscriptionId.onChange(e.target.value)}
            id="cust-user-id"
          />
        </IdColumn>
      </Container>
    )}
    <FormMessage type="error" message={error} />
    <ButtonContainer>
      <GenerateButton disabled={isSubmiting} onClick={onClickAccessLog} type="button">
        Download access log CSV file
      </GenerateButton>
      <GenerateButton disabled={isSubmiting} onClick={onClickSummary} type="button">
        Download summary CSV file
      </GenerateButton>
    </ButtonContainer>
  </Subsection>
);
