import { ReactComponent as BacteriaSVG } from 'assets/Microbes/Bacteria.svg';
import { ReactComponent as MycobacteriaSVG } from 'assets/Microbes/Mycobacteria.svg';
import { ReactComponent as YeastsSVG } from 'assets/Microbes/Yeasts.svg';
import { P } from 'Atoms/text';
import { Props as PProps } from 'Atoms/text/P';
import { ExploreFocusSection } from 'Molecules/exploreFocus/ExploreFocusSectionMobile';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { formatNullableNumber } from 'utils/formatNumber';

const SubTitle = styled(P).attrs(
  (): Partial<PProps> => ({
    size: 'big',
    weight: '500',
  })
)`
  text-align: center;
`;

const Categories = styled.div`
  display: none;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    margin: 33px 0 20px 0;
  }
`;

interface Props {
  basePath: string;
  bacteriaTotal: number;
  mycobacteriaTotal: number;
  yeastsTotal: number;
}

export const AzMicrobesButtonsMobile: FC<Props> = ({
  basePath,
  bacteriaTotal,
  mycobacteriaTotal,
  yeastsTotal,
}) => {
  return (
    <>
      <SubTitle>Choose sub-category</SubTitle>
      <Categories>
        <ExploreFocusSection
          caption={`Bacteria (${formatNullableNumber(bacteriaTotal)})`}
          to={`${basePath}/bacteria`}
          Icon={BacteriaSVG}
        />
        <ExploreFocusSection
          caption={`Mycobacteria (${mycobacteriaTotal})`}
          to={`${basePath}/mycobacteria`}
          Icon={MycobacteriaSVG}
        />
        <ExploreFocusSection
          caption={`Yeasts and Algae (${yeastsTotal})`}
          to={`${basePath}/yeasts`}
          Icon={YeastsSVG}
        />
      </Categories>
    </>
  );
};
