import { ExternalLink } from 'Atoms/links/ExternalLink';
import { Loader } from 'Atoms/Loader';
import { ModalH3 } from 'Atoms/modalContents/ModalH3';
import { ModalP } from 'Atoms/modalContents/ModalP';
import { ModalCard } from 'Molecules/modalCard/ModalCard';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { ReactNode } from 'react';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading, Resource } from 'store/types';
import styled from 'styled-components/macro';
import { ReferenceDetails } from 'types/reference';

interface Props {
  className?: string;
  reference: Resource<ReferenceDetails>;
  index: number;
}

const renderReferenceSection = (name: string, content: string): ReactNode => (
  <>
    <ModalH3>{name}</ModalH3>
    <ModalP>{content}</ModalP>
  </>
);

const LoaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
`;

export const useHandledReferenceData = (
  reference: Resource<ReferenceDetails>
): {
  title: string;
  subTitle?: ReactNode;
  content: ReactNode;
} => {
  assertIsNotStoreError(reference);

  if (isLoading(reference)) {
    return {
      title: ' ',
      content: (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ),
    };
  }

  return {
    title: reference.title,
    subTitle: <DynamicHtml content={reference.citation} />,
    content: (
      <>
        {reference.authors && renderReferenceSection('Author(s)', reference.authors)}
        {reference.abstract && renderReferenceSection('Abstract', reference.abstract)}
        <ExternalLink to={reference.url} openInNewTab>
          Open reference source in new tab
        </ExternalLink>
      </>
    ),
  };
};

const ReferenceCardBase: React.FC<Props> = ({ className, reference, index }) => {
  const { title, subTitle, content } = useHandledReferenceData(reference);

  return (
    <ModalCard className={className} title={`${index}. ${title}`} subTitle={subTitle}>
      {content}
    </ModalCard>
  );
};

export const ReferenceCard = styled(ReferenceCardBase)``;
