import { Dictionary } from './common';

export type Entry = { id: number | string; name: string };

export interface GroupedData {
  data: SectionsDictionary;
  total: number;
}
export type SectionsDictionary = Dictionary<Entry[]>;

export const categories = ['diseases', 'drugs', 'vaccines', 'countries', 'microbes'] as const;
export type CategoryType = typeof categories[number];

export const microbes = ['bacteria', 'mycobacteria', 'yeasts'] as const;
export type MicrobeType = typeof microbes[number];

export const microbeNames: Record<MicrobeType, string> = {
  bacteria: 'Bacteria',
  mycobacteria: 'Mycobacteria',
  yeasts: 'Yeasts and Algae',
};

export const isMicrobe = (value: string): value is MicrobeType =>
  value === 'bacteria' || value === 'mycobacteria' || value === 'yeasts';
