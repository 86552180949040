import { ReactComponent as Contract } from 'assets/UI/Contract.svg';
import { ReactComponent as Newsletter } from 'assets/UI/Newsletter.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Icon } from 'Atoms/Icon';
import { Link } from 'Atoms/links/Link';
import { Loader } from 'Atoms/Loader';
import { Span } from 'Atoms/text';
import { H2 } from 'Atoms/text/H';
import { accountSignUp, getAccountEmailExists } from 'Auth/apiServices/account';
import { SignUpError } from 'Auth/apiServices/account.dto';
import { FormInput } from 'Auth/Molecules/FormInput';
import { FormMessage } from 'Auth/Molecules/FormMessage';
import { ReCaptchaNoticeText } from 'Auth/Molecules/ReCaptchaNoticeText';
import { SocialProof } from 'Auth/Molecules/SocialProof';
import { ContentContainer, ErrorRow, Row, Title, TrialLayout } from 'Auth/Molecules/Styles';
import { emailRegex, validateEmailDomain } from 'Auth/services/validation';
import { initialState, State } from 'Auth/store/provider';
import { reducer } from 'Auth/store/reducer';
import { AxiosError } from 'axios';
import { CheckboxWithLabel } from 'Molecules/CheckboxWithLabel';
import React, { FC, FormEvent, ReactNode, useCallback, useReducer, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';
import { usePushState } from 'services/usePushState.hook';
import { useDispatch as useLegalStoreDispatch } from 'store/legalModalStore/hooks';
import styled from 'styled-components/macro';
import { EmailExists } from 'types/account';

const FormContainer = styled.div`
  width: 450px;
  height: 100%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;

const Form = styled.form`
  width: 700px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    position: unset;
    width: 100%;

    align-items: center;
  }
`;

const TrialFormInputSpaced = styled(FormInput)`
  margin-left: 5px;
`;

const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin: 30px 0 20px 0;
`;

const CheckboxWithLabelStyled = styled(CheckboxWithLabel)`
  margin: 20px 0 0 0;
`;

const IconStyled = styled(Icon)`
  margin: 0 0 0 5px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  }
`;

const TextButton = styled(InvisibleButton)`
  text-decoration: underline;
  cursor: pointer;
`;

const SpecialButton = styled(FilledButton)`
  width: 450px;
  height: 44px;

  margin-top: 20px;
`;

const ReCaptchaTextStyled = styled(ReCaptchaNoticeText)`
  width: 450px;
`;

export const SignUpHome: FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(false);

  const legalStoreDispatch = useLegalStoreDispatch();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const { push } = usePushState();

  const validateEmail = useCallback((): string | null => {
    if (!state.email) {
      return 'Email is required';
    } else {
      const emailIsValid = state.email.match(emailRegex);

      if (!emailIsValid) {
        return 'Email is not valid';
      } else {
        const isValid = validateEmailDomain(state.email);

        if (!isValid) {
          return 'Please use an institutional email account';
        }
      }
    }

    return null;
  }, [state.email]);

  const validateForm = (): State['errors'] => {
    const errors: State['errors'] = {
      firstName: null,
      lastName: null,
      email: null,
      institution: null,
      newsletter: null,
      password: null,
      repeatPassword: null,
      tos: null,
      form: null,
    };

    if (!state.firstName) {
      errors.firstName = 'First name is required';
    }

    if (!state.lastName) {
      errors.lastName = 'Last name is required';
    }

    if (state.password.length < 8) {
      errors.password = 'Password length must be at least 8 characters';
    }

    if (state.password != state.repeatPassword) {
      errors.repeatPassword = 'Passwords must match';
    }

    if (!state.tos) {
      errors.tos = 'You must accept terms and conditions';
    }

    const emailError = validateEmail();

    if (emailError) {
      errors.email = emailError;
    }

    return errors;
  };

  const getFormError = (message: string | undefined): ReactNode => {
    if (message === 'Email already exists.') {
      return (
        <Span>
          Email already exists. Click <Link to="/login">here</Link> to sign in.
        </Span>
      );
    } else if (message === 'You are not human.') {
      return 'Suspicious behaviour detected. Please try again.';
    }

    return message || 'Server error';
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (!executeRecaptcha) {
      return;
    }

    const { firstName, lastName, email, password, institution, newsletter, tos } = state;

    const errors = validateForm();
    dispatch({ type: 'SignUp/Set/Errors', payload: errors });

    if (Object.values(errors).some(error => error)) {
      return;
    }

    setIsLoading(true);
    executeRecaptcha('submit')
      .then(token =>
        accountSignUp(firstName, lastName, email, password, institution, newsletter, tos, token)
      )
      .then(() => {
        setIsLoading(false);
        push('/signup/success', { email: email });
      })
      .catch((e: AxiosError<SignUpError> | null) => {
        dispatch({
          type: 'SignUp/Set/Error',
          payload: { field: 'form', value: getFormError(e?.response?.data?.error?.message) },
        });
        setIsLoading(false);
      });
  };

  const getEmailError = (response: EmailExists): ReactNode => {
    if (response.domainExists) {
      return (
        <>
          You have access! <Link to="/login">Sign in</Link> through your library.
        </>
      );
    } else if (response.exists) {
      return (
        <>
          This email is already in use. <Link to="/login">Sign in</Link>
        </>
      );
    }
  };

  const onEmailBlur = useCallback((): void => {
    const emailError = validateEmail();

    if (emailError) {
      return;
    }

    getAccountEmailExists(state.email).then(res => {
      if (!res.exists && !res.domainExists) {
        return;
      }

      const emailError = getEmailError(res);

      if (!emailError) {
        return;
      }

      dispatch({
        type: 'SignUp/Set/Error',
        payload: {
          field: 'email',
          value: emailError,
        },
      });
    });
  }, [state.email, validateEmail]);

  return (
    <TrialLayout>
      <Helmet>
        <title>Trial - GIDEON</title>
      </Helmet>
      <Title color="lightLink" weight="600" size="big" font="Inter">
        Ready to get started?
      </Title>
      <H2 color="input" weight="500" size="smallRegular" font="Inter">
        Try our product for free
      </H2>
      <ContentContainer>
        <FormContainer>
          <Form onSubmit={onSubmit}>
            <Row>
              <FormInput
                id="signup-first-name"
                label="First name"
                value={state.firstName}
                onChange={firstName =>
                  dispatch({
                    type: 'SignUp/Set/Value',
                    payload: { field: 'firstName', value: firstName },
                  })
                }
                error={!!state.errors.firstName}
                autoComplete="given-name"
              />
              <TrialFormInputSpaced
                id="signup-last-name"
                label="Last name"
                value={state.lastName}
                onChange={lastName =>
                  dispatch({
                    type: 'SignUp/Set/Value',
                    payload: { field: 'lastName', value: lastName },
                  })
                }
                error={!!state.errors.lastName}
                autoComplete="family-name"
              />
            </Row>
            <ErrorRow>
              <FormMessage type="error" message={state.errors.firstName} />
            </ErrorRow>
            <ErrorRow>
              <FormMessage type="error" message={state.errors.lastName} />
            </ErrorRow>
            <Row>
              <FormInput
                id="signup-insitution"
                label="Institution"
                value={state.institution}
                onChange={institution =>
                  dispatch({
                    type: 'SignUp/Set/Value',
                    payload: { field: 'institution', value: institution },
                  })
                }
                error={!!state.errors.institution}
                autoComplete="organization"
              />
            </Row>
            <Row>
              <FormInput
                id="signup-email"
                label="Institutional email"
                value={state.email}
                onChange={email =>
                  dispatch({
                    type: 'SignUp/Set/Value',
                    payload: { field: 'email', value: email.trim() },
                  })
                }
                error={!!state.errors.email}
                type="email"
                autoComplete="email"
                onBlur={onEmailBlur}
              />
            </Row>
            <ErrorRow>
              <FormMessage type="error" message={state.errors.email} />
            </ErrorRow>
            <Row>
              <FormInput
                id="signup-password"
                type="password"
                label="Create your password"
                value={state.password}
                onChange={password =>
                  dispatch({
                    type: 'SignUp/Set/Value',
                    payload: { field: 'password', value: password.trim() },
                  })
                }
                error={!!state.errors.password}
                autoComplete="new-password"
              />
            </Row>
            <ErrorRow>
              <FormMessage type="error" message={state.errors.password} />
            </ErrorRow>
            <Row>
              <FormInput
                id="signup-repeat-password"
                type="password"
                label="Type it one more time, please"
                value={state.repeatPassword}
                onChange={repeatPassword =>
                  dispatch({
                    type: 'SignUp/Set/Value',
                    payload: { field: 'repeatPassword', value: repeatPassword.trim() },
                  })
                }
                error={!!state.errors.repeatPassword}
                autoComplete="new-password"
              />
            </Row>
            <ErrorRow>
              <FormMessage type="error" message={state.errors.repeatPassword} />
            </ErrorRow>
            <CheckboxContainer>
              <CheckboxWithLabel
                size="small"
                checkboxSize="30px"
                onChange={() =>
                  dispatch({
                    type: 'SignUp/Set/Value',
                    payload: { field: 'tos', value: !state.tos },
                  })
                }
                checked={state.tos}
                color="main"
                id="legal"
              >
                I agree with GIDEON&nbsp;
                <TextButton
                  type="button"
                  onClick={() =>
                    legalStoreDispatch({ type: 'LegalModal/TermsAndConditions', payload: true })
                  }
                >
                  <Span color="main">Terms of Service</Span>
                </TextButton>
                ,&nbsp;
                <TextButton
                  type="button"
                  onClick={() =>
                    legalStoreDispatch({ type: 'LegalModal/LicenseAgreement', payload: true })
                  }
                >
                  <Span color="main">License Agreement</Span>
                </TextButton>
                , and&nbsp;
                <TextButton
                  type="button"
                  onClick={() => legalStoreDispatch({ type: 'LegalModal/Privacy', payload: true })}
                >
                  <Span color="main">Privacy Policy</Span>
                </TextButton>
                <IconStyled size="small" svgComponent={Contract} />
              </CheckboxWithLabel>
              <ErrorRow>
                <FormMessage type="error" message={state.errors.tos} />
              </ErrorRow>
              <CheckboxWithLabelStyled
                size="small"
                checkboxSize="30px"
                onChange={() =>
                  dispatch({
                    type: 'SignUp/Set/Value',
                    payload: { field: 'newsletter', value: !state.newsletter },
                  })
                }
                checked={state.newsletter}
                color="main"
                id="newsletter"
              >
                I agree to receive the latest news on Infectious Diseases
                <IconStyled size="small" svgComponent={Newsletter} />
              </CheckboxWithLabelStyled>
            </CheckboxContainer>
            <ErrorRow>
              <FormMessage type="error" message={state.errors.form} />
            </ErrorRow>
            <SpecialButton color="special">
              {isLoading ? <Loader /> : 'Try GIDEON free'}
            </SpecialButton>
            <ReCaptchaTextStyled color="inactive" />
          </Form>
        </FormContainer>
        <SocialProof />
      </ContentContainer>
    </TrialLayout>
  );
};
