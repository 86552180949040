import { getOutbreaks, getOutbreaksPublic } from 'apiServices/Diseases/diseases';
import { Dispatch, useContext, useEffect } from 'react';
import { newStoreError } from 'store/storeError';
import { Loading, Resource } from 'store/types';
import { Outbreak } from 'types/disease';
import { CustomError, ErrorCodes } from 'types/errorTypes';

import { Action, OutbreaksDispatchContext, OutbreaksStateContext, State } from './provider';

export const useState = (): State => {
  const state = useContext(OutbreaksStateContext);
  if (state === undefined) {
    throw new CustomError('OutbreaksStore', ErrorCodes.StoreNotInitialized);
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = useContext(OutbreaksDispatchContext);
  if (dispatch === undefined) {
    throw new CustomError('OutbreaksStore', ErrorCodes.StoreNotInitialized);
  }
  return dispatch;
};

export const useOutbreaks = (year: string): Resource<Outbreak[]> => {
  const state = useState();
  const dispatch = useDispatch();
  const outbreaks = state.outbreaks[year];

  useEffect(() => {
    if (!outbreaks) {
      dispatch({ type: 'Outbreaks/SingleLoadInitiated', payload: { id: year } });
      getOutbreaks(year)
        .then(data => dispatch({ type: 'Outbreaks/SingleLoaded', payload: { id: year, data } }))
        .catch(err => {
          dispatch({
            type: 'Outbreaks/SingleLoadFailed',
            payload: { id: year, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
  }, [dispatch, outbreaks, year]);

  return outbreaks || Loading;
};

export const useOutbreaksPublic = (year: string): Resource<Outbreak[]> => {
  const state = useState();
  const dispatch = useDispatch();
  const outbreaks = state.outbreaksPublic[year];

  useEffect(() => {
    if (!outbreaks) {
      dispatch({ type: 'Outbreaks/Public/SingleLoadInitiated', payload: { id: year } });
      getOutbreaksPublic(year)
        .then(data =>
          dispatch({ type: 'Outbreaks/Public/SingleLoaded', payload: { id: year, data } })
        )
        .catch(err => {
          dispatch({
            type: 'Outbreaks/Public/SingleLoadFailed',
            payload: { id: year, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
  }, [dispatch, outbreaks, year]);

  return outbreaks || Loading;
};
