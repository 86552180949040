import { HiddenElementForCapture } from 'Atoms/HiddenElementForCapture';
import React, { forwardRef, ReactElement, useImperativeHandle, useState } from 'react';
import { useElementCapture } from 'services/useElementCapture.hook';

export interface CaptureElementRef {
  capture: () => void;
}

interface Props {
  children: ReactElement;
  imageName?: string;
}

export const GraphCapture = forwardRef<CaptureElementRef, Props>(({ children, imageName }, ref) => {
  const { ref: captureRef, capture } = useElementCapture(imageName);
  const [mount, setMount] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      capture: () => {
        setMount(true);
        // TODO: setTimeout switch to ready event listiner
        setTimeout(() => capture().then(() => setMount(false)), 1000);
      },
    }),
    [capture]
  );

  return mount ? (
    <HiddenElementForCapture ref={captureRef}>{children}</HiddenElementForCapture>
  ) : null;
});
