import { ReactComponent as KeyIcon } from 'assets/Map/legend.svg';
import { ReactComponent as ListIcon } from 'assets/Map/list.svg';
import { ReactComponent as MaximizeSVG } from 'assets/UI/Maximize.svg';
import { ReactComponent as MinimizeSVG } from 'assets/UI/Minimize.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { AppLayoutFullscreenContext } from 'layouts/AppLayout';
import { Breadcrumb } from 'Molecules/breadcrumb/Breadcrumb';
import { BreadcrumbContainerContent } from 'Molecules/breadcrumb/BreadcrumbContainerContent';
import { Range, YearRangeOption, YearRangeSelector } from 'Molecules/YearRangeSelector';
import { Legend } from 'Organisms/outbreaksMap/Legend';
import React, { FC, MouseEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useContextAssert } from 'services/useContextAssert.hook';
import { useMobile } from 'services/useMobile';
import { useTooltip } from 'services/useTooltip';
import styled, { css } from 'styled-components/macro';
import { Dictionary } from 'types/common';

const BreadcrumbContainerContentStyled = styled(BreadcrumbContainerContent)`
  position: absolute;
  top: 0;
  left: 0;

  padding: 10px 30px 0;

  ${Breadcrumb} {
    margin: 6px 0;
  }
`;

const ControlsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  padding: 10px 30px 0;
`;

const Container = styled.div<{ isFullscreen: boolean }>`
  ${props =>
    props.isFullscreen &&
    css`
      position: absolute;
      top: 20px;
      right: 20px;
    `}

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
    align-items: center;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    padding: 10px 0 0 10px;

    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 0 10px 0 auto;
  }
`;

const ControlButton = styled(FilledButton)`
  width: 42px;
  height: 42px;
`;

const ControlButtonContainer = styled.div``;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 7;

  ${ControlButtonContainer}:not(:last-child) {
    margin-right: 10px;
  }

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    margin: 0 0 0 auto;
  }
`;

const StyledYearSelector = styled(YearRangeSelector)`
  margin: 0 10px 0 0;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 5px 10px 5px 0;
  }
`;

export interface Props {
  className?: string;
  toYearRange?: Range;
  fromYearRange: Range;
  yearSelectorOptions: YearRangeOption[];
  isFullscreenAPIActive: boolean;
  isFullscreenAPISupported: boolean;
  title: ReactNode;
  toggleFullscreen: () => void;
  toggleList?: () => void;
  legendEndemicColors?: Dictionary<string>;
  legendEndemicLabels?: string[];
  orderYearsAsc?: boolean;
  showYearSelector?: boolean;
  showFullscreenButton?: boolean;
  isListOpen?: boolean;
}

interface MapControlProps extends Props {
  fullscreenValue: boolean;
}

const MapControlsBase: FC<MapControlProps> = ({
  toggleFullscreen,
  toYearRange,
  fromYearRange,
  yearSelectorOptions,
  title,
  toggleList,
  legendEndemicColors,
  legendEndemicLabels,
  orderYearsAsc,
  fullscreenValue,
  showYearSelector = true,
  showFullscreenButton = true,
  isListOpen,
}) => {
  const [legendButtonRef, setLegendButtonRef] = useState<HTMLButtonElement | null>(null);
  const [isLegendOpen, setLegendOpen] = useState(false);
  const toggleLegend = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setLegendOpen(open => !open);
    },
    [setLegendOpen]
  );

  const showLegendButton = legendEndemicColors && legendEndemicLabels;

  const isDesktop = useMobile('m', 'min');

  const onClickList = (): void => {
    if (fullscreenValue) {
      toggleFullscreen();
    }

    if (toggleList) {
      toggleList();
    }
  };

  const [endemicityProps, endemicityPopper, setEndemicityElement] = useTooltip(
    'Endemicity legend',
    { eventElementSelector: true }
  );
  const [listProps, listPopper, setListElement] = useTooltip(
    fullscreenValue
      ? 'List all outbreaks (Clicking this exits the fullscreen)'
      : 'List all outbreaks',
    { eventElementSelector: true }
  );
  const [
    fullscreenProps,
    fullscreenPopper,
    setFullscreenElement,
  ] = useTooltip('View in full screen', { eventElementSelector: true });

  return (
    <Controls>
      <Container isFullscreen={fullscreenValue}>
        {isDesktop && title}
        {showYearSelector && (
          <StyledYearSelector
            from={fromYearRange}
            to={toYearRange}
            options={yearSelectorOptions}
            orderYearsAsc={orderYearsAsc}
          />
        )}
        <ButtonContainer>
          {showLegendButton && (
            <ControlButtonContainer {...endemicityProps.events}>
              <ControlButton
                padding="small"
                onClick={toggleLegend}
                aria-label="Legend"
                {...endemicityProps.aria}
                ref={ref => {
                  setEndemicityElement(ref);
                  setLegendButtonRef(ref);
                }}
              >
                <Icon svgComponent={KeyIcon} size="small" />
              </ControlButton>
              {endemicityPopper}
            </ControlButtonContainer>
          )}
          {toggleList && (
            <ControlButtonContainer {...listProps.events}>
              <ControlButton
                padding="small"
                onClick={onClickList}
                aria-label="Outbreaks list"
                {...listProps.aria}
                aria-expanded={isListOpen}
                ref={ref => setListElement(ref)}
              >
                <Icon svgComponent={ListIcon} size="small" />
              </ControlButton>
              {listPopper}
            </ControlButtonContainer>
          )}
          {showFullscreenButton && (
            <ControlButtonContainer {...fullscreenProps.events}>
              <ControlButton
                onClick={toggleFullscreen}
                padding="small"
                aria-label="Fullscreen"
                {...fullscreenProps.aria}
                ref={ref => setFullscreenElement(ref)}
              >
                <Icon svgComponent={fullscreenValue ? MinimizeSVG : MaximizeSVG} />
              </ControlButton>
              {fullscreenPopper}
            </ControlButtonContainer>
          )}
        </ButtonContainer>
      </Container>
      {legendEndemicLabels && legendEndemicColors && (
        <Legend
          endemicLabels={legendEndemicLabels}
          endemicColors={legendEndemicColors}
          referenceElement={legendButtonRef}
          isOpen={isLegendOpen}
          onClose={() => setLegendOpen(false)}
        />
      )}
    </Controls>
  );
};

const isIOS = /iPad|iPhone|iPod/.test(navigator.platform);

export const AppLayoutMapControls: FC<Props> = props => {
  const isFullscreenSupported = !isIOS && props.isFullscreenAPISupported;

  const [fullscreen, setFullscreen] = useContextAssert(AppLayoutFullscreenContext);

  const fullscreenValue = useMemo(
    (): boolean => (isFullscreenSupported ? props.isFullscreenAPIActive : fullscreen),
    [fullscreen, isFullscreenSupported, props.isFullscreenAPIActive]
  );

  const toggleFullscreen = useCallback((): void => {
    if (isFullscreenSupported) {
      props.toggleFullscreen();
    } else {
      setFullscreen(value => !value);
    }
  }, [isFullscreenSupported, props, setFullscreen]);

  useEffect(() => {
    // Resets app layout fullscreen state when user leaves the page
    return () => setFullscreen(false);
  }, [setFullscreen]);

  return (
    <BreadcrumbContainerContentStyled passthrough={fullscreenValue}>
      <MapControlsBase
        {...props}
        toggleFullscreen={toggleFullscreen}
        fullscreenValue={fullscreenValue}
      />
    </BreadcrumbContainerContentStyled>
  );
};

export const MapControls: FC<Props> = props => {
  const isFullscreenSupported = !isIOS && props.isFullscreenAPISupported;

  return (
    <ControlsContainer>
      <MapControlsBase
        {...props}
        fullscreenValue={props.isFullscreenAPIActive}
        showFullscreenButton={isFullscreenSupported}
      />
    </ControlsContainer>
  );
};
