import { H1, H2, P } from 'Atoms/text';
import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${props => props.theme.colors.background.recordTitle};
  box-shadow: 0px 2px 2px ${props => props.theme.colors.recordSection.shadow};
  border-radius: 5px;

  padding: 25px 43px;
  margin: 40px 0 15px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 25px 28px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled(H1)`
  margin: 0 15px 0 0;
  text-align: left;
`;

const SecondaryTitle = styled(H2)`
  margin: 20px 0 0 0;
  text-align: left;
`;

const NoteWrapper = styled.div`
  margin-top: 16px;
`;

interface Props {
  className?: string;
  title: string;
  distributionNote?: string | string[];
  note?: ReactNode;
  buttons?: ReactNode;
  synonymName?: string;
  subtitle?: string;
}

export const RecordTitle: React.FC<Props> = ({
  className,
  title,
  distributionNote,
  buttons,
  synonymName,
  note,
  subtitle,
}) => {
  const distribution = typeof distributionNote === 'string' ? [distributionNote] : distributionNote;

  return (
    <Wrapper className={className}>
      <TitleContainer>
        <Title color="mainBold" size="big" font="Exo2">
          {title}
        </Title>
        {!!buttons && buttons}
      </TitleContainer>
      {subtitle && (
        <SecondaryTitle color="mainBold" size="mediumRegular" font="Exo2">
          {subtitle}
        </SecondaryTitle>
      )}
      {synonymName && (
        <SecondaryTitle color="mainBold" size="normal" font="Exo2">
          found via synonym {synonymName}
        </SecondaryTitle>
      )}
      {distribution && (
        <NoteWrapper>
          {distribution.map(x => (
            <P key={x}>{x}</P>
          ))}
        </NoteWrapper>
      )}
      {note && <NoteWrapper>{note}</NoteWrapper>}
    </Wrapper>
  );
};
