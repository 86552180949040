import { P } from 'Atoms/text/P';
import { Span } from 'Atoms/text/Span';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { DynamicContent } from 'types/dynamicContent';

const StyledP = styled(P)`
  margin: 15px 0 10px;
`;

interface CustomVaccineDetails {
  code: number;
  vaccine: string;
  mechanismOfAction: DynamicContent;
  adultDosage: DynamicContent;
  childDosage?: DynamicContent;
  booster?: string;
  referencesLinks?: DynamicContent;
}

interface Props {
  record: CustomVaccineDetails;
}

export const VaccineSummarySection: FC<Props> = ({ record }) => (
  <>
    <StyledP weight="700">Mechanism of Action</StyledP>
    <DynamicHtml content={record.mechanismOfAction} />
    <StyledP weight="700">Typical Adult Dosage</StyledP>
    <DynamicHtml content={record.adultDosage} />
    {record.childDosage && (
      <>
        <StyledP weight="700">Typical Pediatric Dosage</StyledP>
        <DynamicHtml content={record.childDosage} />
      </>
    )}
    {record.booster && (
      <StyledP>
        <Span weight="700">Subsequent booster:&nbsp;</Span>
        <Span>{record.booster}</Span>
      </StyledP>
    )}
    {record.referencesLinks && (
      <>
        <StyledP weight="700">Reference links</StyledP>
        <DynamicHtml content={record.referencesLinks} />
      </>
    )}
  </>
);
