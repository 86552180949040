import { ReactComponent as InfoSVG } from 'assets/UI/Info.svg';
import { Icon } from 'Atoms/Icon';
import { Link } from 'Atoms/links/Link';
import { OrderedList, OrderedListItem } from 'Atoms/OrderedList';
import { P } from 'Atoms/text/P';
import { DrugClassItem } from 'Molecules/drug/DrugClassItem';
import { DrugSpectrumKey } from 'Molecules/drug/DrugSpectrumKey';
import { DrugSymbolKey } from 'Molecules/drug/DrugSymbolKey';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { DrugSpectrum } from 'types/drugSpectrum';

const Wrapper = styled.div``;

const Info = styled.div`
  display: flex;
  align-items: center;
`;

const InfoDescription = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const StyledIcon = styled(Icon)`
  fill: ${props => props.theme.colors.text.lightLink};
  margin-right: 15px;
`;

const StyledList = styled(OrderedList)`
  display: block;
  margin: 20px 0 0 55px;
  column-count: 2;
  column-gap: 20px;
  column-width: 200px;
  width: 70%;
`;

const OrderedListItemStyled = styled(OrderedListItem)`
  display: inline-flex;
  align-items: center;
  width: 100%;
`;

const DrugClassItemStyled = styled(DrugClassItem)`
  &:not(:first-child) {
    margin-top: 10px;
  }
`;

const DrugClassContainer = styled(OrderedList)`
  margin: 20px 0 0 15px;
`;

const DrugSymbolKeyStyled = styled(DrugSymbolKey)`
  margin-top: 15px;
`;

interface Props {
  spectrum: DrugSpectrum[];
  drugClasses: DrugSpectrum[];
}

export const DrugSpectrumSection: FC<Props> = ({ spectrum, drugClasses }) => {
  const showSymbolKey = spectrum.some(s => s.drugOfChoice || s.usedInCombination);

  return (
    <Wrapper>
      <Info>
        <StyledIcon svgComponent={InfoSVG} size="smallMedium" />
        <InfoDescription>
          <P>Pathogen susceptibility patterns may vary widely in time and place.</P>
          <P>
            Designation of bacteria as sensitive to a given drug indicates that published data have
            documented MIC-50 values within therapeutic range.
          </P>
        </InfoDescription>
      </Info>
      {showSymbolKey && <DrugSymbolKeyStyled />}
      <DrugClassContainer>
        {drugClasses.map(d => (
          <DrugClassItemStyled key={d.pathogen} label={d.pathogen} />
        ))}
      </DrugClassContainer>
      <StyledList style={{ counterReset: `item ${drugClasses.length}` }}>
        {spectrum.map(s => {
          const agent = s.microbiologyAgents.length > 0 ? s.microbiologyAgents[0] : null;
          return (
            <OrderedListItemStyled key={s.pathogen}>
              {agent ? (
                <Link to={`/explore/microbes/${agent.type}/${agent.code}`}>{s.pathogen}</Link>
              ) : (
                s.pathogen
              )}
              <DrugSpectrumKey show={s.drugOfChoice} type="Star" />
              <DrugSpectrumKey show={s.usedInCombination} type="Combine" />
              <DrugSpectrumKey show={s.excisionRequired || s.surgeryRequired} type="Surgery" />
            </OrderedListItemStyled>
          );
        })}
      </StyledList>
    </Wrapper>
  );
};
