import 'mapbox-gl/dist/mapbox-gl.css';

import { point } from '@turf/helpers';
import { ExternalLink } from 'Atoms/links/ExternalLink';
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import MapGL, { MapEvent, MapRef } from 'react-map-gl';
import {
  countriesByCode,
  countriesByNaturalEarthId,
  CountryCode,
} from 'services/countries/countries';
import { ThemeModeContext } from 'services/theme';
import styled from 'styled-components/macro';
import Supercluster from 'supercluster';
import { LayerInfo, Marker, MarkerClickHandler, Viewport } from 'types/map';
import { MapCluster, MapPoint } from 'types/map';

import { CountryColor, mapFillColorFromCountryColors } from './mapEndemics';
import { OutbreaksClusterMarker } from './marker';
import darkStyle from './styles/dark-styles.mapbox.json';
import lightStyle from './styles/light-styles.mapbox.json';

const AttributionContainer = styled.div`
  position: absolute;

  bottom: 0;
  right: 0;
  display: flex;

  align-items: center;

  .mapboxgl-ctrl {
    background: ${props => props.theme.colors.background.primary};
  }

  .mapboxgl-ctrl-attrib-inner,
  .mapboxgl-ctrl-attrib a {
    color: ${props => props.theme.colors.text.inactive};
  }
`;

type Layer = {
  id: string;
  source?: string;
  'source-layer'?: string;
  paint: { [field: string]: unknown };
};

const defaultMapSettings = {
  dragPan: true,
  dragRotate: false,
  scrollZoom: true,
  touchZoom: true,
  touchRotate: false,
  keyboard: false,
  doubleClickZoom: true,
  minZoom: 0,
  maxZoom: 10,
  minPitch: 0,
  maxPitch: 0,
};

interface Props<A extends unknown, T extends Marker<A>> {
  countryColors?: CountryColor[];
  markers: T[];
  onCountryClick?: (countryCode: string) => void;
  markerClickHandler: MarkerClickHandler<T>;
  viewport: Viewport;
  setViewport: (value: Viewport) => void;
}

export const Map = <A extends unknown, T extends Marker<A>>({
  countryColors,
  markers,
  onCountryClick,
  markerClickHandler,
  viewport,
  setViewport,
}: Props<A, T>): ReactElement => {
  const { isDarkMode } = useContext(ThemeModeContext);
  const style = isDarkMode ? darkStyle : lightStyle;
  const layerInfo = useRef<LayerInfo | null>(null);
  const mapRef = useRef<MapRef | null>(null);
  const [mapboxGlRef, setMapboxGlRef] = useState<mapboxgl.Map | null>(null);

  const [hoveredFeature, setHoveredFeature] = useState<string | number | undefined>(undefined);
  const onMouseEnter = useCallback(
    (e: MapEvent): void => {
      const feature = e?.features?.[0];
      if (feature) {
        setHoveredFeature(feature?.id);
      } else {
        setHoveredFeature(undefined);
      }
    },
    [setHoveredFeature]
  );

  useEffect(() => {
    if (hoveredFeature && layerInfo.current) {
      const featureIdentifier = {
        id: hoveredFeature,
        source: layerInfo.current.source,
        sourceLayer: layerInfo.current.sourceLayer,
      };
      mapboxGlRef?.setFeatureState(featureIdentifier, { hover: true });

      return () => {
        mapboxGlRef?.setFeatureState(featureIdentifier, { hover: false });
      };
    }
    return;
  }, [hoveredFeature, mapboxGlRef]);

  const mapStyle = useMemo(() => {
    // type inference is not working due to light and dark styles type incompatibility
    const layers: Layer[] = isDarkMode ? darkStyle.layers : lightStyle.layers;
    const newLayers = layers.map(
      (layer: Layer): Layer => {
        if (layer.id !== 'countries') {
          return layer;
        }

        if (layer.source) {
          layerInfo.current = {
            source: layer.source,
            sourceLayer: layer['source-layer'],
          };
        }

        return {
          ...layer,
          paint: {
            ...layer.paint,
            'fill-color': countryColors
              ? mapFillColorFromCountryColors(countryColors)
              : 'transparent',
          },
        };
      }
    );

    return {
      ...style,
      layers: newLayers,
    };
  }, [isDarkMode, countryColors, style]);

  const supercluster = useMemo(() => {
    const minZoom = defaultMapSettings.minZoom,
      maxZoom = defaultMapSettings.maxZoom,
      radius = 40;

    const cluster = new Supercluster<MapPoint<A, T>, MapCluster<A, T>>({
      minZoom,
      maxZoom,
      radius,
      map: point => ({ points: [point.point] }),
      reduce: (accumulated, props) => {
        if (accumulated.points) {
          accumulated.points = [...accumulated.points, ...props.points];
        } else {
          accumulated.points = [...props.points];
        }
      },
    });

    const points = markers.map(marker =>
      point<MapPoint<A, T>>([marker.lng, marker.lat], { point: marker })
    );

    cluster.load(points);
    return cluster;
  }, [markers]);

  const clusters = useMemo(() => {
    const zoom = viewport.zoom;
    const bounds = mapboxGlRef?.getBounds().toArray() ?? [
      [-180, 90],
      [180, -90],
    ];

    return supercluster.getClusters(
      [bounds[0][0], bounds[0][1], bounds[1][0], bounds[1][1]],
      Math.floor(zoom)
    );
  }, [mapboxGlRef, supercluster, viewport]);

  const actualMarkers = useMemo(
    () =>
      clusters.map((cluster, i) => (
        <OutbreaksClusterMarker
          key={cluster.id || i}
          cluster={cluster}
          markerClickHandler={markerClickHandler}
        />
      )),
    [clusters, markerClickHandler]
  );

  const onClick = useCallback(
    (e: MapEvent): void => {
      const feature = e?.features?.[0];
      if (onCountryClick && feature) {
        const isoA2 = feature.properties?.ISO_A2;
        const naturalEarthId = feature.properties?.NE_ID;
        const country =
          countriesByCode?.[isoA2 as CountryCode] ?? countriesByNaturalEarthId?.[naturalEarthId];

        if (country?.id) {
          onCountryClick(country.id);
        }
      }
    },
    [onCountryClick]
  );

  const onLoad = (): void => {
    const mapGLRef: mapboxgl.Map = mapRef.current?.getMap();

    if (mapGLRef) {
      setMapboxGlRef(mapGLRef);
    }
  };

  return (
    <MapGL
      ref={mapRef}
      {...viewport}
      {...defaultMapSettings}
      width="100%"
      height="100%"
      mapStyle={mapStyle}
      onViewportChange={setViewport}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      interactiveLayerIds={['countries']}
      onHover={onMouseEnter}
      onClick={onClick}
      onLoad={onLoad}
      attributionControl={false}
    >
      {actualMarkers}
      <AttributionContainer>
        <div className="mapboxgl-ctrl mapboxgl-ctrl-attrib">
          <div className="mapboxgl-ctrl-attrib-inner" role="list">
            <ExternalLink openInNewTab to="https://www.gideononline.com">
              © GIDEON Informatics, Inc
            </ExternalLink>{' '}
            <ExternalLink
              openInNewTab
              to="https://www.mapbox.com/about/maps/"
              title="Mapbox"
              aria-label="Mapbox"
            >
              © Mapbox
            </ExternalLink>{' '}
            <ExternalLink
              openInNewTab
              to="http://www.openstreetmap.org/about/"
              title="OpenStreetMap"
              aria-label="OpenStreetMap"
            >
              © OpenStreetMap
            </ExternalLink>
          </div>
        </div>
      </AttributionContainer>
    </MapGL>
  );
};
