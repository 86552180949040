import { CompareButtonsSection } from 'Molecules/compare/CompareButtonsSection';
import { CompareDrugsKeys } from 'Molecules/compare/CompareDrugsKeys';
import { CompareTopCaption } from 'Molecules/compare/CompareTopCaption';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { OnChangeValue } from 'react-select';
import { getRows } from 'services/compare/getCompareDrugData';
import { useCompareList, useState as useCompareListState } from 'store/ComparisonListsStore/hooks';
import { useCompareDrugs } from 'store/ComparisonStore/hooks';
import { useDrugsResource } from 'store/drugsStore/hooks';
import { assertIsNotStoreError, isStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { Column, CompareColumnDefinition } from 'types/compare';
import { SelectOption } from 'types/select';

import { CompareDrugsTable } from './CompareDrugsTable';

const StyledCompareKeys = styled(CompareDrugsKeys)`
  margin-bottom: 40px;
`;

export const CompareDrugs: FC = () => {
  const { drugs } = useDrugsResource();
  const { toggleComparison, isFull, clearList, isItemInList } = useCompareList('drugs');
  const compareListState = useCompareListState();
  const comparedDrugs = useCompareDrugs();
  assertIsNotStoreError(drugs);

  if (isLoading(drugs)) {
    return (
      <>
        <CompareTopCaption
          countText={`${compareListState.drugs.length} drugs`}
          options={[]}
          isLoading
          compareCategory="drugs"
        />
      </>
    );
  }

  const tableEmpty = Object.keys(comparedDrugs).length === 0;

  const selectOptions = drugs
    ? drugs.map(drug => ({
        value: drug.id.toString(),
        label: drug.name,
        disabled: isItemInList(drug.id),
      }))
    : [];

  const addToCompare = (selected: OnChangeValue<SelectOption, false>): void => {
    if (selected) {
      toggleComparison(parseInt(selected.value));
    }
  };

  if (tableEmpty) {
    return (
      <>
        <Helmet>
          <title>Compare drugs - GIDEON</title>
          <meta
            name="description"
            content="Generate a side-by-side spectra comparison of 340 anti-infective drugs. Download the table and share your insights with peers!"
          />
        </Helmet>
        <CompareTopCaption
          countText={`${compareListState.drugs.length} drugs`}
          options={selectOptions}
          onChange={addToCompare}
          isListFull={isFull}
          compareCategory="drugs"
        />
      </>
    );
  }

  const colsData: Column[] = comparedDrugs.map(drug => {
    if (isStoreError(drug)) {
      throw new Error('Failed to retrieve drug');
    }
    if (isLoading(drug) || drug === undefined) {
      return 'Loading';
    }

    return {
      id: drug.id,
      category: 'drugs',
      name: drug.name,
    };
  });

  const mappedColumns: CompareColumnDefinition[] = colsData.map(x => {
    return {
      column: x,
      enableWrap: true,
    };
  });

  return (
    <>
      <Helmet>
        <title>Compare drugs - GIDEON</title>
        <meta
          name="description"
          content="Generate a side-by-side spectra comparison of 340 anti-infective drugs. Download the table and share your insights with peers!"
        />
      </Helmet>
      <CompareTopCaption
        countText={`${compareListState.drugs.length} drugs`}
        options={selectOptions}
        onChange={addToCompare}
        isListFull={isFull}
        onClear={clearList}
        compareCategory="drugs"
      />
      <StyledCompareKeys />
      <CompareDrugsTable
        columns={tableEmpty ? [] : mappedColumns}
        rows={getRows(comparedDrugs)}
        onRemove={toggleComparison}
      />
      <CompareButtonsSection category="drugs" ids={compareListState.drugs} />
    </>
  );
};
