import { Icon, SvgComponent } from 'Atoms/Icon';
import { Span } from 'Atoms/text';
import { ModalCloseButton } from 'Molecules/buttons/ModalCloseButton';
import React, { FC, ReactNode } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

interface ColorProp {
  color: keyof DefaultTheme['colors']['background'];
}

const Container = styled.div<ColorProp>`
  position: relative;
  background: ${props => props.theme.colors.background.primary};
  width: 300px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  border: 2px solid ${props => props.theme.colors.background[props.color]};
`;

export const ContentContainer = styled.div`
  padding: 5px;
  max-height: 400px;
  overflow: auto;
`;

const StyledCloseButton = styled(ModalCloseButton)`
  width: 15px;
  height: 15px;
  top: 5px;
  right: 5px;
`;

const TitleContainer = styled.div<ColorProp>`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.background[props.color]};
  padding-left: 5px;
  padding-right: 25px;
  min-height: 25px;
  ${Icon} {
    margin-right: 5px;
  }
`;

interface Props {
  className?: string;
  title?: string;
  titleIcon?: SvgComponent;
  children: ReactNode;
  onClose?: () => void;
  color?: keyof DefaultTheme['colors']['background'];
}

export const PopupContainer: FC<Props> = ({
  className,
  children,
  title,
  titleIcon,
  onClose,
  color = 'lightPopup',
}) => (
  <Container className={className} color={color}>
    {title && (
      <TitleContainer color={color}>
        {titleIcon && <Icon svgComponent={titleIcon} size="small" fill="secondary" />}
        <Span weight="600" color="secondary">
          {title}
        </Span>
      </TitleContainer>
    )}
    <StyledCloseButton onClick={onClose} fill="secondary" />
    <ContentContainer>{children}</ContentContainer>
  </Container>
);
