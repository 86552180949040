import { isNotNull } from 'apiServices/common/guards';
import { ReactComponent as AccountDarkSvg } from 'assets/UI/AccountDark.svg';
import { ReactComponent as AccountLightSvg } from 'assets/UI/AccountLight.svg';
import { ReactComponent as DarkModeIcon } from 'assets/UI/DarkMode.svg';
import { ReactComponent as LightModeIcon } from 'assets/UI/LightModeDark.svg';
import { ReactComponent as LogOutIcon } from 'assets/UI/Logout.svg';
import { ReactComponent as LogOutIconDark } from 'assets/UI/LogoutDark.svg';
import { AccountMenu } from 'Organisms/AccountMenu';
import React, {
  FC,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { hotjarTrggerSurvey } from 'services/hotjar/triger';
import { LoginModalContext } from 'services/loginModal.service';
import { ThemeModeContext } from 'services/theme';
import { useContextAssert } from 'services/useContextAssert.hook';
import { useMobile } from 'services/useMobile';
import { usePushState } from 'services/usePushState.hook';
import { useAccountController } from 'store/accountStore/hooks';
import { useDispatch as useComparisonListDispatch } from 'store/ComparisonListsStore/hooks';
import styled, { ThemeContext } from 'styled-components/macro';
import { AccountMenuOption } from 'types/accountMenuOption';

import {
  AZButtonMobile,
  CompareButtonMobile,
  CompareButtonPublicMobile,
  SearchButtonMobile,
  SearchButtonPublicMobile,
} from './NavbarButtons';
import {
  NavbarLinksLeftSide,
  NavbarLinksLeftSidePublic,
  RightLinks,
  RightLinksPublic,
} from './NavbarLinks';
import { NavbarLogo } from './NavbarLogo';

const Navigation = styled.nav`
  width: 100%;
  background: ${props => props.theme.colors.background.navbar};
  box-shadow: 0 6px 3px -3px rgba(0, 0, 0, 0.3);
  height: 64px;
  min-height: 64px;
  z-index: 800;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 11px 0 32px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    justify-content: flex-start;
    padding: 0 20px;
  }
`;

export const Navbar: FC = () => {
  const { isAuthenticated, signOut, token } = useAccountController();

  const { isDarkMode, setIsDarkMode } = useContext(ThemeModeContext);
  const toggleTheme = useCallback(() => setIsDarkMode(!isDarkMode), [isDarkMode, setIsDarkMode]);
  const theme = useContext(ThemeContext);
  const comparisonListDispatch = useComparisonListDispatch();
  const { push } = usePushState();

  const linkRefs = useRef<(HTMLElement | null)[]>([]);
  const isMobile = useMobile('m');
  const { setOpen } = useContextAssert(LoginModalContext);

  const menuOptions = useMemo(() => {
    const account: AccountMenuOption = {
      value: 'account',
      label: 'Account',
      Icon: isDarkMode ? AccountDarkSvg : AccountLightSvg,
      onClick: () => push('/account'),
    };

    const darkModeOption: AccountMenuOption = isDarkMode
      ? {
          value: 'light-mode',
          label: 'Light mode',
          Icon: LightModeIcon,
          onClick: () => toggleTheme(),
          description: 'Dark mode is activated, click to activate light mode',
        }
      : {
          value: 'dark-mode',
          label: 'Dark mode',
          Icon: DarkModeIcon,
          onClick: () => toggleTheme(),
          description: 'Light mode is activated, click to activate dark mode',
        };

    const signOutOption: AccountMenuOption = {
      value: 'Sign-out',
      label: 'Sign out',
      Icon: isDarkMode ? LogOutIconDark : LogOutIcon,
      onClick: () => {
        signOut();
        comparisonListDispatch({ type: 'ComparisonLists/Reset' });
        hotjarTrggerSurvey();
      },
    };

    const updates: AccountMenuOption = {
      value: 'updates',
      label: 'Updates',
      Icon: theme.images.updates.folder,
      onClick: () => push('/updates'),
      description:
        'Get the latest updates, view the global disease outbreaks map, view content by numbers',
    };

    const help: AccountMenuOption = {
      value: 'help',
      label: 'Help',
      Icon: theme.images.help,
      onClick: () => (window.location.href = 'https://learn.gideononline.com'),
    };

    const eBooksUrl = `${process.env.REACT_APP_EBOOKS_URL}/login/token#${token}`;
    const ebooks: AccountMenuOption | null = eBooksUrl
      ? {
          value: 'ebooks',
          label: 'eBooks',
          Icon: theme.images.eBooks,
          onClick: () => (window.location.href = eBooksUrl),
        }
      : null;

    return [updates, account, darkModeOption, ebooks, help, signOutOption].filter(isNotNull);
  }, [
    comparisonListDispatch,
    isDarkMode,
    push,
    signOut,
    theme.images.eBooks,
    theme.images.help,
    theme.images.updates.folder,
    toggleTheme,
    token,
  ]);

  const menuOptionsPublic = useMemo(() => {
    const darkModeOption: AccountMenuOption = isDarkMode
      ? {
          value: 'light-mode',
          label: 'Light mode',
          Icon: LightModeIcon,
          onClick: () => toggleTheme(),
          description: 'Dark mode is activated, click to activate light mode',
        }
      : {
          value: 'dark-mode',
          label: 'Dark mode',
          Icon: DarkModeIcon,
          onClick: () => toggleTheme(),
          description: 'Light mode is activated, click to activate dark mode',
        };

    const signIn: AccountMenuOption = {
      value: 'Sign-in',
      label: 'Sign in',
      Icon: isDarkMode ? LogOutIconDark : LogOutIcon,
      onClick: () => push('/login'),
    };

    const updates: AccountMenuOption = {
      value: 'updates',
      label: 'Updates',
      Icon: theme.images.updates.folder,
      onClick: () => push('/updates'),
      description:
        'Get the latest updates, view the global disease outbreaks map, view content by numbers',
    };

    const help: AccountMenuOption = {
      value: 'help',
      label: 'Help',
      Icon: theme.images.help,
      onClick: () => (window.location.href = 'https://learn.gideononline.com'),
    };

    return [darkModeOption, updates, help, signIn];
  }, [isDarkMode, push, theme.images.help, theme.images.updates.folder, toggleTheme]);

  const options = useMemo(() => {
    let optionsTemp = isAuthenticated ? menuOptions : menuOptionsPublic;

    if (process.env.REACT_APP_FEATURE_ACCOUNT_SETTINGS === 'disabled') {
      optionsTemp = optionsTemp.filter(x => x.value !== 'account');
    }

    if (process.env.REACT_APP_FEATURE_UPDATES === 'disabled') {
      optionsTemp = optionsTemp.filter(x => x.value !== 'updates');
    }

    return optionsTemp;
  }, [isAuthenticated, menuOptions, menuOptionsPublic]);

  const onKeyDown = (e: KeyboardEvent, index: number): void => {
    const refs = linkRefs.current;

    if (!refs) {
      return;
    }

    if (e.key === 'ArrowRight') {
      e.preventDefault();
      const right = refs[index + 1];

      if (right) {
        right.focus();
      }
    } else if (e.key === 'ArrowLeft') {
      e.preventDefault();
      const left = refs[index - 1];

      if (left) {
        left.focus();
      }
    }
  };

  const content = useMemo((): ReactNode | null => {
    if (!isAuthenticated && isMobile) {
      // Public mobile
      return (
        <>
          <CompareButtonPublicMobile />
          <AZButtonMobile />
          <NavbarLogo to="/start" onClick={() => setOpen(true)} label="Sign In/Register" />
          <SearchButtonPublicMobile />
          <AccountMenu options={options} />
        </>
      );
    } else if (!isAuthenticated && !isMobile) {
      // Public desktop
      return (
        <>
          <NavbarLinksLeftSidePublic refs={linkRefs} />
          <NavbarLogo to="/start" onClick={() => setOpen(true)} label="Sign In/Register" />
          <RightLinksPublic accountMenuOptions={options} refs={linkRefs} onKeyDown={onKeyDown} />
        </>
      );
    } else if (isAuthenticated && !isMobile) {
      // Authed desktop
      return (
        <>
          <NavbarLinksLeftSide refs={linkRefs} />
          <NavbarLogo to="/" />
          <RightLinks accountMenuOptions={options} refs={linkRefs} onKeyDown={onKeyDown} />
        </>
      );
    } else if (isAuthenticated && isMobile) {
      // Authed mobile
      return (
        <>
          <CompareButtonMobile />
          <AZButtonMobile />
          <NavbarLogo to="/" />
          <SearchButtonMobile />
          <AccountMenu options={options} />
        </>
      );
    }

    return null;
  }, [isAuthenticated, isMobile, options, setOpen]);

  return content ? (
    <Navigation>
      <Wrapper>{content}</Wrapper>
    </Navigation>
  ) : null;
};
