import { ReactComponent as CountriesSvg } from 'assets/Navbar/Countries.svg';
import { ReactComponent as DiseasesSvg } from 'assets/Navbar/Diseases.svg';
import { ReactComponent as DrugsSvg } from 'assets/Navbar/Drugs.svg';
import { ReactComponent as MicrobesSvg } from 'assets/Navbar/Microbes.svg';
import { ReactComponent as VaccinesSvg } from 'assets/Navbar/Vaccine.svg';
import { ReactComponent as Bacteria } from 'assets/Search/Bacteria.svg';
import { ReactComponent as Mycobacteria } from 'assets/Search/Mycobacteria.svg';
import { ReactComponent as Yeasts } from 'assets/Search/Yeasts.svg';
import { SvgComponent } from 'Atoms/Icon';
import { ReactComponent as ProbabilityEngineSvg } from 'Diagnose/assets/ProbabilityEngine.svg';
import { ReactComponent as ProcessSvg } from 'Diagnose/assets/Process.svg';

export interface SecondaryNavbarLink {
  to: string;
  title: string;
  icon: SvgComponent;
}

export const exploreLinks: SecondaryNavbarLink[] = [
  {
    to: '/explore/diseases',
    title: 'Diseases',
    icon: DiseasesSvg,
  },
  {
    to: '/explore/drugs',
    title: 'Drugs',
    icon: DrugsSvg,
  },
  {
    to: '/explore/vaccines',
    title: 'Vaccines',
    icon: VaccinesSvg,
  },
  {
    to: '/explore/microbes',
    title: 'Microbes',
    icon: MicrobesSvg,
  },
  {
    to: '/explore/countries',
    title: 'Countries',
    icon: CountriesSvg,
  },
];

export const labLinks: SecondaryNavbarLink[] = [
  {
    to: '/lab/bacteria',
    title: 'Bacteria',
    icon: Bacteria,
  },
  {
    to: '/lab/mycobacteria',
    title: 'Mycobacteria',
    icon: Mycobacteria,
  },
  {
    to: '/lab/yeasts',
    title: 'Yeasts',
    icon: Yeasts,
  },
];

export const diagnoseLinks: SecondaryNavbarLink[] = [
  {
    to: '/diagnose/probability-engine',
    title: 'Probability engine',
    icon: ProbabilityEngineSvg,
  },
  {
    to: '/diagnose/steps',
    title: 'Step by Step',
    icon: ProcessSvg,
  },
];
