import markerUrl from 'assets/Map/marker.svg';
import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Span } from 'Atoms/text';
import { uniq } from 'lodash';
import React, { ReactElement } from 'react';
import { Marker } from 'react-map-gl';
import styled from 'styled-components/macro';
import Supercluster from 'supercluster';
import { isCluster, MapCluster, MapPoint, MarkerClickHandler } from 'types/map';
import { Marker as MarkerType } from 'types/map';

const getMarkerSize = (outbreaksCount: number): number => {
  const maxSize = 80;
  const minSize = 20;
  const sizeStep = 5;
  return Math.min(maxSize, Math.max(minSize, minSize + (outbreaksCount - 1) * sizeStep));
};

const MarkerButton = styled(InvisibleButton)`
  position: relative;
  transform: translate(-50%, -50%);
`;

const MarkerIcon = styled.img`
  user-select: none;
  width: 50px;
`;

const MarkerCounter = styled(Span)`
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
`;

interface Props<T extends MarkerType<unknown>> {
  outbreaksData: T;
  markerClickHandler: MarkerClickHandler<T>;
}

const OutbreaksMarker = <T extends MarkerType<unknown>>({
  outbreaksData,
  markerClickHandler,
}: Props<T>): ReactElement => (
  <Marker longitude={outbreaksData.lng} latitude={outbreaksData.lat} captureDrag={false}>
    <MarkerButton
      onClick={e => markerClickHandler(e, outbreaksData)}
      aria-label={`Click to see outbreak in ${outbreaksData.countryName}`}
      tabIndex={0}
    >
      <MarkerIcon
        style={{
          width: getMarkerSize(1) + 'px',
        }}
        src={markerUrl}
        alt="Outbreak marker icon"
        draggable="false"
        aria-hidden
      />
    </MarkerButton>
  </Marker>
);

interface ClusterMarkerProps<A extends unknown, T extends MarkerType<A>> {
  cluster:
    | Supercluster.ClusterFeature<MapCluster<A, T>>
    | Supercluster.PointFeature<MapPoint<A, T>>;
  markerClickHandler: MarkerClickHandler<T>;
}

export const OutbreaksClusterMarker = <A extends unknown, T extends MarkerType<A>>({
  cluster,
  markerClickHandler,
}: ClusterMarkerProps<A, T>): ReactElement => {
  if (isCluster<MapCluster<A, T>, MapPoint<A, T>>(cluster)) {
    const [longitude, latitude] = cluster.geometry.coordinates;

    const countryNames = uniq(cluster.properties.points.map(p => p.countryName)).join(', ');

    return (
      <Marker longitude={longitude} latitude={latitude} captureDrag={false}>
        <MarkerButton
          onClick={e => markerClickHandler(e, cluster.properties.points)}
          aria-label={`Click to see ${cluster.properties.points.length} outbreaks of ${countryNames}`}
          tabIndex={0}
        >
          <MarkerIcon src={markerUrl} alt="Outbreak marker icon" draggable="false" aria-hidden />
          <MarkerCounter color="secondary">{cluster.properties.point_count}</MarkerCounter>
        </MarkerButton>
      </Marker>
    );
  } else {
    return (
      <OutbreaksMarker
        outbreaksData={cluster.properties.point}
        markerClickHandler={markerClickHandler}
      />
    );
  }
};
