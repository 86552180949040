import { LazyLoadPlaceholder } from 'Atoms/LazyLoadPlaceholder';
import { H3, P } from 'Atoms/text';
import { LazyLoad } from 'Molecules/LazyLoad';
import { ListItem } from 'Molecules/ListItem';
import React, { ReactElement } from 'react';
import styled from 'styled-components/macro';
import { ComparisonListsSection } from 'types/comparisonLists';

const estimatedNumberOfColumns = 4;
const listElementHeight = 40;
const observerThresholdInPx = 150;
const lazyLoadDelay = 200;
const minElementsForOptimization = 10;

const Wrapper = styled.div``;

const SectionTitle = styled(P)`
  padding-bottom: 3px;
  border-bottom: 1px solid ${props => props.theme.colors.azListSection.borderColor};
`;

const SectionTitleMobile = styled(SectionTitle)`
  padding: 0 0 11px 12px;
`;

const ItemsContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemsContainer = styled.div`
  margin-top: 5px;
  padding-top: 15px;

  column-count: 4;
  column-width: 360px;
  column-gap: 20px;
`;

const AZListItem = styled(ListItem)`
  width: 380px;
  display: inline-flex;
`;

const AZListItemMobile = styled(ListItem)`
  border-bottom: 1px solid ${props => props.theme.colors.listItem.border};
  padding: 15px 14px 15px 12px;
  margin-bottom: 0;
  max-width: unset;
  width: 100%;
`;

type Entry = { id: number | string; name: string };
interface Props {
  className?: string;
  data: Entry[];
  letter: string;
  mobile?: boolean;
  exploreUrlPrefix: string;
  compareSection: ComparisonListsSection | undefined;
  useDOMOptimization?: boolean;
}

const getCompareButtonId = (id: string | number): number | undefined =>
  typeof id === 'number' ? id : undefined;

export const AZListSection = React.memo(
  ({
    className,
    data,
    letter,
    mobile,
    exploreUrlPrefix,
    compareSection,
    useDOMOptimization = false,
  }: Props): ReactElement => {
    const title = `${letter} (${data.length})`;
    const ListItem = mobile ? AZListItemMobile : AZListItem;
    const estimatedSectionHeight =
      listElementHeight * Math.ceil(data.length / estimatedNumberOfColumns);

    const listItems = data
      ? data.map(item => (
          <ListItem
            key={item.id}
            name={item.name}
            to={`${exploreUrlPrefix}/${item.id}`}
            compareButtonSection={compareSection}
            compareButtonId={getCompareButtonId(item.id)}
          />
        ))
      : null;

    if (data) {
      return (
        <Wrapper className={className} id={letter}>
          {mobile ? (
            <>
              <SectionTitleMobile weight="500" size="big" color="darkLink">
                {title}
              </SectionTitleMobile>
              {data.length > 0 && <ItemsContainerMobile>{listItems}</ItemsContainerMobile>}
            </>
          ) : (
            <>
              <SectionTitle weight="500" size="big" color="darkLink">
                {title}
              </SectionTitle>
              <LazyLoad
                debounceMs={lazyLoadDelay}
                disabled={!useDOMOptimization || data.length < minElementsForOptimization}
                threshold={observerThresholdInPx / estimatedSectionHeight}
                placeholder={
                  <LazyLoadPlaceholder
                    showLoader={false}
                    showShine={false}
                    noBackground={true}
                    height={estimatedSectionHeight}
                  />
                }
              >
                {data.length > 0 && <ItemsContainer>{listItems}</ItemsContainer>}
              </LazyLoad>
            </>
          )}
        </Wrapper>
      );
    } else {
      return <H3>No data found</H3>;
    }
  }
);
