import { parseDynamicContent } from 'services/parser/dynamicContentParser';
import { Chart } from 'types/chart';

import { ChartDTO } from './DTO';

export const mapDiseaseChartDTO = (chart: ChartDTO, chartNotes?: string): Chart => ({
  coordinates: chart.coordinates.map(c => ({ x: c.x_axis, y: c.y_axis })),
  title: chart.title,
  xTitle: chart.x_title,
  yTitle: chart.y_title,
  notes: chartNotes ? parseDynamicContent(chartNotes) : undefined,
});
