import { Icon as IconAtom, SvgComponent } from 'Atoms/Icon';
import { P } from 'Atoms/text';
import { Props as PProps } from 'Atoms/text/P';
import React, { FC } from 'react';
import styled, { css } from 'styled-components/macro';

const Caption = styled(P).attrs(
  (): Partial<PProps> => ({
    size: 'big',
  })
)`
  margin: 15px 0 0 0;
`;

interface Props {
  className?: string;
  Icon: SvgComponent;
  caption: string;
  onClick?(): void;
  active?: boolean;
}

const Button: FC<Props> = ({ className, Icon, caption, onClick }) => {
  return (
    <button className={className} onClick={onClick}>
      <IconAtom svgComponent={Icon} />
      <Caption>{caption}</Caption>
    </button>
  );
};

export const MicrobeButton = styled(Button)`
  background: ${props => props.theme.colors.button.default.backgroundColor};
  width: 150px;
  height: 150px;
  border: 1px solid ${props => props.theme.colors.button.default.borderColor};
  color: ${props => props.theme.colors.button.default.textColor};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    width: 75px;
    height: 75px;
    fill: ${props => props.theme.colors.button.default.textColor};
  }
  &:hover {
    opacity: 0.9;
  }
  ${props =>
    props.active &&
    css`
      border: 3px solid ${props => props.theme.colors.button.active.borderColor};
      background: ${props => props.theme.colors.button.active.backgroundColor};
      svg {
        fill: ${props => props.theme.colors.button.active.textColor};
      }
      ${Caption} {
        color: ${props => props.theme.colors.button.active.textColor};
      }
      &:hover {
        opacity: 1;
        cursor: unset;
      }
    `};
`;
