import { ReactComponent as DiseasesSvg } from 'assets/ExploreFocus/Diseases.svg';
import { ReactComponent as DrugsSvg } from 'assets/ExploreFocus/Drugs.svg';
import { ReactComponent as BacteriaSvg } from 'assets/Microbes/Bacteria.svg';
import { ReactComponent as MycobacteriaSvg } from 'assets/Microbes/Mycobacteria.svg';
import { ReactComponent as YeastsSvg } from 'assets/Microbes/Yeasts.svg';
import { SvgComponent } from 'Atoms/Icon';
import { ExploreFocusSection } from 'Molecules/exploreFocus/ExploreFocusSectionMobile';
import { Section } from 'pages/Compare/ComparePageCategories';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin: 33px 0 20px 0;
`;

interface Props {
  sections: Section[];
}

const icons: Record<Section['slug'], SvgComponent> = {
  diseases: DiseasesSvg,
  drugs: DrugsSvg,
  bacteria: BacteriaSvg,
  mycobacteria: MycobacteriaSvg,
  yeasts: YeastsSvg,
  virus: DrugsSvg
};

export const CompareMobileMenu: FC<Props> = ({ sections }) => (
  <Container>
    {sections.map(section => (
      <ExploreFocusSection
        key={section.name}
        caption={section.name}
        to={`/compare/${section.slug}`}
        Icon={icons[section.slug]}
      />
    ))}
  </Container>
);
