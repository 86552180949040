import axios from 'axios.cached';

export const submitRecognitionResponse = async (
  id: string,
  hash: string,
  response: string
): Promise<void> =>
  await axios.post('/api/account/surveys/how_hear/response', {
    id,
    hash,
    response,
  });
