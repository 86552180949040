import { ReactComponent as EditSvg } from 'assets/UI/Edit.svg';
import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Icon, SvgComponent } from 'Atoms/Icon';
import React, { ChangeEvent, FC, useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';

export interface Props {
  className?: string;
  type: 'text' | 'email' | 'password';
  name?: string;
  placeholder?: string;
  weight?: '400' | '500' | '600' | '700';
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  icon?: SvgComponent;
  id?: string;
  autoComplete?: string;
}

const InputWrapper = styled.div`
  border: 1px solid ${props => props.theme.colors.settings.inputBorder};
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;

  &:focus-within {
    outline: 2px solid ${props => props.theme.colors.focus};
  }
`;

const StyledInput = styled.input`
  flex-grow: 1;
  width: 100%;
  padding: 7px 0;
  border: none;
  outline: none;
  background: none;
  font: ${props => props.theme.fonts.normalTextSemiBold};
  color: ${props => props.theme.colors.settings.inputText};

  &::placeholder {
    color: ${props => props.theme.colors.text.inactive};
  }

  &::-ms-reveal {
    display: none;
  }
`;

const RevealButtonStyled = styled(InvisibleButton)`
  cursor: pointer;

  margin-right: 5px;
`;

export const AccountInput: FC<Props> = ({
  className,
  type,
  name,
  placeholder,
  onChange,
  value,
  icon,
  id,
  autoComplete,
}) => {
  const [isRevealed, setIsRevealed] = useState(false);
  const theme = useContext(ThemeContext);

  const getType = (): 'text' | 'email' | 'password' => {
    if (type === 'password') {
      return isRevealed ? 'text' : 'password';
    }

    return type;
  };

  return (
    <InputWrapper className={className}>
      <StyledInput
        id={id}
        type={getType()}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        autoComplete={autoComplete}
      />
      {type === 'password' && (
        <RevealButtonStyled onClick={() => setIsRevealed(revealed => !revealed)} type="button">
          <Icon
            svgComponent={isRevealed ? theme.images.passwordHide : theme.images.passwordShow}
            size="small"
          />
        </RevealButtonStyled>
      )}
      <Icon svgComponent={icon || EditSvg} size="small" fill="inactive" />
    </InputWrapper>
  );
};
