import { ReactComponent as Clear } from 'assets/UI/Clear.svg';
import { ButtonLabel } from 'Atoms/buttons/ButtonLabel';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { P } from 'Atoms/text';
import { Span } from 'Atoms/text';
import { ConfirmationModal } from 'Molecules/modal/ConfirmationModal';
import { SearchSelect } from 'Molecules/select/SearchSelect';
import React, { FC, useState } from 'react';
import { OnChangeValue } from 'react-select';
import styled from 'styled-components/macro';
import { CompareCategory } from 'types/compare';
import { SelectOption } from 'types/select';

const CaptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 10px 0 40px 0;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
  }
`;

const StyledP = styled(P)`
  margin: 10px 15px 0 0;

  color: ${props => props.theme.colors.comparisonCount};
`;

const StyledSelect = styled(SearchSelect)`
  margin: 10px 10px 0 0;

  width: 400px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
  }

  .${props => props.classNamePrefix}__control {
    &--is-focused,
    &--menu-is-open {
      .${props => props.classNamePrefix}__placeholder {
        display: unset;
      }
    }
  }

  .${props => props.classNamePrefix}__option {
    &--is-disabled {
      opacity: 0.5;
    }
  }
`;

const SpanStyled = styled(Span)`
  margin: 0 auto;
`;

const StyledButton = styled(FilledButton)`
  width: 150px;
  border-radius: 5px;
  padding: 4px 15px;
  box-shadow: none;
  height: 44px;

  &:hover {
    ${SpanStyled} {
      color: ${props => props.theme.colors.button.hover.textColor};
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
    margin: 10px 0 0 0;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0 0 0;
`;

interface Props {
  className?: string;
  countText: string;
  options: SelectOption[];
  onChange?: (selected: OnChangeValue<SelectOption, false>) => void;
  isListFull?: boolean;
  isLoading?: boolean;
  onClear?: () => void;
  compareCategory: CompareCategory;
}

export const CompareTopCaption: FC<Props> = ({
  className,
  countText,
  options,
  onChange,
  isListFull,
  isLoading,
  onClear,
  compareCategory,
}) => {
  const [confirmationModal, setConfirmationModal] = useState(false);

  const clearRecordType =
    compareCategory === 'diseases'
      ? 'diseases'
      : compareCategory === 'drugs'
      ? 'drugs'
      : 'organisms';

  return (
    <CaptionWrapper className={className}>
      <LeftContainer>
        <StyledP>
          You have <strong>{countText}</strong> in your comparison table.
        </StyledP>
        {!isListFull && (
          <StyledSelect
            classNamePrefix="comparison-table-select"
            options={options}
            placeholder="Would you like to add more?"
            onChange={onChange}
            isLoading={isLoading}
            ariaLabel="Add more"
            showIcon
            controlShouldRenderValue={false}
          />
        )}
      </LeftContainer>
      <RightContainer>
        {onClear && (
          <StyledButton onClick={() => setConfirmationModal(true)}>
            <Icon svgComponent={Clear} fill="inactive" size="small" />
            <ButtonLabel color="inactive">Clear table</ButtonLabel>
          </StyledButton>
        )}
      </RightContainer>
      <ConfirmationModal
        isOpen={confirmationModal}
        description={`Clear all ${clearRecordType} from ${compareCategory} comparison table?`}
        onClose={() => setConfirmationModal(false)}
        onCancel={() => setConfirmationModal(false)}
        onConfirm={() => {
          onClear && onClear();
          setConfirmationModal(false);
        }}
        cancelLabel="No"
        confirmLabel="Yes"
      />
    </CaptionWrapper>
  );
};
