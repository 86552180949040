import { Link } from 'Atoms/links/Link';
import React, { createContext, FC, useContext } from 'react';
import { useReference } from 'services/referenceProvider/hooks';

export const ReferenceLabelContext = createContext<string | undefined>(undefined);

interface Props {
  className?: string;
  type: 'm' | 'p';
  href?: string;
  value: string;
}

export const ReferenceMarker: FC<Props> = ({ className, type, value }) => {
  const { index, openReference } = useReference(`${type} ${value}`);

  const label = useContext(ReferenceLabelContext);

  return (
    <sup className={className}>
      <Link
        to="#"
        onClick={openReference}
        size="reference"
        noUnderline
        aria-label={`${label} reference ${index}`}
      >
        [{index}]
      </Link>
    </sup>
  );
};
