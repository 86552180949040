import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { OrderedList, OrderedListItem } from 'Atoms/OrderedList';
import { P } from 'Atoms/text';
import { DrugSpectrumModal } from 'Molecules/drug/DrugSpectrumModal';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';
import { DynamicContent } from 'types/dynamicContent';
import { DrugSusceptibility } from 'types/microbeDetails';

const Wrapper = styled.div``;

const Category = styled.div`
  margin: 20px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledP = styled(P)`
  margin-bottom: 10px;
`;

const StyledButton = styled(InvisibleButton)`
  color: ${props => props.theme.colors.text.darkLink};
  font: ${props => props.theme.fonts.normalText};
  cursor: pointer;
`;

interface Props {
  notes: DynamicContent;
  ecology: DynamicContent;
  synonyms?: string[];
  susceptibility?: DrugSusceptibility[];
}

export const MicrobeSummarySection: FC<Props> = ({ notes, ecology, synonyms, susceptibility }) => {
  const [isSpectrumOpen, setSpectrumOpen] = useState<boolean>(false);

  return (
    <Wrapper>
      {notes.length > 0 && (
        <Category>
          <StyledP weight="700">Notes</StyledP>
          <DynamicHtml content={notes} />
        </Category>
      )}
      {ecology.length > 0 && (
        <Category>
          <StyledP weight="700">Ecology</StyledP>
          <DynamicHtml content={ecology} />
        </Category>
      )}
      {susceptibility && susceptibility.length > 0 && (
        <Category>
          <StyledP weight="700">Drug Susceptibility</StyledP>
          {susceptibility.map(x => (
            <StyledButton key={x.code} onClick={() => setSpectrumOpen(true)}>
              {x.pathogen}
            </StyledButton>
          ))}
          <DrugSpectrumModal
            isOpen={isSpectrumOpen}
            onClose={() => setSpectrumOpen(false)}
            initialSpectrumValues={susceptibility.map(x => ({
              value: x.code.toString(),
              label: x.pathogen,
            }))}
          />
        </Category>
      )}
      {synonyms && synonyms.length > 0 && (
        <Category>
          <StyledP weight="700">Synonyms ({synonyms.length})</StyledP>
          <OrderedList>
            {synonyms.map((s: string) => (
              <OrderedListItem key={s}>{s}</OrderedListItem>
            ))}
          </OrderedList>
        </Category>
      )}
    </Wrapper>
  );
};
