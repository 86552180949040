import { DataDTO } from 'apiServices/common/DTO';
import axios from 'axios.cached';
import { parseDynamicContent } from 'services/parser/dynamicContentParser';
import { CustomError, ErrorCodes } from 'types/errorTypes';
import { ReferenceDetails, ReferenceIdentifier, ReferenceSourceCodes } from 'types/reference';

import { ReferenceDetailsDTO } from './DTO';

const parseSourceCode = (sourceCode: string): ReferenceSourceCodes => {
  if (sourceCode === 'm' || sourceCode === 'p') {
    return sourceCode;
  }

  throw new Error('unsupported source code');
};

const getReferenceCode = (id: ReferenceIdentifier): string =>
  `${parseSourceCode(id[0])}${id.substring(2)}`;

export const getReferencesDetails = async (
  referenceIdentifiers: ReferenceIdentifier[]
): Promise<ReferenceDetails[]> => {
  const codes = referenceIdentifiers.map(getReferenceCode);

  const params = new URLSearchParams();
  params.append('ids', codes.toString());

  const referenceResponse = await axios.get<DataDTO<ReferenceDetailsDTO[]>>(
    `/api/references?${params}`
  );

  const data = referenceResponse.data.data;

  return data.map(x => ({
    sourceCode: parseSourceCode(x.source_code),
    referenceNumber: x.reference_number,
    referenceSource: x.reference_source,
    title: x.title,
    authors: x.authors,
    abstract: x.abstract,
    citation: parseDynamicContent(x.citation),
    shortCitation: x.short_citation,
    url: x.url,
  }));
};

export const getReferenceDetailsPublic = async (
  id: string,
  type: ReferenceSourceCodes
): Promise<ReferenceDetails> => {
  const referenceResponse = await axios.get<DataDTO<ReferenceDetailsDTO | null>>(
    `/api/public/references/${type}/${id}`
  );

  const data = referenceResponse.data.data;

  if (!data) {
    throw new CustomError('Data is null', ErrorCodes.NotFound);
  }

  return {
    sourceCode: parseSourceCode(data.source_code),
    referenceNumber: data.reference_number,
    referenceSource: data.reference_source,
    title: data.title,
    authors: data.authors,
    abstract: data.abstract,
    citation: parseDynamicContent(data.citation),
    shortCitation: data.short_citation,
    url: data.url,
  };
};

export const getReferencesDetailsPublic = async (
  referenceIdentifiers: ReferenceIdentifier[]
): Promise<ReferenceDetails[]> => {
  const requests = referenceIdentifiers.map(id => {
    const type = id.split(' ')[0];
    const number = id.split(' ')[1];

    return axios.get<DataDTO<ReferenceDetailsDTO>>(`/api/public/references/${type}/${number}`);
  });

  const responses = await Promise.all(requests);

  return responses.map(res => {
    const data = res.data.data;

    return {
      sourceCode: parseSourceCode(data.source_code),
      referenceNumber: data.reference_number,
      referenceSource: data.reference_source,
      title: data.title,
      authors: data.authors,
      abstract: data.abstract,
      citation: parseDynamicContent(data.citation),
      shortCitation: data.short_citation,
      url: data.url,
    };
  });
};
