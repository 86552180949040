import { ReactComponent as SearchIcon } from 'assets/Navbar/Search.svg';
import { ReactComponent as Document } from 'assets/Search/Document.svg';
import { ReactComponent as Graph } from 'assets/Search/Graph.svg';
import { ReactComponent as Image } from 'assets/Search/Image.svg';
import { ReactComponent as AllIcon } from 'assets/UI/All.svg';
import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Icon } from 'Atoms/Icon';
import { Label } from 'Atoms/Label';
import { Loader } from 'Atoms/Loader';
import { Span } from 'Atoms/text';
import { SearchSelect } from 'Molecules/select/SearchSelect';
import React, { FC, FormEvent, KeyboardEvent, RefObject, useMemo } from 'react';
import Autosuggest, {
  ChangeEvent,
  OnSuggestionsClearRequested,
  OnSuggestionSelected,
  SuggestionsFetchRequested,
} from 'react-autosuggest';
import { OnChangeValue } from 'react-select';
import styled from 'styled-components/macro';
import { isSearchFilterType, SearchFilterOption, SearchFilterType } from 'types/search';
import { SelectOption } from 'types/select';

const FilterSelect = styled(SearchSelect)`
  max-width: 170px;
  height: 50px;

  .${props => props.classNamePrefix}__control {
    border: solid ${props => props.theme.colors.accountMenu.default.borderColor};
    border-width: 1px;
    border-radius: 10px 0 0 10px;
    height: 100%;

    padding: 4px 0 4px 12px;
    background: ${props => props.theme.colors.background.searchBar};

    &:hover {
      border-color: ${props => props.theme.colors.accountMenu.hover.borderColor};
    }

    &--is-focused {
      border: 2px solid ${props => props.theme.colors.focus};
    }

    &--menu-is-open {
      border: solid ${props => props.theme.colors.select.active.border};
      border-width: 2px 2px 0 2px;
      border-radius: 10px 10px 0 0;
      margin: -2px;
    }
  }

  .${props => props.classNamePrefix}__search-icon {
    width: 20px;
    height: 20px;

    margin-right: 10px;
  }

  .${props => props.classNamePrefix}__menu {
    width: calc(100% + 4px);
    left: -2px;
    top: calc(100% - 2px);

    min-width: 150px;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    max-width: 50px;

    .${props => props.classNamePrefix}__control {
      border-radius: 10px 0 0 10px;
      &--menu-is-open {
        border: 1px solid ${props => props.theme.colors.accountMenu.default.borderColor};
        margin: unset;
      }
    }

    .${props => props.classNamePrefix}__single-value,
      .${props => props.classNamePrefix}__dropdown-indicator {
      display: none;
    }

    .${props => props.classNamePrefix}__search-icon {
      margin-right: unset;
    }

    .${props => props.classNamePrefix}__menu {
      border-width: 2px;
      border-radius: 10px;
      top: 100%;

      min-width: 150px;
    }
  }
`;

const SearchIconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 15px 0 0;
  border: solid ${props => props.theme.colors.accountMenu.default.borderColor};
  border-width: 1px 1px 1px 0;
  border-radius: 0 10px 10px 0;
`;

const SearchBarStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 50px;
  background: ${props => props.theme.colors.background.searchBar};
  border-radius: 10px;
  position: relative;

  width: 100%;
  max-width: 880px;

  .react-autosuggest__container {
    display: flex;
    align-items: center;

    width: 100%;
    height: 100%;
  }

  .react-autosuggest__input {
    width: 100%;
    height: 100%;

    padding: 0 10px;

    background: unset;

    border: solid ${props => props.theme.colors.accountMenu.default.borderColor};
    border-width: 1px 0 1px 0;

    color: ${props => props.theme.colors.text.input};

    font-size: 30px;
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      font-size: 20px;
    }

    font-weight: 600;
    &::-webkit-input-placeholder,
    &::placeholder {
      font-weight: 500;
    }

    &:focus {
      border: 2px solid ${props => props.theme.colors.focus};
      outline: none;
    }

    z-index: 100;
  }

  .react-autosuggest__suggestions-container {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 100;
    max-height: 300px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    overflow: auto;

    background: ${props => props.theme.colors.background.searchBar};
  }

  .react-autosuggest__suggestions-container--open {
    border: 1px solid ${props => props.theme.colors.accountMenu.default.borderColor};
    border-radius: 10px;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 10px 0;
  }

  .react-autosuggest__suggestion {
    list-style: none;
    cursor: pointer;
    margin: 0;

    padding: 10px 40px;

    @media (max-width: ${props => props.theme.breakpoints.m}) {
      padding: 10px 20px;
    }

    &:hover {
      background-color: ${props => props.theme.colors.select.hover.option};
    }
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: ${props => props.theme.colors.select.hover.option};
  }
`;

const SearchIconStyled = styled(Icon)`
  flex-shrink: 0;
`;

const LoaderContainer = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
`;

const InvisibleButtonStyled = styled(InvisibleButton)`
  cursor: pointer;
`;

const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 880px;
`;

const LabelStyled = styled(Label)`
  margin: 10px 0;
`;

const filterValues: SearchFilterOption[] = [
  {
    value: 'all',
    label: 'All',
    Icon: AllIcon,
  },
  {
    value: 'document',
    label: 'Documents',
    Icon: Document,
  },
  {
    value: 'graph',
    label: 'Graphs',
    Icon: Graph,
  },
  {
    value: 'image',
    label: 'Images',
    Icon: Image,
  },
];

interface Props {
  className?: string;
  value: string;
  onChange: (e: FormEvent<HTMLElement>, params: ChangeEvent) => void;
  suggestions: string[];
  onSuggestionsClearRequested: OnSuggestionsClearRequested;
  onSuggestionsFetchRequested: SuggestionsFetchRequested;
  onSuggestionSelected: OnSuggestionSelected<string>;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  onChangeFilter: (value: SearchFilterType) => void;
  filterValue: SearchFilterType;
  innerRef: RefObject<Autosuggest>;
  onClickSubmit: () => void;
  isLoading: boolean;
}

export const SearchBar: FC<Props> = ({
  className,
  value,
  onChange,
  suggestions,
  onSuggestionsClearRequested,
  onSuggestionsFetchRequested,
  onSuggestionSelected,
  onKeyDown,
  innerRef,
  onChangeFilter,
  filterValue,
  onClickSubmit,
  isLoading,
}) => {
  const onChangeSelectFilter = (value: OnChangeValue<SelectOption, false>): void => {
    if (value && isSearchFilterType(value.value)) {
      onChangeFilter(value.value);
    }
  };

  const filterSelectValue = useMemo(() => filterValues.find(fv => fv.value === filterValue), [
    filterValue,
  ]);

  return (
    <Container className={className}>
      <LabelStyled color="main" size="medium" htmlFor="search-input" font="Quicksand">
        Filter and search across all GIDEON records
      </LabelStyled>
      <SearchBarStyled role="search">
        <FilterSelect
          classNamePrefix="filter-select"
          placeholder=""
          options={filterValues}
          value={filterSelectValue}
          onChange={onChangeSelectFilter}
          showIcon
          isSearchable={false}
          showDropdown
          ariaLabel="Search filter"
          id="search-filter"
        />
        <Autosuggest
          suggestions={suggestions}
          renderSuggestion={suggestion => <Span size="big">{suggestion}</Span>}
          getSuggestionValue={suggestion => suggestion}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          inputProps={{
            placeholder: 'Search',
            value,
            onChange,
            onKeyDown,
            id: 'search-input',
            'aria-label': 'Search',
          }}
          ref={innerRef}
          containerProps={{
            'aria-label': 'Search',
          }}
        />
        <SearchIconContainer>
          {isLoading && (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
          <InvisibleButtonStyled onClick={onClickSubmit} aria-label="Submit search" type="button">
            <SearchIconStyled svgComponent={SearchIcon} fill="input" size="medium" />
          </InvisibleButtonStyled>
        </SearchIconContainer>
      </SearchBarStyled>
    </Container>
  );
};
