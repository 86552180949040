import { Icon } from 'Atoms/Icon';
import { Link } from 'Atoms/links/Link';
import { OrderedList, OrderedListItem } from 'Atoms/OrderedList';
import { P, Span } from 'Atoms/text';
import { CompleteTooltip } from 'Atoms/tooltip/CompleteTooltip';
import { ReactComponent as InfoIcon } from 'Lab/assets/information.svg';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Vaccine } from 'types/disease';
import { DynamicContent } from 'types/dynamicContent';
import { SimpleDisease } from 'types/simpleDisease';

const Section = styled.div``;

const StyledTooltip = styled(CompleteTooltip)`
  top: -10px;
  right: -25px;
`;

const StyledPBase = styled(P)`
  position: relative;
  display: inline-block;
  margin: 20px 0 0;
`;

const StyledP = styled(StyledPBase)`
  margin-bottom: 10px;
`;

const SynonymsHeading = styled(StyledPBase)`
  margin: 10px 0;
`;

const StyledLink = styled(Link)`
  display: block;
  margin-bottom: 5px;
`;

const Notification = styled(StyledP)`
  margin-bottom: 0px;

  display: flex;
  align-items: center;
`;

const NotificationText = styled(Span)`
  margin: 0 0 0 10px;
`;

interface DiseaseDetails {
  agentText: DynamicContent;
  reservoirText: DynamicContent;
  vectorText: DynamicContent;
  vehicleText: DynamicContent;
  incubation: DynamicContent;
  diagnosticTests: DynamicContent;
  adultTherapy?: DynamicContent;
  pediatricTherapy?: DynamicContent;
  vaccines?: Vaccine[];
  clinicalHints?: DynamicContent;
  synonyms?: DynamicContent[];
  relatedDiseases?: SimpleDisease[];
}

interface Props {
  details: DiseaseDetails;
  diseaseId?: number;
}

export const DiseaseSummarySection: FC<Props> = ({ details, diseaseId }) => (
  <>
    <Section>
      <StyledP weight="700">
        Agent
        <StyledTooltip content="Classification (e.g., virus, parasite) and taxonomic designation." />
      </StyledP>
      <P>
        <DynamicHtml content={details.agentText} />
      </P>
    </Section>
    <Section>
      <StyledP weight="700">
        Reservoir
        <StyledTooltip content="Any animal, arthropod, plant, soil or substance in which an infectious agent normally lives and multiplies, on which it depends primarily for survival, and where it reproduces itself in such a manner that it can be transmitted to a susceptible host." />
      </StyledP>
      <P>
        <DynamicHtml content={details.reservoirText} />
      </P>
    </Section>
    <Section>
      <StyledP weight="700">
        Vector
        <StyledTooltip content="An arthropod or other living carrier which transports an infectious agent from an infected organism or reservoir to a susceptible individual or immediate surroundings." />
      </StyledP>
      <P>
        <DynamicHtml content={details.vectorText} />
      </P>
    </Section>
    <Section>
      <StyledP weight="700">
        Vehicle
        <StyledTooltip content="The mode of transmission for an infectious agent. This generally implies a passive and inanimate (i.e., non-vector) mode." />
      </StyledP>
      <P>
        <DynamicHtml content={details.vehicleText} />
      </P>
      {diseaseId && (
        <Notification>
          <Icon size="small" fill="inactive" svgComponent={InfoIcon} />
          <NotificationText>
            For further details and references related to agents, vectors, vehicles, and reservoirs,
            see the <Link to={`/explore/diseases/${diseaseId}/worldwide`}>Worldwide note</Link>
          </NotificationText>
        </Notification>
      )}
    </Section>
    <Section>
      <StyledP weight="700">
        Incubation Period
        <StyledTooltip content="The minimal and maximal number of days which might elapse between exposure to an infectious agent and appearance of the first signs or symptoms of the disease." />
      </StyledP>
      <P>
        <DynamicHtml content={details.incubation} />
      </P>
    </Section>
    <Section>
      <StyledP weight="700">
        Diagnostic Tests
        <StyledTooltip content="A summary of available laboratory, imaging, and other tests for the disease." />
      </StyledP>
      <P>
        <DynamicHtml content={details.diagnosticTests} />
      </P>
    </Section>
    {details.adultTherapy && (
      <Section>
        <StyledP weight="700">
          Typical adult therapy
          <StyledTooltip content="Currently recommended drugs and dosage schedules for uncomplicated disease in an adult." />
        </StyledP>
        <P>
          <DynamicHtml content={details.adultTherapy} />
        </P>
      </Section>
    )}
    {details.pediatricTherapy && (
      <Section>
        <StyledP weight="700">
          Typical pediatric therapy
          <StyledTooltip content="Currently recommended drugs and dosage schedules for uncomplicated disease in a child." />
        </StyledP>
        <P>
          <DynamicHtml content={details.pediatricTherapy} />
        </P>
      </Section>
    )}
    {details.vaccines && details.vaccines.length > 0 && (
      <Section>
        <StyledP weight="700">
          Vaccines
          <StyledTooltip content="Vaccines and globulin preparations." />
        </StyledP>
        {details.vaccines.map(x => (
          <StyledLink key={x.code} to={`/explore/vaccines/${x.code}`}>
            {x.vaccine}
          </StyledLink>
        ))}
      </Section>
    )}
    {details.clinicalHints && (
      <Section>
        <StyledPBase weight="700">
          Clinical hints
          <StyledTooltip content="A brief summary of typical signs and symptoms of the disease." />
        </StyledPBase>
        <DynamicHtml content={details.clinicalHints} />
      </Section>
    )}
    {details.relatedDiseases && details.relatedDiseases.length > 0 && (
      <Section>
        <StyledPBase weight="700">Related diseases ({details.relatedDiseases.length})</StyledPBase>
        <ul>
          {details.relatedDiseases.map(d => (
            <li key={d.id}>
              <StyledLink to={`/explore/diseases/${d.id}`}>{d.name}</StyledLink>
            </li>
          ))}
        </ul>
      </Section>
    )}
    {details.synonyms && (
      <Section>
        <SynonymsHeading weight="700">Synonyms ({details.synonyms.length})</SynonymsHeading>
        <OrderedList>
          {details.synonyms.map((s, i) => (
            <OrderedListItem key={i}>
              <DynamicHtml content={s} />
            </OrderedListItem>
          ))}
        </OrderedList>
      </Section>
    )}
  </>
);
