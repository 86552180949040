import { useEffect } from 'react';
import { useDispatch, useState } from 'store/ReferenceListStore/hooks';
import { ReferenceIdentifier } from 'types/reference';

export const useReference = (
  referenceIdentifier: ReferenceIdentifier
): { index: number; openReference: () => void } => {
  const state = useState();
  const dispatch = useDispatch();
  const index = state.index[referenceIdentifier];

  const openReference = (): void => {
    dispatch({ type: 'ReferenceList/OpenModal', payload: { reference: referenceIdentifier } });
  };

  useEffect(() => {
    if (!index) {
      dispatch({ type: 'ReferenceList/AddReference', payload: { referenceIdentifier } });
    }
  }, [dispatch, index, referenceIdentifier]);

  return {
    index,
    openReference,
  };
};

export const useOpenAllReferences = (): {
  openAllReferences: () => void;
  referenceCount: number;
} => {
  const state = useState();
  const dispatch = useDispatch();
  const openAllReferences = (): void => {
    dispatch({ type: 'ReferenceList/OpenModal', payload: { reference: 'all' } });
  };
  return {
    openAllReferences,
    referenceCount: state.list.length,
  };
};

export const useReferenceClose = (): { closeReferences: () => void } => {
  const dispatch = useDispatch();
  const closeReferences = (): void => {
    dispatch({ type: 'ReferenceList/CloseModal' });
  };
  return {
    closeReferences,
  };
};
