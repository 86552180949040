export interface ComparisonLists {
  diseases: number[];
  drugs: number[];
  bacteria: number[];
  mycobacteria: number[];
  yeasts: number[];
  virus: number[];
}

export type ComparisonListsSection = keyof ComparisonLists;

export const isComparisonListsSection = (value: string): value is ComparisonListsSection =>
  value === 'diseases' ||
  value === 'drugs' ||
  value === 'bacteria' ||
  value === 'mycobacteria' ||
  value === 'yeasts' ||
  value === 'virus';

export const getComparisonListsSection = (value: string): ComparisonListsSection | undefined =>
  isComparisonListsSection(value) ? value : undefined;
