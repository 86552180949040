import { getDiseaseSynonymsPublic } from 'apiServices/Diseases/diseases';
import { ReactComponent as FingerprintSVG } from 'assets/UI/Fingerprint.svg';
import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Icon } from 'Atoms/Icon';
import { ExploreLayout } from 'layouts/ExploreLayout';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { LoginModalContext } from 'services/loginModal.service';
import { useContextAssert } from 'services/useContextAssert.hook';
import { useMobile } from 'services/useMobile';
import { useDiseasesResource } from 'store/diseasesStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';

const ButtonStyled = styled(InvisibleButton)`
  opacity: 0.5;
`;

const FingerprintIcon = styled(Icon)`
  width: 50px;
  height: 50px;
  fill: ${props => props.theme.colors.text.inactive};
  cursor: pointer;

  &:hover {
    fill: ${props => props.theme.colors.text.lightLink};
  }
`;

export const DiseasesPublic: FC = () => {
  const diseasesState = useDiseasesResource();
  const isMobile = useMobile('s');

  assertIsNotStoreError(diseasesState);

  const { setOpen } = useContextAssert(LoginModalContext);

  const selectOptions = !isLoading(diseasesState)
    ? diseasesState.list.map(id => ({
        value: id.toString(),
        label: diseasesState.data[id].name,
        isSynonym: false,
      }))
    : [];

  const loadSynonymOptions = async (inputValue: string): Promise<SelectOption[]> => {
    const synonyms = await getDiseaseSynonymsPublic(inputValue);
    return !isLoading(diseasesState)
      ? synonyms.map(x => ({
          label: x.name,
          value: x.id.toString(),
          synonymOf: diseasesState.data[x.id].name,
        }))
      : [];
  };

  return (
    <>
      <Helmet>
        <title>Explore diseases - GIDEON</title>
        <meta
          name="description"
          content="Explore information on 361 infectious diseases, discover diseases by their fingerprint, view 23,500+ country notes and nearly 26,000 outbreak records."
        />
      </Helmet>
      <ExploreLayout
        title="Explore GIDEON data on diseases"
        subtitle="Includes bacterial, fungal, parasitic and viral infections"
        imageName="diseases"
        defaultOptions={selectOptions}
        placeholder={isMobile ? 'Enter disease name' : 'Start typing disease name'}
        section="diseases"
        tooltipContent="A fingerprint is an epidemiological attribute shared by a group of diseases - a type of agent, a reservoir in nature, vector and vehicle."
        loadSynonymOptions={loadSynonymOptions}
        optionTitle="Search by fingerprint"
        selectLabel="Filter and search across all disease records"
      >
        <ButtonStyled aria-label="Open fingerprint" onClick={() => setOpen(true)}>
          <FingerprintIcon svgComponent={FingerprintSVG} />
        </ButtonStyled>
      </ExploreLayout>
    </>
  );
};
