import { Props as SortProps, SortButton } from 'Atoms/buttons/SortButton';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const CellContent = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSortButton = styled(SortButton)`
  margin-left: 10px;
`;

interface Props extends SortProps {
  text: string;
  showSort?: boolean;
}

export const CellWithSort: FC<Props> = ({ text, showSort = true, ...rest }) => (
  <CellContent>
    {text}
    {showSort && <StyledSortButton label={text} {...rest} />}
  </CellContent>
);
