import { DataDTO } from 'apiServices/common/DTO';
import axios from 'axios.cached';
import moment from 'moment';
import { Moment } from 'moment';
import { AccountSettings, LoginRequestReportFilterKey } from 'types/accountSettings';

import { mapAccountSettings, mapAccountSettingsDTO } from './accountSettings.map';
import { AccountSettingsDTO, LoginRequestReportDTO, LoginRequestSummaryDTO } from './DTO';

export const getAccountSettings = async (): Promise<AccountSettings> => {
  const settings = await axios.get<DataDTO<AccountSettingsDTO>>('/api/account/settings');
  const data = settings.data.data;

  return mapAccountSettingsDTO(data);
};

export const updateAccountSettings = async (
  settings: AccountSettings
): Promise<AccountSettings> => {
  const res = await axios.patch<DataDTO<AccountSettingsDTO>>(
    '/api/account/settings',
    mapAccountSettings(settings)
  );

  return mapAccountSettingsDTO(res.data.data);
};

export const changeAccountPassword = async (
  currentPassword: string,
  newPassword: string
): Promise<void> => {
  await axios.post('/api/account/settings/password', {
    current_password: currentPassword,
    new_password: newPassword,
  });

  return;
};

export const changeAccountEmail = async (password: string, email: string): Promise<void> => {
  await axios.post('/api/account/settings/change-email', {
    email,
    password,
    link: `${process.env.REACT_APP_URL}/confirm-email`,
  });

  return;
};

export const confirmAccountEmail = async (token: string): Promise<void> => {
  await axios.post('/api/auth/confirm-email', {
    token,
  });

  return;
};

export const getLoginRequestsReport = async (
  from: Moment,
  to: Moment,
  filterKey?: LoginRequestReportFilterKey,
  filterValue?: string
): Promise<string> => {
  const params = new URLSearchParams({
    from: from.unix().toString(),
    to: to.unix().toString(),
    return: 'detailed',
  });

  if (filterKey) {
    params.append('filter_key', filterKey);
  }

  if (filterValue) {
    params.append('filter_value', filterValue);
  }

  const logins = await axios.get<LoginRequestReportDTO[]>(
    `/api/account/reports/requests/logins?${params.toString()}`
  );

  const loginFields = ['ip', 'concurrency_count', 'login_time_utc', 'session_length'];

  const csv = [
    loginFields,
    ...logins.data.map(d => [
      d.ip,
      d.concurrency_count,
      d.login_time_utc.replace('T', ' ').replace('Z', ''),
      moment.utc(d.session_length * 1000).format('HH:mm:ss'),
    ]),
  ]
    .map(d => d.join(','))
    .join('\n');

  return csv;
};

export const getModuleName = (module: string): string => {
  const word = module.split('_').join(' ');

  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getLoginRequestsSummary = async (
  from: Moment,
  to: Moment,
  filterKey?: LoginRequestReportFilterKey,
  filterValue?: string
): Promise<string> => {
  const params = new URLSearchParams({
    from: from.unix().toString(),
    to: to.unix().toString(),
    return: 'detailed',
  });

  if (filterKey) {
    params.append('filter_key', filterKey);
  }

  if (filterValue) {
    params.append('filter_value', filterValue);
  }

  const summary = await axios.get<LoginRequestSummaryDTO[]>(
    `/api/account/reports/requests/summary?${params.toString()}`
  );

  const summaryFields: (keyof LoginRequestSummaryDTO)[] = ['module', 'view_count'];

  const modules = summary.data
    .filter(s => s.module !== 'account' && s.module !== 'internal')
    .map(d =>
      d.module === 'auth' ? ['Login count', d.view_count] : [getModuleName(d.module), d.view_count]
    );

  const csv = [summaryFields, ...modules].map(d => d.join(',')).join('\n');

  return csv;
};
