import { Link } from 'Atoms/links/Link';
import { ErrorBoundary } from 'errorBoundary';
import { ContentError } from 'Molecules/ContentError';
import { DynamicTable } from 'Organisms/dynamicContent/DynamicTable';
import { ParserError } from 'Organisms/dynamicContent/ParserError';
import { ReferenceMarker } from 'Organisms/dynamicContent/ReferenceMarker';
import {
  CountryVaccinationSchedule,
  CountryVaccinationScheduleForDisease,
} from 'Organisms/dynamicContent/VaccinationSchedule';
import { GraphAndTableLazy } from 'Organisms/GraphAndTable';
import React, { FC, ReactElement } from 'react';
import { DynamicContent, Node } from 'types/dynamicContent';

interface BuilderProps {
  node: Node;
}

const renderChildren = (children: Node[]): ReactElement[] => {
  return children.map((child, index) => <DynamicHtmlBuilder key={index} node={child} />);
};

const DynamicHtmlBuilder = ({ node }: BuilderProps): ReactElement => {
  switch (node.type) {
    case 'text': {
      return <>{node.value}</>;
    }
    case 'b': {
      return <b>{renderChildren(node.children)}</b>;
    }
    case 'i': {
      return <i>{renderChildren(node.children)}</i>;
    }
    case 'u': {
      return <u>{renderChildren(node.children)}</u>;
    }
    case 'newLine': {
      return <br />;
    }
    case 'reference m': {
      return <ReferenceMarker type="m" value={node.value} />;
    }
    case 'reference p': {
      return <ReferenceMarker type="p" value={node.value} />;
    }
    case 'ul': {
      return <ul>{renderChildren(node.children)}</ul>;
    }
    case 'li': {
      return <li>{renderChildren(node.children)}</li>;
    }
    case 'Link':
      return <Link to={node.to}>{renderChildren(node.children)}</Link>;
    case 'chart': {
      return (
        <ErrorBoundary error={props => <ContentError title="Chart Error" {...props} />}>
          <GraphAndTableLazy
            graphId={node.id}
            diseaseId={node.diseaseId}
            countryId={node.countryId}
          />
        </ErrorBoundary>
      );
    }
    case 'table': {
      return (
        <ErrorBoundary error={props => <ContentError title="Outbreaks Table Error" {...props} />}>
          <DynamicTable
            tableType={node.tableType}
            diseaseId={node.diseaseId}
            countryId={node.countryId}
            title={node.title}
            subTitle={node.subTitle}
          />
        </ErrorBoundary>
      );
    }
    case 'vaccineSchedule': {
      const diseaseId = parseInt(node.diseaseId, 10);
      return (
        <ErrorBoundary error={props => <ContentError title="Vaccine Schedule Error" {...props} />}>
          {diseaseId >= 0 ? (
            <CountryVaccinationScheduleForDisease
              countryId={node.countryId}
              diseaseId={diseaseId}
            />
          ) : (
            <CountryVaccinationSchedule countryId={node.countryId} />
          )}
        </ErrorBoundary>
      );
    }
    case 'unsupported': {
      return React.createElement(node.tagName, node.attributes, renderChildren(node.children));
    }
    case 'parsererror': {
      return <ParserError node={node} />;
    }
    default: {
      const _ignore: never = node;
      return <></>; // this should be unreachable
    }
  }
};

export interface Props {
  content: DynamicContent;
}

export const DynamicHtml: FC<Props> = ({ content }) => {
  const result = (
    <ErrorBoundary error={props => <ContentError title="Dynamic Content Error" {...props} />}>
      {renderChildren(content)}
    </ErrorBoundary>
  );

  return result;
};
