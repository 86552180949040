import { ImageDTO } from 'apiServices/common/DTO';
import { mapImageDTO } from 'apiServices/common/mappers';
import { SimpleDisease as SimpleDiseasesDTO, VaccineDTO } from 'apiServices/Diseases/DTO';
import { parseDynamicContent } from 'services/parser/dynamicContentParser';
import { Vaccine } from 'types/disease';
import {
  DrugSusceptibility,
  MicrobeDetails,
  MicrobeDetailsPublic,
  Phenotype,
} from 'types/microbeDetails';
import { SimpleMicrobe } from 'types/simpleMicrobe';
import { replaceCaseInsensitive } from 'utils/replacer';

import {
  BacteriaDetailsDTO,
  BacteriaDetailsPublicDTO,
  DrugSusceptibilityDTO,
  MycobacteriaDetailsDTO,
  MycobacteriaDetailsPublicDTO,
  PhenotypeDTO,
  SimpleBacteriaDTO,
  SimpleMycobacteriaDTO,
  SimpleVirusDTO,
  SimpleYeastDTO,
  YeastsDetailsDTO,
  YeastsDetailsPublicDTO,
} from './DTO';

export const mapDrugSusceptibilityDTO = (x: DrugSusceptibilityDTO): DrugSusceptibility => ({
  code: x.pathogen_code,
  pathogen: x.pathogen,
  isDrugClass: x.is_drug_class,
});

export const mapVaccineDTO = (vaccine: VaccineDTO): Vaccine => ({
  code: vaccine.vaccine_code,
  vaccine: vaccine.vaccine,
});

export const mapPhenotypeDTO = (x: PhenotypeDTO): Phenotype => ({
  code: x.phenotype_code,
  percent: x.percent,
  phenotype: x.phenotype,
});

const addDiseaseLinks = (diseaseText: string, diseases: SimpleDiseasesDTO[]): string => {
  const replacer = diseases.reduce((acc, disease) => {
    return {
      ...acc,
      [disease.disease]: `<gdn-link type="diseases" id="${disease.disease_code}">${disease.disease}</gdn-link>`,
    };
  }, {});

  return replaceCaseInsensitive(diseaseText, replacer);
};

export const mapBacteriaDetailsDTO = (
  x: BacteriaDetailsDTO,
  phenotype: PhenotypeDTO[],
  images: ImageDTO[]
): MicrobeDetails => ({
  type: 'bacteria',
  code: x.bacteria_code,
  name: x.bacteria,
  drugSusceptibility: x.drug_susceptibility
    ? x.drug_susceptibility.map(mapDrugSusceptibilityDTO)
    : [],
  vaccines: x.vaccines ? x.vaccines.map(mapVaccineDTO) : [],
  notes: parseDynamicContent(x.notes || ''),
  ecology: parseDynamicContent(addDiseaseLinks(x.ecology, x.diseases)),
  synonyms: x.synonyms,
  phenotype: phenotype.map(mapPhenotypeDTO),
  images: images.map(mapImageDTO),
});

export const mapBacteriaDetailsPublicDTO = (x: BacteriaDetailsPublicDTO): MicrobeDetailsPublic => ({
  code: x.bacteria_code,
  name: x.bacteria,
  notes: parseDynamicContent(x.notes || ''),
  ecology: parseDynamicContent(x.ecology),
});

export const mapMycobacteriaDetailsDTO = (
  x: MycobacteriaDetailsDTO,
  phenotype: PhenotypeDTO[],
  images: ImageDTO[]
): MicrobeDetails => ({
  type: 'mycobacteria',
  code: x.mycobacteria_code,
  name: x.mycobacteria,
  drugSusceptibility: x.drug_susceptibility
    ? x.drug_susceptibility.map(mapDrugSusceptibilityDTO)
    : [],
  vaccines: x.vaccines ? x.vaccines.map(mapVaccineDTO) : [],
  notes: parseDynamicContent(x.notes || ''),
  ecology: parseDynamicContent(x.ecology),
  synonyms: x.synonyms,
  phenotype: phenotype.map(mapPhenotypeDTO),
  images: images.map(mapImageDTO),
});

export const mapMycobacteriaDetailsPublicDTO = (
  x: MycobacteriaDetailsPublicDTO
): MicrobeDetailsPublic => ({
  code: x.mycobacteria_code,
  name: x.mycobacteria,
  notes: parseDynamicContent(x.notes || ''),
  ecology: parseDynamicContent(x.ecology),
});

export const mapYeastDetailsDTO = (
  x: YeastsDetailsDTO,
  phenotype: PhenotypeDTO[],
  images: ImageDTO[]
): MicrobeDetails => ({
  type: 'yeasts',
  code: x.yeast_code,
  name: x.yeast,
  drugSusceptibility: x.drug_susceptibility
    ? x.drug_susceptibility.map(mapDrugSusceptibilityDTO)
    : [],
  vaccines: x.vaccines ? x.vaccines.map(mapVaccineDTO) : [],
  notes: parseDynamicContent(x.notes || ''),
  ecology: parseDynamicContent(x.ecology),
  synonyms: x.synonyms,
  phenotype: phenotype.map(mapPhenotypeDTO),
  images: images.map(mapImageDTO),
});

export const mapYeastDetailsPublicDTO = (x: YeastsDetailsPublicDTO): MicrobeDetailsPublic => ({
  code: x.yeast_code,
  name: x.yeast,
  notes: parseDynamicContent(x.notes || ''),
  ecology: parseDynamicContent(x.ecology),
});

const simpleMicrobeSort = (first: SimpleMicrobe, second: SimpleMicrobe): number => {
  return first.name.toLowerCase().localeCompare(second.name.toLowerCase());
};

export const mapBacteriaDTO = (bacteria: SimpleBacteriaDTO): SimpleMicrobe[] => {
  return bacteria.data
    ? bacteria.data.map(microbe => ({
        id: microbe.bacteria_code,
        name: microbe.bacteria,
        type: 'bacteria',
      }))
    : [];
};

export const mapMycobacteriaDTO = (mycobacteria: SimpleMycobacteriaDTO): SimpleMicrobe[] => {
  return mycobacteria.data
    ? mycobacteria.data.map(mycobacteria => ({
        id: mycobacteria.mycobacteria_code,
        name: mycobacteria.mycobacteria,
        type: 'mycobacteria',
      }))
    : [];
};

export const mapYeastsDTO = (yeasts: SimpleYeastDTO): SimpleMicrobe[] => {
  return yeasts.data
    ? yeasts.data.map(yeast => ({
        id: yeast.yeast_code,
        name: yeast.yeast,
        type: 'yeasts',
      }))
    : [];
};

export const mapVirusDTO = (viruses: SimpleVirusDTO): SimpleMicrobe[] => {
  return viruses.data
    ? viruses.data.map(virus => ({
        id: virus.virus_code,
        name: virus.virus,
        type: 'virus',
      }))
    : [];
};

export const mapAllMicrobeDTO = (
  bacteria: SimpleBacteriaDTO,
  mycobacteria: SimpleMycobacteriaDTO,
  yeasts: SimpleYeastDTO,
  virus: SimpleVirusDTO
): SimpleMicrobe[] => {
  const bacteriaMicrobes: SimpleMicrobe[] = mapBacteriaDTO(bacteria);

  const mycobacteriaMicrobes: SimpleMicrobe[] = mapMycobacteriaDTO(mycobacteria);

  const yeastMicrobes: SimpleMicrobe[] = mapYeastsDTO(yeasts);

  const virusMicrobes: SimpleMicrobe[] = mapVirusDTO(virus);

  return [...bacteriaMicrobes, ...mycobacteriaMicrobes, ...yeastMicrobes, ...virusMicrobes].sort(
    simpleMicrobeSort
  );
};
