import { isNotNull } from 'apiServices/common/guards';
import { ReactComponent as GoToIcon } from 'assets/Map/goto.svg';
import { Icon } from 'Atoms/Icon';
import { Link } from 'Atoms/links/Link';
import { P, Span } from 'Atoms/text';
import { ContentContainer, PopupContainer } from 'Atoms/tooltip/PopupContainer';
import { Popper, PopperContext } from 'Organisms/Popper';
import React, { FC, ReactElement } from 'react';
import { useContextAssert } from 'services/useContextAssert.hook';
import styled from 'styled-components/macro';
import { Outbreak } from 'types/disease';
import { OutbreaksData } from 'types/map';
import { formatNullableNumber } from 'utils/formatNumber';

const PopperStyled = styled(Popper)`
  z-index: 1000;
`;

const IconStyled = styled(Icon)`
  vertical-align: bottom;
`;

const Row = styled.div`
  padding: 0 5px;
`;

const PopupContainerStyled = styled(PopupContainer)<{ maxHeight?: number }>`
  ${ContentContainer} {
    padding: 5px 0;
    ${Row}:nth-child(odd) {
      background: ${props => props.theme.colors.table.row.backgroundHoverColor};
    }

    max-height: ${props => props.maxHeight}px;
  }
`;

const OutbreakItemText = styled(Span)`
  text-decoration: underline;
`;

interface SingleOutbreakProps {
  data: OutbreaksData;
  onClick?: () => void;
}

interface ClusteredOutbreaksProps {
  data: OutbreaksData[];
  onClick?: () => void;
}

interface OutbreakItemProps {
  outbreak: Outbreak;
  onClick?: () => void;
}

const getLocationText = (outbreak: Outbreak): ReactElement | null => {
  if (outbreak.city || outbreak.state) {
    const locations = [outbreak.city, outbreak.state, outbreak.countryName]
      .filter(s => s)
      .filter(isNotNull);

    return locations.length > 0 ? <P>{locations.join(', ')}</P> : null;
  } else {
    return outbreak.region ? (
      <P>
        {outbreak.region}, {outbreak.countryName}
      </P>
    ) : (
      <P>{outbreak.countryName}</P>
    );
  }
};

const OutbreakItem: FC<OutbreakItemProps> = ({ outbreak, onClick }) => (
  <Row>
    <Link
      to={`/explore/diseases/${outbreak.diseaseId}/${outbreak.countryId}`}
      onClick={onClick}
      noUnderline
    >
      <OutbreakItemText>
        <Span weight="600">{outbreak.diseaseName}</Span>
        <Span> ({formatNullableNumber(outbreak.cases)} cases)</Span>
      </OutbreakItemText>
      {getLocationText(outbreak)}
    </Link>
  </Row>
);

const SingleOutbreakPopup: FC<SingleOutbreakProps> = ({ data, onClick }) => (
  <>
    <OutbreakItem outbreak={data.data} onClick={onClick} />
    <Row>
      <Link to={`/explore/countries/${data.countryId}`} onClick={onClick}>
        Go to {data.countryName} record <IconStyled svgComponent={GoToIcon} size="small" />
      </Link>
    </Row>
  </>
);

const ClusteredOutbreaksPopupContent: FC<ClusteredOutbreaksProps> = ({ data, onClick }) => (
  <>
    {data.map((outbreak, i) => (
      <OutbreakItem key={i} outbreak={outbreak.data} onClick={onClick} />
    ))}
  </>
);

interface PopupContentProps {
  data: OutbreaksData | OutbreaksData[];
  year: string;
  onClose: () => void;
  onClick?: () => void;
}

const OutbreaksPopupContent: FC<PopupContentProps> = ({ data, year, onClose, onClick }) => {
  const popperValues = useContextAssert(PopperContext);

  const getTitle = (): string => {
    if (Array.isArray(data)) {
      return `Outbreaks, ${year}`;
    } else if (data.stateName) {
      return `Outbreaks in ${data.stateName}, ${year}`;
    } else {
      return `Outbreaks in ${data.countryName}, ${year}`;
    }
  };

  const getContent = (): ReactElement => {
    if (Array.isArray(data)) {
      return <ClusteredOutbreaksPopupContent data={data} onClick={onClick} />;
    } else {
      return <SingleOutbreakPopup data={data} onClick={onClick} />;
    }
  };

  return (
    <PopupContainerStyled
      onClose={onClose}
      title={getTitle()}
      color="darkPopup"
      //Number is roughly the size of the popup header and borders.
      maxHeight={popperValues.maxHeight - 30}
    >
      {getContent()}
    </PopupContainerStyled>
  );
};

interface Props {
  referenceElement: HTMLElement;
  onClose: () => void;
  outbreaksData: OutbreaksData | OutbreaksData[];
  year: string;
  onClick?: () => void;
  boundary: HTMLElement | null;
}

export const OutbreaksPopup: FC<Props> = ({
  referenceElement,
  onClose,
  outbreaksData,
  year,
  onClick,
  boundary,
}) => (
  <PopperStyled
    referenceElement={referenceElement}
    position="bottom-end"
    offsetDistance={5}
    isOpen={!!referenceElement}
    boundary={boundary}
  >
    <OutbreaksPopupContent data={outbreaksData} year={year} onClose={onClose} onClick={onClick} />
  </PopperStyled>
);
