import { ReactComponent as DownloadSVG } from 'assets/UI/Download.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { H3 } from 'Atoms/text';
import { CopyrightText } from 'Molecules/CopyrightText';
import { CountryNotesGraphTable } from 'Molecules/CountryNotesGraphTable';
import { CountryNotesGraph } from 'Molecules/graph/CountryNotesGraph';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { Dispatch, FC, SetStateAction } from 'react';
import styled from 'styled-components/macro';
import { Chart } from 'types/chart';

import { GraphAndTableButton } from '../buttons/GraphAndTableButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  padding-bottom: 5px;
  justify-content: flex-end;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    justify-content: space-between;
    ${FilledButton} {
      flex-grow: 1;
    }
  }
`;

const Content = styled.div`
  margin-top: 35px;
`;

const ChartWrapper = styled.div`
  width: 100%;
  height: 500px;
  max-height: calc(100vh - 50px);
`;

const StyledIcon = styled(Icon)`
  margin-right: 10px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-right: 5px;
  }
`;

const StyledButton = styled(FilledButton)`
  max-width: 130px;
  padding: 10px;

  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: unset;
    min-width: 117px;
  }
`;

const GraphTitle = styled(H3)`
  margin-bottom: 10px;
`;

const GraphNotes = styled.div`
  margin-top: 10px;
`;

const CopyrightTextStyled = styled(CopyrightText)`
  margin: 20px 0 10px;
  display: block;
`;

interface Props {
  chart: Chart;
  chartRates: Chart;
  isGraphSelected: boolean;
  isMobile: boolean;
  setIsGraphSelected?: Dispatch<SetStateAction<boolean>>;
  onDownload?: () => void;
  showCopyright?: boolean;
}

export const GraphAndTableFrame: FC<Props> = ({
  chart,
  chartRates,
  isGraphSelected,
  isMobile,
  setIsGraphSelected,
  onDownload,
  showCopyright = true,
}) => {
  return (
    <>
      <GraphTitle weight="600">{chart.title}</GraphTitle>
      <Wrapper>
        {setIsGraphSelected && (
          <ButtonsContainer>
            {onDownload && (
              <StyledButton size="small" onClick={onDownload}>
                <StyledIcon svgComponent={DownloadSVG} size="small" />
                Download
              </StyledButton>
            )}
            <GraphAndTableButton
              isGraphSelected={isGraphSelected}
              setIsGraphSelected={setIsGraphSelected}
            />
          </ButtonsContainer>
        )}
        <Content>
          {isGraphSelected ? (
            <ChartWrapper>
              <CountryNotesGraph chart={chart} chartRates={chartRates} isMobile={isMobile} />
            </ChartWrapper>
          ) : (
            <CountryNotesGraphTable chartData={chart} />
          )}
        </Content>
      </Wrapper>
      {chart.notes && (
        <GraphNotes>
          <DynamicHtml content={chart.notes} />
        </GraphNotes>
      )}
      {showCopyright && <CopyrightTextStyled textAlign="center" size="small" />}
    </>
  );
};
