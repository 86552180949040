import { isNotNull } from 'apiServices/common/guards';
import {
  countriesByCode,
  countriesById,
  CountryCode,
  CountryId,
} from 'services/countries/countries';
import { Dictionary } from 'types/common';

export type CountryColor = [CountryCode, string];

export const getCountryColors = (
  endemicColors: Dictionary<string>,
  coutryEndemics: Dictionary<string>
): CountryColor[] => {
  const endemicHoverColors = Object.fromEntries(
    Object.entries(endemicColors).map(([key, color]) => [key, color])
  );

  return Object.entries(coutryEndemics)
    .map(([countryId, endemicLabel]): CountryColor | null => {
      const country = countriesById[countryId as CountryId];
      if (country && country.code) {
        return [country.code, endemicHoverColors[endemicLabel]];
      }
      return null;
    })
    .filter(isNotNull);
};

type MapboxExpression = string | number | MapboxExpression[];

export const mapFillColorFromCountryColors = (countryColors: CountryColor[]): MapboxExpression => [
  'case',
  ['!=', ['get', 'ISO_A2'], ['string', '-99']],
  [
    'match',
    ['get', 'ISO_A2'],
    ...countryColors.reduce<(string | [string])[]>(
      (acc, [countryCode, countryColor]) => [...acc, [countryCode], countryColor],
      []
    ),
    'transparent',
  ],
  [
    'match',
    ['get', 'NE_ID'],
    ...countryColors.reduce<(string | [string | number])[]>((acc, [countryCode, countryColor]) => {
      if (countriesByCode[countryCode]?.naturalEarthUnitIds) {
        countriesByCode[countryCode]?.naturalEarthUnitIds?.forEach((id: number) => {
          acc.push([id]);
          acc.push(countryColor);
        });
      }
      return acc;
    }, []),
    'transparent',
  ],
];
