import { ReactComponent as CountriesSvg } from 'assets/ExploreFocus/Countries.svg';
import { ReactComponent as DiseasesSvg } from 'assets/ExploreFocus/Diseases.svg';
import { ReactComponent as DrugsSvg } from 'assets/ExploreFocus/Drugs.svg';
import { ReactComponent as MicrobesSvg } from 'assets/ExploreFocus/Microbes.svg';
import { ReactComponent as VaccinesSvg } from 'assets/ExploreFocus/Vaccines.svg';
import {
  AZCountries,
  AZDiseases,
  AZDrugs,
  AZMicrobes,
  AZVaccines,
  Props,
} from 'Organisms/az/AZCategories';
import { FC } from 'react';
import { useCountriesGrouped } from 'store/countriesStore/hooks';
import { useDiseasesGrouped } from 'store/diseasesStore/hooks';
import { useDrugsGrouped } from 'store/drugsStore/hooks';
import { useMicrobesGrouped } from 'store/microbesStore/hooks';
import { useVaccinesGrouped } from 'store/vaccinesStore/hooks';
import { AZTabSection } from 'types/tab';
import { formatNumber } from 'utils/formatNumber';

interface Section extends AZTabSection {
  Component: FC<Props>;
}

export const useSections = (): Section[] => {
  const diseases = useDiseasesGrouped();
  const drugs = useDrugsGrouped();
  const vaccines = useVaccinesGrouped();
  const microbes = useMicrobesGrouped();
  const countries = useCountriesGrouped();

  return [
    {
      name: 'Diseases',
      slug: 'diseases',
      Component: AZDiseases,
      count: formatNumber(diseases.total),
      icon: DiseasesSvg,
    },
    {
      name: 'Drugs',
      slug: 'drugs',
      Component: AZDrugs,
      count: formatNumber(drugs.total),
      icon: DrugsSvg,
    },
    {
      name: 'Vaccines',
      slug: 'vaccines',
      Component: AZVaccines,
      count: formatNumber(vaccines.total),
      icon: VaccinesSvg,
    },
    {
      name: 'Microbes',
      slug: 'microbes',
      Component: AZMicrobes,
      count: formatNumber(
        microbes.bacteria.total + microbes.mycobacteria.total + microbes.yeasts.total
      ),
      icon: MicrobesSvg,
    },
    {
      name: 'Countries',
      slug: 'countries',
      Component: AZCountries,
      count: formatNumber(countries.total),
      icon: CountriesSvg,
    },
  ];
};
