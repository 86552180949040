export const searchSelectOptionSortCompare = (
  a: { label: string },
  b: { label: string }
): number => {
  return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
};

export const filterSelectOptionByInput = <T extends { label: string }>(
  options: T[],
  inputValue: string,
  comparator: (left: T, right: T) => number = searchSelectOptionSortCompare
): T[] => {
  const matchingStart: T[] = [];
  const matchingInnerPart: T[] = [];
  const input = inputValue.toLowerCase();

  options.sort(comparator).forEach(option => {
    const label = option.label.toLowerCase();
    if (label.startsWith(input)) {
      matchingStart.push(option);
    } else if (label.includes(input)) {
      matchingInnerPart.push(option);
    }
  });

  return matchingStart.concat(matchingInnerPart);
};
