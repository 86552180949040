import { DiseaseDetailsDTO, RelatedNotesDTO } from 'apiServices/Diseases/DTO';
import { parseDynamicContent } from 'services/parser/dynamicContentParser';
import { CountryNotes } from 'types/countryNotes';

import { CrossbordersDTO, OutbreaksDTO, SurveyDTO } from '../common/DTO';
import { mapReferenceDTO } from '../common/mappers';
import { mapCrossbordersDTO, mapOutbreaksDTO, mapSurveyDTO } from '../common/mappers';
import { CountryNotesDTO } from './DTO';

export const mapWorldwideNotesDTO = (
  x: CountryNotesDTO,
  prevalence: SurveyDTO[],
  seroprevalence: SurveyDTO[],
  crossborders: CrossbordersDTO,
  outbreaks: OutbreaksDTO[],
  diseaseDetails: DiseaseDetailsDTO,
  relatedNotes: RelatedNotesDTO
): CountryNotes => ({
  note: parseDynamicContent(x.note || '', {
    relatedDiseases: diseaseDetails.related_diseases,
  }),
  references: x.references.map(mapReferenceDTO),
  prevalence: prevalence.map(x => mapSurveyDTO(x)),
  seroprevalence: seroprevalence.map(x => mapSurveyDTO(x)),
  crossborders: crossborders.events.map(x => mapCrossbordersDTO(x)),
  outbreaks: outbreaks.map(x => mapOutbreaksDTO(x)),
  relatedCountries: relatedNotes.related_countries.map(x => ({
    id: x.country_code,
    name: x.country,
  })),
  relatedDiseases: relatedNotes.related_diseases.map(x => ({
    id: x.disease_code,
    name: x.disease,
  })),
});
