import { ReactComponent as SearchIcon } from 'assets/Navbar/Search.svg';
import { Icon } from 'Atoms/Icon';
import { Input } from 'Atoms/input/Input';
import { P } from 'Atoms/text';
import { ColumnHeader } from 'Diagnose/types/diagnosisTable';
import { debounce, uniqBy } from 'lodash';
import { BreadcrumbSelect } from 'Molecules/select/BreadcrumbSelect';
import { SearchSelect } from 'Molecules/select/SearchSelect';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OnChangeValue } from 'react-select';
import { animated, useSpring } from 'react-spring';
import { CellMeasurerCache } from 'react-virtualized';
import { usePushState } from 'services/usePushState.hook';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';
import { LatestDataTable } from 'Updates/Organisms/LatestDataTable';
import { useUpdatedFields } from 'Updates/store/updatesStore/hooks';
import { RangeSelectOption, RangeValue } from 'Updates/types/rangeType';
import { formatNumber } from 'utils/formatNumber';

const WindowResizeDebounce = 300;
const SearchResultDebounce = 300;

const Container = styled.div`
  border: 1px solid ${props => props.theme.colors.separator.light};
  border-radius: 10px;

  padding: 20px 30px;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 20px 5px;
  }
`;

const UpdatesMadeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 0 3px;
`;

const StyledTable = styled(LatestDataTable)`
  margin-top: 30px;
`;

const NumberSpan = styled(animated.span)`
  font-weight: 600;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 3px;
`;

const StyledSelect = styled(BreadcrumbSelect)`
  max-width: 155px;
  margin-left: 10px;
`;

const InputContainer = styled.div`
  border: 1px solid ${props => props.theme.colors.text.inactive};
  border-radius: 10px;

  padding: 8px;

  display: flex;
  width: 400px;
  height: 44px;

  margin: 0 0 0 10px;

  align-items: center;

  &:focus-within {
    outline: 2px solid ${props => props.theme.colors.focus};
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
    margin: 10px 0 0 0;
  }
`;

const InputStyled = styled(Input)`
  border: none;
  border-radius: 10px;

  height: 100%;
  width: 100%;
  margin: 0 0 0 10px;

  &:focus {
    outline: none;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 20px 0 0 0;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex-wrap: wrap;
  }
`;

const FilterSelect = styled(SearchSelect)`
  width: 250px;
  height: 100%;

  .${props => props.classNamePrefix}__control {
    padding: 4px;

    &--menu-is-open {
      border-width: 1px 1px 0 1px;
    }

    border-width: 1px;
  }

  .${props => props.classNamePrefix}__menu {
    border-width: 0px 1px 1px 1px;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;

const rangeSelectOptions: RangeSelectOption[] = [
  { value: '48-hours', label: 'Last 48 hours' },
  { value: '7-days', label: 'Last 7 days' },
];

const tableColumns: ColumnHeader[] = [
  { label: 'Date', isSortable: true },
  { label: 'Category', isSortable: true },
  { label: 'Updated/added item', isSortable: true },
];

interface Props {
  className?: string;
  path: string;
}

interface Params {
  value: RangeValue;
}

const AllOption = {
  value: 'all',
  label: 'All',
};

export const LatestDataSection: FC<Props> = ({ className, path }) => {
  const { push } = usePushState();
  const { value } = useParams<Params>();
  const updates = useUpdatedFields(value);

  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [category, setCategory] = useState<SelectOption>(AllOption);

  const [, enforceRender] = useState<unknown>();
  const [cache] = useState(
    () =>
      new CellMeasurerCache({
        fixedWidth: true,
      })
  );

  const clearCache = useRef(
    debounce(() => {
      cache.clearAll();
      enforceRender({});
      setIsLoadingPage(false);
    }, WindowResizeDebounce)
  ).current;
  addEventListener('resize', clearCache);

  const [props, set] = useSpring(() => ({ number: 0, updatesCount: 0 }));

  useEffect(() => {
    if (isLoading(updates) || !updates) {
      return;
    }

    set({ number: updates.total, updatesCount: updates.updateCount });
  }, [set, updates]);

  const onRangeSelectChange = (selected: OnChangeValue<SelectOption, false>): void => {
    if (selected) {
      push(`${path}/${selected.value}`);
    }
  };

  const setSearchValueDebounce = debounce((value: string) => {
    setSearchValue(value);
    clearCache();
  }, SearchResultDebounce);

  const actualFields = useMemo(() => {
    if (isLoading(updates) || !updates || isLoadingPage) {
      return 'Loading';
    } else {
      return updates.updates.filter(
        f =>
          (category.value === 'all' || f.category === category.value) &&
          `${f.name} in ${f.countries?.map(c => c.name).join(', ')}`
            .toLowerCase()
            .includes(searchValue.toLowerCase())
      );
    }
  }, [category.value, isLoadingPage, searchValue, updates]);

  const onChangeFilter = (option: OnChangeValue<SelectOption, false>): void => {
    setIsLoadingPage(true);
    if (option) {
      setCategory(option);
      clearCache();
    }
  };

  const filterSelectOptions = useMemo(() => {
    if (isLoading(updates) || !updates) {
      return [];
    }

    return [
      AllOption,
      ...uniqBy(
        updates.updates.map(field => ({ value: field.category, label: field.category })),
        f => f.value
      ),
    ];
  }, [updates]);

  return (
    <Container className={className}>
      <Wrapper>
        <P weight="600">Select range</P>
        <StyledSelect
          classNamePrefix="breadcrumb-select"
          value={value}
          options={rangeSelectOptions}
          onChange={onRangeSelectChange}
        />
      </Wrapper>
      <UpdatesMadeWrapper>
        <NumberSpan>
          {props.number.to(number => (number === 0 ? null : formatNumber(Math.floor(number))))}
        </NumberSpan>
        &nbsp;
        <animated.span>{props.number.to(num => (num === 0 ? null : `updates made`))}</animated.span>
      </UpdatesMadeWrapper>
      <UpdatesMadeWrapper>
        <NumberSpan>
          {props.updatesCount.to(number =>
            number === 0 ? null : formatNumber(Math.floor(number))
          )}
        </NumberSpan>
        &nbsp;
        <animated.span>
          {props.updatesCount.to(num => (num === 0 ? null : `TOTAL UPDATES!!`))}
        </animated.span>
      </UpdatesMadeWrapper>
      <SearchContainer>
        <FilterSelect
          classNamePrefix="filter-search"
          placeholder="Select category"
          options={filterSelectOptions}
          value={category}
          onChange={onChangeFilter}
        />
        <InputContainer>
          <Icon svgComponent={SearchIcon} fill="inactive" size="small" />
          <InputStyled
            onChange={e => {
              setIsLoadingPage(true);
              setSearchValueDebounce(e.target.value);
            }}
            type="text"
            size="normal"
            placeholder="Find updates by item"
            placeholderColor="inactive"
          />
        </InputContainer>
      </SearchContainer>
      <StyledTable
        columns={tableColumns}
        data={actualFields}
        cache={cache}
        clearCache={clearCache}
      />
    </Container>
  );
};
