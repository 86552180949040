import { RelatedNotesDTO } from 'apiServices/Diseases/DTO';
import { parseDynamicContent } from 'services/parser/dynamicContentParser';
import { RegionVaccineSchedule } from 'types/countryDetails';
import { CountryNotes, CountryNotesPublic } from 'types/countryNotes';

import { CrossbordersDTO, OutbreaksDTO, SurveyDTO } from '../common/DTO';
import {
  mapCrossbordersDTO,
  mapOutbreaksDTO,
  mapReferenceDTO,
  mapSurveyDTO,
} from '../common/mappers';
import { CountryNotesDTO, CountryNotesPublicDTO, VaccineScheduleDTO } from './DTO';

export const mapVaccineScheduleDTO = (
  v: VaccineScheduleDTO[]
): RegionVaccineSchedule | undefined => {
  if (v.length === 0) {
    return undefined;
  }

  return {
    countryCode: v.length > 0 ? v[0].country_code : '',
    vaccines: v.map(x => ({ vaccine: x.vaccine, schedule: x.schedule })),
  };
};

export const mapCountryNotesDTO = (
  x: CountryNotesDTO,
  prevalence: SurveyDTO[],
  seroprevalence: SurveyDTO[],
  crossborders: CrossbordersDTO,
  outbreaks: OutbreaksDTO[],
  vaccineSchedule: VaccineScheduleDTO[],
  relatedNotes: RelatedNotesDTO
): CountryNotes => ({
  note: parseDynamicContent(x.note || ''),
  references: x.references.map(mapReferenceDTO),
  prevalence: prevalence.map(x => mapSurveyDTO(x)),
  seroprevalence: seroprevalence.map(x => mapSurveyDTO(x)),
  crossborders: crossborders.events.map(x => mapCrossbordersDTO(x)),
  outbreaks: outbreaks.map(x => mapOutbreaksDTO(x)),
  vaccineSchedule: mapVaccineScheduleDTO(vaccineSchedule),
  relatedCountries: relatedNotes.related_countries.map(x => ({
    id: x.country_code,
    name: x.country,
  })),
  relatedDiseases: relatedNotes.related_diseases.map(x => ({
    id: x.disease_code,
    name: x.disease,
  })),
});

export const mapCountryNotesPublicDTO = (x: CountryNotesPublicDTO): CountryNotesPublic => ({
  note: parseDynamicContent(x.note),
});
