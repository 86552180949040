import { Span } from 'Atoms/text';
import { Props as MapControlProps } from 'Organisms/outbreaksMap/MapControls';
import React, { FC, ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { LoginModalContext } from 'services/loginModal.service';
import { useContextAssert } from 'services/useContextAssert.hook';
import { useFullscreen } from 'services/useFullscreen.hook';
import { usePushState } from 'services/usePushState.hook';
import { useOutbreaksPublic } from 'store/outbreaksStore/hooks';
import { isStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { MarkerClickHandler, OutbreaksData, Viewport } from 'types/map';
import { generateArrayRangePublic } from 'utils/generateArray';

import { Map } from './Map';
import { getMarkersFromOutbreaks } from './mapOutbreaks';
import { OutbreaksPopup } from './OutbreaksPopup';

const containerId = `countries-map-container`;

const thisYear = new Date().getFullYear();

const Container = styled.div`
  position: relative;

  &[data-fullscreen='true'] {
    width: 100%;
    height: 100%;
  }
`;

const Title = styled(Span)`
  display: flex;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-right: 10px;
  }
`;

interface Props {
  className?: string;
  renderMapControls: (props: MapControlProps) => ReactElement;
  showDownloadButton?: boolean;
}

const MapBase: FC<Props> = ({ className = '', renderMapControls }) => {
  const [outbreakYear, setOutbreakYear] = useState(thisYear.toString());
  const [selectedMarker, setSelectedMarker] = useState<{
    ref: HTMLElement;
    data: OutbreaksData | OutbreaksData[];
  } | null>(null);
  const [viewport, setViewport] = useState<Viewport>({
    zoom: 2,
    longitude: 0,
    latitude: 40,
    width: 1,
    height: 1,
  });

  const { setOpen } = useContextAssert(LoginModalContext);

  const outbreaks = useOutbreaksPublic(outbreakYear);

  const fullscreenRef = useRef<HTMLDivElement>(null);
  const { isFullscreenSupported, isFullscreenActive, toggleFullscreen } = useFullscreen(
    fullscreenRef
  );

  const markerClickHandler: MarkerClickHandler<OutbreaksData> = useCallback(
    (e, data) => {
      e.stopPropagation();
      setSelectedMarker({ ref: e.currentTarget, data });
    },
    [setSelectedMarker]
  );

  const onClick = (): void => {
    setOpen(true);
  };

  const { push } = usePushState();
  const onCountryClick = useCallback(
    (countryId: string) => push(`/explore/countries/${countryId}`),
    [push]
  );

  const markers = useMemo((): OutbreaksData[] => {
    if (isStoreError(outbreaks) || isLoading(outbreaks)) {
      return [];
    }

    const markers = getMarkersFromOutbreaks(outbreaks);

    return [...markers];
  }, [outbreaks]);

  const mapControlProps: MapControlProps = {
    yearSelectorOptions: generateArrayRangePublic(1900, thisYear),
    title: <Title>Global outbreaks</Title>,
    fromYearRange: {
      value: outbreakYear,
      onChange: v => v && setOutbreakYear(v.value),
    },
    isFullscreenAPISupported: isFullscreenSupported,
    isFullscreenAPIActive: isFullscreenActive,
    toggleFullscreen: toggleFullscreen,
  };

  return (
    <Container
      id={containerId}
      className={className}
      ref={fullscreenRef}
      data-fullscreen={isFullscreenActive}
    >
      {renderMapControls(mapControlProps)}
      <Map
        markers={markers}
        markerClickHandler={markerClickHandler}
        onCountryClick={onCountryClick}
        viewport={viewport}
        setViewport={setViewport}
      />
      {selectedMarker?.data && selectedMarker?.ref && (
        <OutbreaksPopup
          referenceElement={selectedMarker.ref}
          outbreaksData={selectedMarker.data}
          onClose={() => setSelectedMarker(null)}
          year={outbreakYear}
          onClick={onClick}
          boundary={fullscreenRef.current}
        />
      )}
    </Container>
  );
};

export const CountriesMap = styled(MapBase)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;
