import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { RefObject, useRef } from 'react';

interface ElementCapture {
  ref: RefObject<HTMLDivElement>;
  capture: () => Promise<void>;
}

export const useElementCapture = (imageName = 'GIDEON image'): ElementCapture => {
  const ref = useRef<HTMLDivElement>(null);

  const capture = (): Promise<void> =>
    new Promise((resolve, reject) => {
      const el = ref.current;

      if (!el) {
        return reject();
      }

      html2canvas(el, { scrollY: 0 })
        .then(canvas =>
          canvas.toBlob(blob => {
            if (blob) {
              saveAs(blob, `${imageName}.png`);
              resolve();
            } else {
              reject();
            }
          }, 'image/png')
        )
        .catch(() => reject());
    });

  return {
    ref,
    capture,
  };
};
