import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { SignUpHome } from './Home';
import { SignUpSuccess } from './Success';
import { SignUpVerify } from './Verify';

export const SignUp: FC = () => (
  <Switch>
    <Route exact path="/signup" component={SignUpHome} />
    <Route exact path="/signup/verify" component={SignUpVerify} />
    <Route exact path="/signup/success" component={SignUpSuccess} />
  </Switch>
);
