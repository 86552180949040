import { DiseaseDetailsDTO, RelatedNotesDTO } from 'apiServices/Diseases/DTO';
import axios from 'axios.cached';
import { CountryNotes, CountryNotesPublic } from 'types/countryNotes';

import { CrossbordersDTO, DataDTO, OutbreaksDTO, SurveyDTO } from '../common/DTO';
import { mapCountryNotesDTO, mapCountryNotesPublicDTO } from './countryNotes.mapper';
import { CountryNotesDTO, CountryNotesPublicDTO, VaccineScheduleDTO } from './DTO';
import { mapWorldwideNotesDTO } from './worldwideNotes.mapper';

export const getCountryNotes = async (
  diseaseId: number,
  countryId: string
): Promise<CountryNotes> => {
  const [
    countryNotes,
    prevalence,
    seroprevalence,
    crossborders,
    outbreaks,
    vaccineSchedule,
    diseaseDetails,
    relatedNotes,
  ] = await Promise.all([
    axios.get<DataDTO<CountryNotesDTO>>(`/api/diseases/${diseaseId}/distribution/${countryId}`),
    axios.get<DataDTO<SurveyDTO[]>>(
      `/api/diseases/${diseaseId}/distribution/${countryId}/surveys/prevalence`
    ),
    axios.get<DataDTO<SurveyDTO[]>>(
      `/api/diseases/${diseaseId}/distribution/${countryId}/surveys/seroprevalence`
    ),
    axios.get<DataDTO<CrossbordersDTO>>(
      `/api/diseases/${diseaseId}/distribution/${countryId}/crossborders`
    ),
    axios.get<DataDTO<OutbreaksDTO[]>>(
      `/api/diseases/${diseaseId}/distribution/${countryId}/outbreaks`
    ),
    axios.get<DataDTO<VaccineScheduleDTO[]>>(
      `/api/travel/countries/${countryId}/diseases/${diseaseId}/vaccine-schedule`
    ),
    axios.get<DataDTO<DiseaseDetailsDTO>>(`/api/diseases/${diseaseId}/general`),
    axios.get<DataDTO<RelatedNotesDTO>>(
      `/api/diseases/${diseaseId}/distribution/${countryId}/related_notes`
    ),
  ]);

  return countryId === 'G100'
    ? mapWorldwideNotesDTO(
        countryNotes.data.data,
        prevalence.data.data,
        seroprevalence.data.data,
        crossborders.data.data,
        outbreaks.data.data,
        diseaseDetails.data.data,
        relatedNotes.data.data
      )
    : mapCountryNotesDTO(
        countryNotes.data.data,
        prevalence.data.data,
        seroprevalence.data.data,
        crossborders.data.data,
        outbreaks.data.data,
        vaccineSchedule.data.data,
        relatedNotes.data.data
      );
};

export const getCountryNotesPublic = async (
  diseaseId: number,
  countryId: string
): Promise<CountryNotesPublic> => {
  const countryNotes = await axios.get<DataDTO<CountryNotesPublicDTO>>(
    `/api/public/diseases/${diseaseId}/distribution/${countryId}`
  );

  return mapCountryNotesPublicDTO(countryNotes.data.data);
};
