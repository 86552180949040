import { ReactComponent as GraphSVG } from 'assets/Button/Graph.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { Link } from 'Atoms/links/Link';
import { P } from 'Atoms/text';
import { RecordTitle } from 'Molecules/record/RecordTitle';
import React, { useContext, useState } from 'react';
import { ThemeModeContext } from 'services/theme';
import { usePushState } from 'services/usePushState.hook';
import styled from 'styled-components/macro';
import { getFlagPath } from 'utils/getFlagPath';

const Flag = styled.img`
  width: 50px;
  height: 42px;
`;

const StyledButton = styled(FilledButton)`
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
  & > span {
    display: flex;
    flex-direction: column;
  }

  margin-left: 10px;
`;

const StyledIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  margin-bottom: 4px;
`;

interface Props {
  className?: string;
  country: string;
  countryId: string;
  hideStatsButton?: boolean;
}

export const CountryPageTitle: React.FC<Props> = ({
  className,
  country,
  countryId,
  hideStatsButton,
}) => {
  const [hasFlag, setHasFlag] = useState(true);
  const { push } = usePushState();
  const { isDarkMode } = useContext(ThemeModeContext);

  const noteForUK =
    countryId === 'G291' ? (
      <>
        <P>
          In many cases, data published for the United Kingdom include reports from England and
          Wales only.
        </P>
        <P>
          As such, <Link to="/explore/countries/G357">Northern Ireland</Link> and{' '}
          <Link to="/explore/countries/G327">Scotland</Link> are listed as additional countries.
        </P>
      </>
    ) : undefined;

  const flagPath = getFlagPath(country, countryId);

  return (
    <RecordTitle
      className={className}
      buttons={
        <>
          {hasFlag && (
            <Flag
              src={isDarkMode ? flagPath.dark : flagPath.light}
              alt={`${country} Flag`}
              onError={() => setHasFlag(false)}
            />
          )}
          {countryId && !hideStatsButton && (
            <StyledButton
              padding="none"
              size="micro"
              onClick={() => push(`/quantify?country=${countryId}`)}
            >
              <StyledIcon svgComponent={GraphSVG} />
              Stats
            </StyledButton>
          )}
        </>
      }
      title={country}
      note={noteForUK}
    />
  );
};
