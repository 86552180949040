import { Icon } from 'Atoms/Icon';
import { H2, P } from 'Atoms/text';
import { DownloadButton } from 'Molecules/buttons/DownloadButton';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

const Header = styled.div`
  position: sticky;
  top: 0;
  min-height: 65px;
  background-color: ${props => props.theme.colors.background.modalCardHeader};
  padding: 26px 70px 26px 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 6px 5px 0px rgba(0, 0, 0, 0.3);
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const TitleStyled = styled(H2)`
  text-align: left;
  margin: 0;
`;

const SubTitleStyled = styled(P)`
  margin: 12px 0 0 0;
`;

const StyledDownloadButton = styled(DownloadButton)`
  height: 40px;
  max-width: 40px;
  padding: 0;
  margin: 0 10px 0 15px;
  ${Icon} {
    margin: 0;
  }
`;

interface ModalCardHeaderProps {
  className?: string;
  title: string;
  subTitle?: ReactNode;
  onDownload?: () => void;
}

export const ModalCardHeader: FC<ModalCardHeaderProps> = ({
  className = '',
  title,
  subTitle,
  onDownload,
}) => (
  <Header className={className}>
    <TitleWrapper>
      <TitleStyled
        font="Inter"
        color="modalCardTitle"
        weight="700"
        lineHeight="referenceTitle"
        size="regular"
      >
        {title}
      </TitleStyled>
      {onDownload && process.env.REACT_APP_FEATURE_OUTBREAKS_PDF === 'enabled' && (
        <StyledDownloadButton label="" onClick={onDownload} />
      )}
    </TitleWrapper>
    {subTitle && (
      <SubTitleStyled color="modalCardTitle" weight="700">
        {subTitle}
      </SubTitleStyled>
    )}
  </Header>
);
