import { getPDFUrl } from 'apiServices/Pdf/pdf';
import { sendPDF } from 'apiServices/Pdf/pdfMail';
import { ReactComponent as ReferencesIcon } from 'assets/Button/References.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { DownloadButton } from 'Molecules/buttons/DownloadButton';
import { SquareButtonStyles, SquareDownloadButton, SquareMailButton } from 'Molecules/PdfButtons';
import { MailPDFButtonForm } from 'Organisms/mailPdf/MailPDFButtonForm';
import React, { FC, useCallback } from 'react';
import { useOpenAllReferences } from 'services/referenceProvider/hooks';
import { useDownloadPDF } from 'services/useDownloadPDF';
import { useEmailPDF } from 'services/useEmailPDF';
import { useMobile } from 'services/useMobile';
import styled from 'styled-components/macro';
import { Category } from 'types/pdf';

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledButton = styled(FilledButton)`
  margin: 10px 20px 10px 0;
  max-width: 230px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    ${SquareButtonStyles};
  }
`;

const StyledIcon = styled(Icon)`
  margin: 0 15px 0 0;
`;

interface Props {
  className?: string;
  category: Category;
  id: string | number;
  labelTitle: string;
}

export const DownloadsSection: FC<Props> = ({ className, category, id, labelTitle }) => {
  const isMobile = useMobile('m');
  const { openAllReferences, referenceCount } = useOpenAllReferences();
  const { email, ...restEmailProps } = useEmailPDF(() => sendPDF(category, id, email));

  const getPDF = useCallback(() => getPDFUrl(category, id), [category, id]);
  const { onClick, label } = useDownloadPDF(getPDF);

  const ariaLabel = `${labelTitle} PDF file download`;

  if (isMobile) {
    return (
      <Section className={className}>
        <SquareDownloadButton
          disabled={!category && !id}
          onClick={onClick}
          label="Download"
          aria-label={ariaLabel}
        />
        <SquareMailButton email={email} {...restEmailProps} />
        {referenceCount > 0 && (
          <StyledButton onClick={openAllReferences}>
            <StyledIcon svgComponent={ReferencesIcon} size="small" />
            References
          </StyledButton>
        )}
      </Section>
    );
  }

  return (
    <Section className={className}>
      <DownloadButton
        disabled={!category && !id}
        onClick={onClick}
        label={label}
        aria-label={ariaLabel}
      />
      <MailPDFButtonForm email={email} {...restEmailProps} />
      {referenceCount > 0 && (
        <StyledButton onClick={openAllReferences}>
          <StyledIcon svgComponent={ReferencesIcon} size="small" />
          View references
        </StyledButton>
      )}
    </Section>
  );
};
