import { Loader } from 'Atoms/Loader';
import { SurveyTable } from 'Organisms/SurveyTable';
import React, { FC } from 'react';
import { useCountryPrevalenceSurveys } from 'store/countryNotesStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';

interface Props {
  diseaseId: number;
  countryId: string;
  title?: string;
}

const footnote1 = '* indicates publication year (not necessarily year of survey)';

export const PrevalenceSurveys: FC<Props> = ({ diseaseId, countryId, title }) => {
  const outbreaksData = useCountryPrevalenceSurveys(diseaseId, countryId);
  assertIsNotStoreError(outbreaksData);
  if (isLoading(outbreaksData)) {
    return <Loader />;
  }

  return <SurveyTable surveyData={outbreaksData} footnote1={footnote1} title={title} />;
};
