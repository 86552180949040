import { parseDynamicContent } from 'services/parser/dynamicContentParser';
import {
  CountryDetails,
  CountryDetailsPublic,
  RegionVaccineSchedule,
  VaccinationChartEntity,
} from 'types/countryDetails';
import { replace } from 'utils/replacer';

import {
  CountryDetailsDTO,
  CountryDetailsPublicDTO,
  ProphylaxisDrugDTO,
  RegionVaccineScheduleDTO,
  VacinationChartEntity as VaccinationChartEntityDTO,
} from './DTO';

export const mapChartDTO = (v: VaccinationChartEntityDTO): VaccinationChartEntity => ({
  diseaseCode: v.disease_code,
  disease: v.disease,
  countryCode: v.country_code,
  country: v.country,
  title: v.title || '',
  xTitle: v.x_title || '',
  yTitle: v.y_title || '',
  coordinates: v.coordinates.map(c => ({ x: c.x_axis, y: c.y_axis })),
});

export const mapRegionVaccineScheduleDTO = (
  region: RegionVaccineScheduleDTO
): RegionVaccineSchedule => ({
  countryCode: region.country_code,
  country: region.country,
  region: region.region || region.country,
  vaccines: region.vaccines,
});

const addDrugLinks = (text: string, drugs: ProphylaxisDrugDTO[]): string => {
  const replacer = drugs.reduce(
    (acc, drug) => ({
      ...acc,
      [drug.drug]: `<gdn-link type="drugs" id="${drug.drug_code}">${drug.drug}</gdn-link>`,
    }),
    {}
  );

  return replace(text, replacer);
};

export const mapCountryDetailsDTO = (countryDetails: CountryDetailsDTO): CountryDetails => ({
  yellowFeverRequirements: parseDynamicContent(countryDetails.yellowFeverRequirements),
  cdcRecommendation: parseDynamicContent(countryDetails.cdcRecommendation),
  malariaGuideline: parseDynamicContent(countryDetails.malariaGuideline),
  chloroquineResistance: parseDynamicContent(countryDetails.choloroquineResistance),
  prophylaxisRecommendation: parseDynamicContent(
    addDrugLinks(countryDetails.prophylaxisRecommendation, countryDetails.prophylaxisDrugs)
  ),
  vaccineSchedule: countryDetails.vaccineSchedule.map(mapRegionVaccineScheduleDTO),
  vaccinationChart: countryDetails.vaccinationChart.map(mapChartDTO),
});

export const mapCountryDetailsPublicDTO = (
  countryDetails: CountryDetailsPublicDTO
): CountryDetailsPublic => ({
  yellowFeverRequirements: parseDynamicContent(countryDetails.yellow_fever_country_requirements),
  cdcRecommendation: parseDynamicContent(countryDetails.yellow_fever_cdc_recommendations),
  malariaGuideline: parseDynamicContent(countryDetails.malaria_areas_of_risk),
});
