import { HorizontalScrollbarShadows } from 'Organisms/HorizontalScrollbarShadows';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

export { TD } from './TD';
export { TH } from './TH';
export { TR } from './TR';

const StyledTable = styled.table`
  border-collapse: collapse;
  max-width: 100%;
  margin-bottom: 1px;
`;

interface Props {
  className?: string;
  headings: ReactNode;
  rows: ReactNode;
}

export const Table: FC<Props> = ({ headings, rows, className }) => (
  <HorizontalScrollbarShadows className={className}>
    <StyledTable>
      <thead>{headings}</thead>
      <tbody>{rows}</tbody>
    </StyledTable>
  </HorizontalScrollbarShadows>
);
