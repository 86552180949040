import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Link } from 'Atoms/links/Link';
import { Loader } from 'Atoms/Loader';
import { RedirectWithState } from 'Atoms/routes/RedirectWithState';
import { accountSignUpVerify } from 'Auth/apiServices/account';
import { submitRecognitionResponse } from 'Auth/apiServices/survey';
import { FormInput } from 'Auth/Molecules/FormInput';
import { FormMessage } from 'Auth/Molecules/FormMessage';
import { SocialProof } from 'Auth/Molecules/SocialProof';
import { ContentContainer, Row, Title, TrialLayout } from 'Auth/Molecules/Styles';
import React, { FC, FormEvent, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { usePushState } from 'services/usePushState.hook';
import styled from 'styled-components/macro';

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const ContentContainerStyled = styled(ContentContainer)`
  margin: 50px 0 0 0;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  margin: 20px 0;
`;

const FormMessageStyled = styled(FormMessage)`
  max-width: 450px;
`;

const SpecialButton = styled(FilledButton)`
  margin-top: 20px;
  height: 44px;
`;

interface Params {
  id: string;
  hash: string;
}

export const SignUpVerify: FC = () => {
  const [recognition, setRecognition] = useState('');
  const [isLinkLoading, setIsLinkLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [formError, setIsFormError] = useState<string | null>(null);
  const { push } = usePushState();

  const location = useLocation();

  const values: Params | null = useMemo(() => {
    const params = new URLSearchParams(location.search);

    const id = params.get('id');
    const hash = params.get('hash');

    return id && hash
      ? {
          id: id,
          hash: hash,
        }
      : null;
  }, [location.search]);

  useEffect(() => {
    if (!values) {
      return;
    }

    accountSignUpVerify(values.id, values.hash)
      .then(() => {
        setIsLinkLoading(false);
      })
      .catch(() => {
        setIsFormError('Link is expired');
        setIsLinkLoading(false);
      });
  }, [values]);

  const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (!values || isLoading) {
      return;
    }

    setIsLoading(true);
    submitRecognitionResponse(values.id, values.hash, recognition).finally(() => {
      setIsLoading(false);
      push('/login');
    });
  };

  if (!values) {
    return <RedirectWithState to="/404" />;
  }

  return (
    <TrialLayout>
      <Helmet>
        <title>Trial - GIDEON</title>
      </Helmet>
      <Title color="lightLink" weight="600" size="big" font="Inter">
        Activate your account
      </Title>
      {isLinkLoading ? (
        <Loader />
      ) : (
        <>
          <MessageContainer>
            <FormMessageStyled type="error" message={formError} />
            {!formError && (
              <FormMessageStyled
                type="success"
                message={
                  <>
                    Your account is now active! <Link to="/login">Sign In to GIDEON.</Link>
                  </>
                }
              />
            )}
          </MessageContainer>
          {!formError && (
            <ContentContainerStyled>
              <Form onSubmit={onSubmit}>
                <Row>
                  <FormInput
                    id="signup-verify-recognition"
                    label="Let us know how did you hear about GIDEON?"
                    value={recognition}
                    onChange={setRecognition}
                  />
                </Row>
                <SpecialButton color="special">{isLoading ? <Loader /> : 'Submit'}</SpecialButton>
              </Form>
              <SocialProof />
            </ContentContainerStyled>
          )}
        </>
      )}
    </TrialLayout>
  );
};
