import { Button } from 'Atoms/buttons/Button';
import { contentClassNames, Modal } from 'Atoms/Modal';
import { ErrorBoundary } from 'errorBoundary';
import { ReferenceCloseButton } from 'Molecules/buttons/ReferenceCloseButton';
import { ContentError } from 'Molecules/ContentError';
import { ModalCard } from 'Molecules/modalCard/ModalCard';
import React, { FC, ReactElement, useState } from 'react';
import { useReferenceClose } from 'services/referenceProvider/hooks';
import { useState as useReferenceListState } from 'store/ReferenceListStore/hooks';
import { useReferenceData } from 'store/referencesStore/hooks';
import { Resource } from 'store/types';
import styled from 'styled-components/macro';
import { ReferenceDetails } from 'types/reference';

import { ReferenceCard } from './ReferenceCard';

const StyledModal = styled(Modal)`
  position: relative;

  .${contentClassNames.base} {
    max-height: 100%;
  }
`;

const StyledButton = styled(Button)`
  margin: 15px 0;
  background: ${props => props.theme.colors.background.primary};
  &:hover {
    background: ${props => props.theme.colors.background.primary};
  }
`;

const UnorderedList = styled.ol`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

interface ReferenceCardWrapperProps {
  data: { index: number; reference: Resource<ReferenceDetails> };
}

const ReferenceCardWrapper = ({ data }: ReferenceCardWrapperProps): ReactElement => (
  <ErrorBoundary
    error={props => (
      <ModalCard title="Reference">
        <ContentError title="ReferenceError" {...props} />
      </ModalCard>
    )}
  >
    <ReferenceCard reference={data.reference} index={data.index} />
  </ErrorBoundary>
);

interface ReferenceCardsProps {
  data: { index: number; reference: Resource<ReferenceDetails> }[];
}

const ReferenceCards = ({ data }: ReferenceCardsProps): ReactElement => (
  <UnorderedList>
    {data.map(x => (
      <li key={x.index}>
        <ReferenceCardWrapper data={x} />
      </li>
    ))}
  </UnorderedList>
);

type Reference = { id: string; index: number };

export const ReferenceModalProvider: FC = ({ children }) => {
  const referenceChunkSize = 20;
  const state = useReferenceListState();
  const { closeReferences } = useReferenceClose();
  const [loadCount, setLoadCount] = useState<number>(referenceChunkSize);

  const customList: Reference[] = state.list.map((x, i) => ({ id: x, index: i + 1 }));

  const referenceList =
    state.referenceModalIds === 'all'
      ? customList
      : [
          {
            id: state.referenceModalIds,
            index: state.index[state.referenceModalIds],
          },
        ];

  const slicedReferenceList = referenceList.slice(0, loadCount);

  const idsToFetch = slicedReferenceList.map(x => x.id);

  const references = useReferenceData(state.showReferenceModal ? idsToFetch : []);

  const mappedReferences = references.map((reference, i) => ({
    index: state.index[idsToFetch[i]],
    reference,
  }));

  return (
    <>
      <StyledModal isOpen={state.showReferenceModal} onClose={closeReferences}>
        <ReferenceCloseButton onClick={closeReferences} />
        {mappedReferences.length === 1 ? (
          <ReferenceCardWrapper data={mappedReferences[0]} />
        ) : (
          <ReferenceCards data={mappedReferences} />
        )}
        {referenceList.length > loadCount && (
          <StyledButton onClick={() => setLoadCount(loadCount + referenceChunkSize)} weight="600">
            Load more
          </StyledButton>
        )}
      </StyledModal>
      {children}
    </>
  );
};
