import { ReactComponent as ScientistSVG } from 'assets/UI/Scientist.svg';
import { Icon } from 'Atoms/Icon';
import { RedirectWithState } from 'Atoms/routes/RedirectWithState';
import { Span } from 'Atoms/text';
import { ContentContainer, Title, TrialLayout } from 'Auth/Molecules/Styles';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

const IconStyled = styled(Icon)`
  width: 65px;
  height: 65px;

  margin: 40px 0 0 0;
`;

const Text = styled(Span)`
  margin: 40px 0 0 0;

  text-align: center;
`;

interface State {
  email: string;
}

export const SignUpSuccess: FC = () => {
  const location = useLocation<State>();

  if (!location?.state?.email) {
    return <RedirectWithState to="/404" />;
  }

  return (
    <TrialLayout>
      <Helmet>
        <title>Trial - GIDEON</title>
      </Helmet>
      <Title color="lightLink" weight="600" size="big" font="Inter">
        SUCCESS!
      </Title>
      <ContentContainer>
        <IconStyled svgComponent={ScientistSVG} />
        <Text size="big">
          Check your inbox for your activation email. We’ve sent it to{' '}
          <Span size="big" weight="700">
            {location.state.email}
          </Span>
        </Text>
        <Text size="big">
          You are about to discover{' '}
          <a href="https://www.gideononline.com/about/testimonials">one of the most impressive</a>{' '}
          Infectious Disease databases in the world. Good choice!
        </Text>
      </ContentContainer>
    </TrialLayout>
  );
};
