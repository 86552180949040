import { Link } from 'Atoms/links/Link';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React from 'react';
import styled from 'styled-components/macro';
import { Pathogen } from 'types/disease';
import { DynamicContent } from 'types/dynamicContent';

const PBold = styled.p`
  font: ${props => props.theme.fonts.normalTextBold};
`;

interface DiseaseDetails {
  disease: string;
  clinicalNote: DynamicContent;
  pathogens?: Pathogen[];
}

interface Props {
  details: DiseaseDetails;
}

export const ClinicalFindingsSection: React.FC<Props> = ({ details }) => {
  return (
    <>
      <DynamicHtml content={details.clinicalNote} />
      {details.pathogens && details.pathogens.length > 0 && (
        <>
          <PBold>Pathogens related to {details.disease}</PBold>
          <ul>
            {details.pathogens.map(p =>
              p.microbe ? (
                <li key={p.code}>
                  <Link to={`/explore/microbes/${p.microbe.type}/${p.microbe.code}`}>
                    {p.pathogen}
                  </Link>
                </li>
              ) : (
                <li key={p.code}>{p.pathogen}</li>
              )
            )}
          </ul>
        </>
      )}
    </>
  );
};
