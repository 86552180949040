import { parseDynamicContent } from 'services/parser/dynamicContentParser';
import { DynamicContent } from 'types/dynamicContent';
import { Image } from 'types/image';
import { Reference, ReferenceIdentifier } from 'types/reference';
import { Crossborders, Outbreaks, Survey } from 'types/surveyTables';

import { CrossborderEventDTO, ImageDTO, OutbreaksDTO, ReferenceDTO, SurveyDTO } from './DTO';

export const parseReferenceIdentifier = (refNumber: string): ReferenceIdentifier => {
  if (refNumber.startsWith('m ') || refNumber.startsWith('p ')) {
    return refNumber;
  }

  throw new Error('unexpected reference number');
};

export const mapReferenceDTO = (reference: ReferenceDTO): Reference => ({
  referenceIdentifier: parseReferenceIdentifier(reference.reference_number),
  citation: reference.citation,
  shortCitation: reference.short_citation,
  href: reference.href,
});

const getNotesData = (notes: string | null, references: ReferenceDTO[]): DynamicContent => {
  if (notes) {
    return parseDynamicContent(
      notes + references.map(ref => `{${ref.reference_number}}`).join(' ')
    );
  }
  return parseDynamicContent(references.map(ref => `{${ref.reference_number}}`).join(' '));
};

export const mapSurveyDTO = (x: SurveyDTO): Survey => ({
  surveyStart: x.survey_start,
  surveyEnd: x.survey_end,
  publicationYear: x.publication_year,
  region: x.region,
  population: x.population,
  percent1: x.percent1,
  percent2: x.percent2,
  notes: getNotesData(x.notes, x.references),
  references: x.references.map(mapReferenceDTO),
});

export const mapCrossbordersDTO = (x: CrossborderEventDTO): Crossborders => ({
  diseaseCode: x.disease_code,
  startYear: x.start_year,
  endYear: x.end_year,
  publicationYear: x.publication_year,
  acquisitionCountry: x.acquisition_country,
  originationCountry: x.origination_country,
  setting: x.setting,
  cases: x.cases,
  deaths: x.deaths,
  notes: getNotesData(x.notes, x.references),
  references: x.references.map(mapReferenceDTO),
});

export const mapOutbreaksDTO = (x: OutbreaksDTO): Outbreaks => ({
  outbreakStart: x.outbreak_start,
  outbreakEnd: x.outbreak_end,
  publicationYear: x.publication_year,
  region: x.region,
  vehicle: x.vehicle,
  setting: x.setting,
  population: x.population,
  cases: x.cases,
  deaths: x.deaths,
  pathogen: x.pathogen,
  clinical: x.clinical,
  notes: getNotesData(x.notes, x.references),
  references: x.references.map(mapReferenceDTO),
});

export const mapImageDTO = ({
  image_file_name: imageFileName,
  alt_text: altText,
  ...rest
}: ImageDTO): Image => ({
  imageFileName,
  altText,
  ...rest,
});
