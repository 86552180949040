import { Graph, GraphData } from 'Atoms/Graph';
import React, { FC, useMemo } from 'react';
import { isLoading } from 'store/types';
import { Chart } from 'types/chart';

const yTitle1 = 'Numbers reported';
const yTitle2 = 'Rates per 100,000';

interface Props {
  chart: Chart;
  chartRates?: Chart;
  isMobile?: boolean;
}

type ChartRatesDictionary = { [year: string]: number };

export const CountryNotesGraph: FC<Props> = ({ chart, chartRates, isMobile }) => {
  const data = useMemo((): GraphData => {
    if (isLoading(chart) || isLoading(chartRates)) {
      return [];
    }

    const data: GraphData = chart.coordinates.map(c => [c.x, parseFloat(c.y)]);

    if (chartRates && chartRates.coordinates.length > 0) {
      const chartCoordinatesDictionary: ChartRatesDictionary = Object.fromEntries(
        chartRates.coordinates.map(c => [c.x, parseFloat(c.y)])
      );

      return [
        ['', yTitle1, yTitle2],
        ...data.map(([col1, col2]) => [col1, col2, col1 && chartCoordinatesDictionary[col1]]),
      ];
    } else {
      return [['', yTitle1], ...data];
    }
  }, [chart, chartRates]);

  const yMaxValue = useMemo(() => {
    return data.every((values, index) => {
      return (
        index === 0 ||
        values.every((value, index) => {
          return index === 0 || value === 0 || !value;
        })
      );
    })
      ? '2'
      : undefined;
  }, [data]);

  if (isLoading(chart) || isLoading(chartRates)) {
    return <p>Loading...</p>;
  }

  return (
    <Graph
      data={data}
      dualAxis
      yTitle1={yTitle1}
      yTitle2={chartRates ? yTitle2 : ''}
      isMobile={isMobile}
      yMaxValue1={yMaxValue}
      yMaxValue2={yMaxValue}
    />
  );
};
