import { ReactComponent as LegendIcon } from 'assets/Map/legend.svg';
import { ColoredBox } from 'Atoms/ColoredBox';
import { P } from 'Atoms/text';
import { CompleteTooltip } from 'Atoms/tooltip/CompleteTooltip';
import { PopupContainer } from 'Atoms/tooltip/PopupContainer';
import { Popper } from 'Organisms/Popper';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Dictionary } from 'types/common';

const ColoredBoxStyled = styled(ColoredBox)<{ color: string }>`
  background-color: ${props => props.color};
`;

const MapLegendEntry = styled.div`
  display: flex;
  margin: 5px 0px;
  ${ColoredBoxStyled} {
    margin-right: 10px;
    border: 1px solid ${props => props.theme.colors.text.secondary};
  }
`;

const CompleteTooltipStyled = styled(CompleteTooltip)`
  position: unset;

  margin: 0 0 0 auto;
`;

const TooltipContent: Dictionary<string> = {
  Endemic:
    'Autochthonous cases of this disease are reported or have been reported in recent years; or likelihood of human disease, by virtue of the ongoing presence of the infecting agent in local reservoirs/vectors.',
  'Not Endemic': 'Autochthonous disease is not reported.',
  Sporadic:
    'Endemic, however, reported or estimated disease rates are <1 per million population per year.',
};

const StyledPopupContainer = styled(PopupContainer)`
  background: ${props => props.theme.colors.tooltip.text};
`;

interface Props {
  endemicLabels: string[];
  endemicColors: Dictionary<string>;
  referenceElement: HTMLElement | null;
  onClose: () => void;
  isOpen: boolean;
}

export const Legend: FC<Props> = ({
  endemicLabels,
  endemicColors,
  referenceElement,
  onClose,
  isOpen,
}) => {
  const startsWithMore = endemicLabels.some(x => x.startsWith('>'));

  const showTooltip = endemicLabels.length === 3;

  return (
    <Popper
      referenceElement={referenceElement}
      position="bottom-end"
      offsetDistance={5}
      isOpen={isOpen}
    >
      <StyledPopupContainer onClose={onClose} titleIcon={LegendIcon} title="Current endemicity">
        {startsWithMore && <P weight="600">Annual disease rates per 100,000 population</P>}
        {endemicLabels.map(l => (
          <MapLegendEntry key={l}>
            <ColoredBoxStyled color={endemicColors[l]} round />
            {l}
            {showTooltip && <CompleteTooltipStyled content={TooltipContent[l]} />}
          </MapLegendEntry>
        ))}
      </StyledPopupContainer>
    </Popper>
  );
};
