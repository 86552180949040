import { parseDynamicContent } from 'services/parser/dynamicContentParser';
import { DrugDetails, DrugDetailsPublic } from 'types/drug';
import { DrugSpectrum } from 'types/drugSpectrum';
import { ToxicEffect } from 'types/toxicEffect';

import { mapReferenceDTO } from '../common/mappers';
import { DrugDetailsDTO, DrugDetailsPublicDTO, DrugSpectrumDTO, DrugToxicEffectDTO } from './DTO';

export const mapDrugToxicEffectsDTO = (toxicEffect: DrugToxicEffectDTO): ToxicEffect => ({
  id: toxicEffect.code,
  name: toxicEffect.toxic_effects,
});

export const mapDrugSpectrumDTO = (spectrum: DrugSpectrumDTO): DrugSpectrum => ({
  code: spectrum.pathogen_code,
  pathogen: spectrum.pathogen.trim(),
  drugOfChoice: spectrum.drug_of_choice,
  usedInCombination: spectrum.used_in_combination,
  isDrugClass: spectrum.is_drug_class,
  excisionRequired: spectrum.excision_required,
  microbiologyAgents: spectrum.microbiology_agents,
  surgeryRequired: spectrum.surgery_required,
});

export const mapDrugDetailsDTO = (
  d: DrugDetailsDTO,
  effects: DrugToxicEffectDTO[],
  interactions: string[],
  spectrum: DrugSpectrumDTO[]
): DrugDetails => ({
  code: d.drug_code,
  drug: d.drug,
  mechanismOfAction: parseDynamicContent(d.mechanism_of_action),
  adultDosage: parseDynamicContent(d.adult_dosage),
  pediatricDosage: parseDynamicContent(d.pediatric_dosage),
  csfPenetration: parseDynamicContent(d.csf_penetration),
  referencesLinks: parseDynamicContent(
    d.references.map(ref => `{${ref.reference_number}}`).join(' ')
  ),
  renal80: d.renal_80,
  renal50: d.renal_50_80,
  renal10: d.renal_10_50,
  renal: d.renal_10,
  hemodialysisDosage: d.hemodialysis_dosage,
  peritonealDialysisDosage: d.peritoneal_dialysis_dosage,
  references: d.references.map(mapReferenceDTO),
  synonyms: d.synonyms,
  toxicEffects: effects.map(mapDrugToxicEffectsDTO),
  interactions,
  spectrum: spectrum.filter(s => !s.is_drug_class).map(mapDrugSpectrumDTO),
  drugClasses: spectrum.filter(s => s.is_drug_class).map(mapDrugSpectrumDTO),
});

export const mapDrugDetailsPublicDTO = (d: DrugDetailsPublicDTO): DrugDetailsPublic => ({
  code: d.drug_code,
  drug: d.drug,
  mechanismOfAction: parseDynamicContent(d.mechanism_of_action),
  adultDosage: parseDynamicContent(d.adult_dosage),
  pediatricDosage: parseDynamicContent(d.pediatric_dosage),
  csfPenetration: parseDynamicContent(d.csf_penetration),
});
