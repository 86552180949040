import { H3 } from 'Atoms/text';
import { AccessExpirationContent } from 'Organisms/AccessExpiration';
import { SignInForm } from 'Organisms/SignInForm';
import React, { FC, FormEvent, useContext, useRef, useState } from 'react';
import { ErrorFields, Fields } from 'store/accountStore/hooks';
import { IPLoginStatus } from 'store/accountStore/provider';
import styled, { ThemeContext } from 'styled-components/macro';

const Container = styled.div`
  background: ${props => props.theme.colors.background.primary};
  border: solid 1px ${props => props.theme.colors.text.darkLink};
  border-radius: 5px;

  padding: 20px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.img`
  width: 45px;
  height: 50px;
`;

const Title = styled(H3)`
  margin: 20px 0 0 0;
`;

const SubTitle = styled(H3)`
  margin: 20px 0 0 0;
`;

const SignInFormStyled = styled(SignInForm)`
  margin-top: 40px;
`;

interface Props {
  onSubmit: (e: FormEvent<HTMLFormElement>, fields: Fields) => void;
  isLoading: boolean;
  ipLoginStatus: IPLoginStatus;
}

export const PaywallContent: FC<Props> = ({
  onSubmit,
  isLoading: authIsLoading,
  ipLoginStatus,
}) => {
  const theme = useContext(ThemeContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [accountExpired, setAccountExpired] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ErrorFields>({
    username: null,
    password: null,
    form: null,
  });
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const isFormLoading = isLoggingIn || authIsLoading;
  const isPageLoading = authIsLoading || ipLoginStatus === 'initial';

  const usernameInput = useRef<HTMLInputElement>(null);
  const passwordInput = useRef<HTMLInputElement>(null);

  return (
    <Container>
      {accountExpired ? (
        <AccessExpirationContent />
      ) : (
        <>
          <Logo src={theme.images.mobileLogo} alt="Gideon logo" />
          <Title weight="600" color="darkLink" size="big" font="Inter">
            Get the data you need
          </Title>
          <SubTitle weight="600" size="mediumRegular" font="Inter">
            Full text is just a click away
          </SubTitle>
          <SignInFormStyled
            isFormLoading={isFormLoading}
            isPageLoading={isPageLoading}
            onSubmit={e =>
              onSubmit(e, {
                username,
                password,
                setAccountExpired,
                setErrorMessage,
                setIsSigningIn: setIsLoggingIn,
                isFormLoading,
                usernameInput,
                passwordInput,
              })
            }
            errorMessage={errorMessage}
            onUsernameChange={value => {
              setUsername(value);
              setErrorMessage({ ...errorMessage, username: null, form: null });
            }}
            onPasswordChange={value => {
              setPassword(value);
              setErrorMessage({ ...errorMessage, password: null, form: null });
            }}
            username={username}
            password={password}
            usernameInput={usernameInput}
            passwordInput={passwordInput}
          />
        </>
      )}
    </Container>
  );
};
