import { AZList } from 'Molecules/az/AZList';
import { AzMicrobesButtonsMobile } from 'Molecules/az/AZMicrobesButtonsMobile';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { match, Route, Switch } from 'react-router-dom';
import { useMicrobesGrouped } from 'store/microbesStore/hooks';
import { microbeNames, microbes } from 'types/az';

interface Props {
  match: match;
  title?: string;
}

export const AZMicrobesMobile: FC<Props> = ({ match, title }) => {
  const groupedMicrobes = useMicrobesGrouped();
  const { bacteria, mycobacteria, yeasts } = groupedMicrobes;

  return (
    <>
      <Helmet>
        <title>A-Z {title} - GIDEON</title>
      </Helmet>
      <Switch>
        <Route
          exact
          path={match.url}
          render={() => (
            <AzMicrobesButtonsMobile
              basePath={match.url}
              bacteriaTotal={bacteria.total}
              mycobacteriaTotal={mycobacteria.total}
              yeastsTotal={yeasts.total}
            />
          )}
        />
        {microbes.map(m => (
          <Route
            key={m}
            path={`${match.url}/${m}`}
            render={({ match: newMatch }) => (
              <AZList
                data={groupedMicrobes[m]}
                category={m}
                match={newMatch}
                isMobile
                title={microbeNames[m]}
              />
            )}
          />
        ))}
      </Switch>
    </>
  );
};
