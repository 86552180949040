import { StyledSpan } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { DownloadButton } from 'Molecules/buttons/DownloadButton';
import { MailPDFModalForm } from 'Organisms/mailPdf/MailPDFModalForm';
import styled, { css } from 'styled-components/macro';

export const SquareButtonStyles = css`
  width: 135px;
  min-width: 135px;
  white-space: nowrap;
  margin: 0 10px 0 0;
  ${StyledSpan} {
    flex-direction: column;
    font: ${props => props.theme.fonts.verySmallTextSemiBold};
    ${Icon} {
      margin: 0 0 5px 0;
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 110px;
    min-width: 110px;
    margin: 0 5px 0 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 95px;
    min-width: 95px;
  }
`;

export const SquareDownloadButton = styled(DownloadButton)`
  ${SquareButtonStyles}
`;

export const SquareMailButton = styled(MailPDFModalForm)`
  ${SquareButtonStyles}
`;
