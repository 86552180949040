import { DataDTO, ImageDTO } from 'apiServices/common/DTO';
import axios from 'axios.cached';
import { getSlug } from 'services/slug/slug.service';
import {
  MicrobeDetails,
  MicrobeDetailsPublic,
  MicrobeFilter,
  MicrobeType,
} from 'types/microbeDetails';
import { SimpleMicrobe, SimpleMicrobeWithSlug } from 'types/simpleMicrobe';

import {
  BacteriaDetailsDTO,
  BacteriaDetailsPublicDTO,
  MycobacteriaDetailsDTO,
  MycobacteriaDetailsPublicDTO,
  PhenotypeDTO,
  SimpleBacteriaDTO,
  SimpleMycobacteriaDTO,
  SimpleVirusDTO,
  SimpleYeastDTO,
  YeastsDetailsDTO,
  YeastsDetailsPublicDTO,
} from './DTO';
import {
  mapAllMicrobeDTO,
  mapBacteriaDetailsDTO,
  mapBacteriaDetailsPublicDTO,
  mapBacteriaDTO,
  mapMycobacteriaDetailsDTO,
  mapMycobacteriaDetailsPublicDTO,
  mapMycobacteriaDTO,
  mapVirusDTO,
  mapYeastDetailsDTO,
  mapYeastDetailsPublicDTO,
  mapYeastsDTO,
} from './microbes.mapper';

export const getBacteriaSimple = async (isPublic?: boolean): Promise<SimpleMicrobeWithSlug[]> => {
  const bacteria = await axios.get<SimpleBacteriaDTO>(
    isPublic ? '/api/public/microbiology/bacteria' : '/api/microbiology/bacteria?return=simple'
  );

  return bacteria.data.data.map(d => ({
    id: d.bacteria_code,
    name: d.bacteria,
    type: 'bacteria',
    slug: getSlug(d.bacteria),
  }));
};

export const getMycobacteriaSimple = async (
  isPublic?: boolean
): Promise<SimpleMicrobeWithSlug[]> => {
  const bacteria = await axios.get<SimpleMycobacteriaDTO>(
    isPublic
      ? '/api/public/microbiology/mycobacteria'
      : '/api/microbiology/mycobacteria?return=simple'
  );

  return bacteria.data.data.map(d => ({
    id: d.mycobacteria_code,
    name: d.mycobacteria,
    type: 'mycobacteria',
    slug: getSlug(d.mycobacteria),
  }));
};

export const getYeastsSimple = async (isPublic?: boolean): Promise<SimpleMicrobeWithSlug[]> => {
  const bacteria = await axios.get<SimpleYeastDTO>(
    isPublic ? '/api/public/microbiology/yeasts' : '/api/microbiology/yeasts?return=simple'
  );

  return bacteria.data.data.map(d => ({
    id: d.yeast_code,
    name: d.yeast,
    type: 'yeasts',
    slug: getSlug(d.yeast),
  }));
};
export const getVirusSimple = async (isPublic?: boolean): Promise<SimpleMicrobeWithSlug[]> => {
  const bacteria = await axios.get<SimpleVirusDTO>(
    isPublic ? '/api/public/microbiology/virus' : '/api/microbiology/virus?return=simple'
  );

  return bacteria.data.data.map(d => ({
    id: d.virus_code,
    name: d.virus,
    type: 'virus',
    slug: getSlug(d.virus),
  }));
};
export const getMicrobeDetails = async (
  code: number,
  type: MicrobeType
): Promise<MicrobeDetails> => {
  if (type === 'bacteria') {
    const [bacteria, phenotype, images] = await Promise.all([
      axios.get<DataDTO<BacteriaDetailsDTO>>(`/api/microbiology/bacteria/${code}/general`),
      axios.get<DataDTO<PhenotypeDTO[]>>(`/api/microbiology/bacteria/${code}/phenotype`),
      axios.get<DataDTO<ImageDTO[]>>(`/api/microbiology/bacteria/${code}/images`),
    ]);

    return mapBacteriaDetailsDTO(bacteria.data.data, phenotype.data.data, images.data.data);
  }
  if (type === 'mycobacteria') {
    const [mycobacteria, phenotype, images] = await Promise.all([
      axios.get<DataDTO<MycobacteriaDetailsDTO>>(`/api/microbiology/mycobacteria/${code}/general`),
      axios.get<DataDTO<PhenotypeDTO[]>>(`/api/microbiology/mycobacteria/${code}/phenotype`),
      axios.get<DataDTO<ImageDTO[]>>(`/api/microbiology/mycobacteria/${code}/images`),
    ]);

    return mapMycobacteriaDetailsDTO(mycobacteria.data.data, phenotype.data.data, images.data.data);
  }
  // TODO should be handled if it is not yeast and return 404
  const [yeast, phenotype, images] = await Promise.all([
    axios.get<DataDTO<YeastsDetailsDTO>>(`/api/microbiology/yeasts/${code}/general`),
    axios.get<DataDTO<PhenotypeDTO[]>>(`/api/microbiology/yeasts/${code}/phenotype`),
    axios.get<DataDTO<ImageDTO[]>>(`/api/microbiology/yeasts/${code}/images`),
  ]);

  return mapYeastDetailsDTO(yeast.data.data, phenotype.data.data, images.data.data);
};

export const getMicrobeDetailsPublic = async (
  code: number,
  type: MicrobeType
): Promise<MicrobeDetailsPublic> => {
  if (type === 'bacteria') {
    const bacteria = await axios.get<DataDTO<BacteriaDetailsPublicDTO>>(
      `/api/public/microbiology/bacteria/${code}`
    );

    return mapBacteriaDetailsPublicDTO(bacteria.data.data);
  } else if (type === 'mycobacteria') {
    const mycobacteria = await axios.get<DataDTO<MycobacteriaDetailsPublicDTO>>(
      `/api/public/microbiology/mycobacteria/${code}`
    );

    return mapMycobacteriaDetailsPublicDTO(mycobacteria.data.data);
  }

  const yeast = await axios.get<DataDTO<YeastsDetailsPublicDTO>>(
    `/api/public/microbiology/yeasts/${code}`
  );

  return mapYeastDetailsPublicDTO(yeast.data.data);
};

export const getMicrobeSynonyms = async (
  type: MicrobeFilter,
  search: string,
  limit = 50
): Promise<SimpleMicrobe[]> => {
  const bacteriaUrl = `/api/microbiology/bacteria/synonyms?search=${search}&limit=${limit}`;
  const mycobacteriaUrl = `/api/microbiology/mycobacteria/synonyms?search=${search}&limit=${limit}`;
  const yeastsUrl = `/api/microbiology/yeasts/synonyms?search=${search}&limit=${limit}`;
  const virusUrl = `/api/microbiology/virus/synonyms?search=${search}&limit=${limit}`;

  switch (type) {
    case 'bacteria': {
      const response = await axios.get<SimpleBacteriaDTO>(bacteriaUrl);
      return mapBacteriaDTO(response.data);
    }
    case 'mycobacteria': {
      const response = await axios.get<SimpleMycobacteriaDTO>(mycobacteriaUrl);
      return mapMycobacteriaDTO(response.data);
    }
    case 'yeasts': {
      const response = await axios.get<SimpleYeastDTO>(yeastsUrl);
      return mapYeastsDTO(response.data);
    }
    case 'virus': {
      const response = await axios.get<SimpleVirusDTO>(virusUrl);
      return mapVirusDTO(response.data);
    }
    default: {
      const [
        bacteriaResponse,
        mycobacteriaResponse,
        yeastsResponse,
        virusResponse,
      ] = await Promise.all([
        axios.get<SimpleBacteriaDTO>(bacteriaUrl),
        axios.get<SimpleMycobacteriaDTO>(mycobacteriaUrl),
        axios.get<SimpleYeastDTO>(yeastsUrl),
        axios.get<SimpleVirusDTO>(virusUrl),
      ]);

      return mapAllMicrobeDTO(
        bacteriaResponse.data,
        mycobacteriaResponse.data,
        yeastsResponse.data,
        virusResponse.data
      );
    }
  }
};

export const getMicrobeSynonymsPublic = async (
  type: MicrobeFilter,
  search: string,
  limit = 50
): Promise<SimpleMicrobe[]> => {
  const bacteriaUrl = `/api/public/microbiology/bacteria/synonyms?search=${search}&limit=${limit}`;
  const mycobacteriaUrl = `/api/public/microbiology/mycobacteria/synonyms?search=${search}&limit=${limit}`;
  const yeastsUrl = `/api/public/microbiology/yeasts/synonyms?search=${search}&limit=${limit}`;
  const virusUrl = `/api/public/microbiology/virus/synonyms?search=${search}&limit=${limit}`;

  switch (type) {
    case 'bacteria': {
      const response = await axios.get<SimpleBacteriaDTO>(bacteriaUrl);
      return mapBacteriaDTO(response.data);
    }
    case 'mycobacteria': {
      const response = await axios.get<SimpleMycobacteriaDTO>(mycobacteriaUrl);
      return mapMycobacteriaDTO(response.data);
    }
    case 'yeasts': {
      const response = await axios.get<SimpleYeastDTO>(yeastsUrl);
      return mapYeastsDTO(response.data);
    }
    case 'virus': {
      const response = await axios.get<SimpleVirusDTO>(virusUrl);
      return mapVirusDTO(response.data);
    }
    default: {
      const [
        bacteriaResponse,
        mycobacteriaResponse,
        yeastsResponse,
        virusResponse,
      ] = await Promise.all([
        axios.get<SimpleBacteriaDTO>(bacteriaUrl),
        axios.get<SimpleMycobacteriaDTO>(mycobacteriaUrl),
        axios.get<SimpleYeastDTO>(yeastsUrl),
        axios.get<SimpleVirusDTO>(virusUrl),
      ]);

      return mapAllMicrobeDTO(
        bacteriaResponse.data,
        mycobacteriaResponse.data,
        yeastsResponse.data,
        virusResponse.data
      );
    }
  }
};
