import { LogBody, LogParams } from 'apiServices/Logs/DTO';
import axios, { AxiosResponse } from 'axios.cached';

export const reportLog = async (log: LogParams): Promise<AxiosResponse> => {
  const requestBody: LogBody = {
    ...log,
    datetime: `${new Date().toISOString().split('.')[0]}Z`,
  };

  return await axios.post('/api/internal/log', requestBody);
};
