import { ModalCloseButton } from 'Molecules/buttons/ModalCloseButton';
import React, { FC, ReactNode } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components/macro';
import { ScrollbarMixin } from 'utils/styleMixins';

const StyledCloseButton = styled(ModalCloseButton)`
  z-index: 1000;
`;

export const contentClassNames = {
  base: 'Modal__Content',
  afterOpen: `Modal__Content--after-open`,
  beforeClose: `Modal__Content--before-close`,
};

export const overlayClassNames = {
  base: 'Modal__Overlay',
  afterOpen: 'Modal__Overlay--after-open',
  beforeClose: 'Modal__Overlay--before-close',
};

export const DefaultAnimationTime = 200;

interface ModalProps {
  className?: string;
  isOpen: boolean;
  onClose?: () => void;
  children: ReactNode;
  disableScroll?: boolean;
  showCloseButton?: boolean;
  // Animation time in ms
  animationTime?: number;
  parentSelector?: () => HTMLElement;
  contentLabel?: string;
}

const ModalBase: FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  className,
  showCloseButton = false,
  animationTime = DefaultAnimationTime,
  parentSelector,
  contentLabel,
}) => (
  <ReactModal
    portalClassName={className}
    className={contentClassNames}
    overlayClassName={overlayClassNames}
    isOpen={isOpen}
    onRequestClose={onClose}
    closeTimeoutMS={animationTime}
    parentSelector={parentSelector}
    contentLabel={contentLabel}
  >
    {showCloseButton && <StyledCloseButton onClick={onClose} aria-label="Modal close" />}
    {children}
  </ReactModal>
);

export const Modal = styled(ModalBase)`
  .${overlayClassNames.base} {
    position: fixed;
    z-index: 900;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color ${props => props.animationTime || DefaultAnimationTime}ms linear;
    background-color: ${props => props.theme.colors.modal.base.overlayBackground};
  }

  .${overlayClassNames.afterOpen} {
    background-color: ${props => props.theme.colors.modal.afterOpen.overlayBackground};
  }

  .${overlayClassNames.beforeClose} {
    background-color: ${props => props.theme.colors.modal.beforeClose.overlayBackground};
  }

  .${contentClassNames.base} {
    position: relative;
    opacity: 0;
    flex: 1 0 auto;
    max-height: 95%;
    max-width: 1000px;
    width: 100%;
    overflow: ${props => (props.disableScroll ? 'unset' : 'auto')};
    ${ScrollbarMixin};
    transition: opacity ${props => props.animationTime || DefaultAnimationTime}ms linear;

    @media (max-width: ${props => props.theme.breakpoints.m}) {
      max-width: 100%;
      max-height: 100%;
    }

    &:focus {
      outline: none;
    }
  }
  .${contentClassNames.afterOpen} {
    opacity: 1;
  }
  .${contentClassNames.beforeClose} {
    opacity: 0;
  }
`;

export const ModalFromBottom = styled(Modal)`
  .${overlayClassNames.base} {
    align-items: flex-end;
    justify-content: flex-end;
  }
  .${contentClassNames.base} {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    transition: transform ${props => props.animationTime || 200}ms ease-out;
    transform: translateY(150%);
  }

  .${contentClassNames.afterOpen} {
    transform: translateY(0);
  }

  .${contentClassNames.beforeClose} {
    transform: translateY(150%);
    opacity: 1;
  }
`;
