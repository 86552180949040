import { ReactNode } from 'react';
import { ActionWithPayload } from 'store/types';

type StringField =
  | 'firstName'
  | 'lastName'
  | 'institution'
  | 'email'
  | 'password'
  | 'repeatPassword';

type BooleanField = 'tos' | 'newsletter';

type FieldName = StringField | BooleanField;

type ErrorFieldName = FieldName | 'form';

export type SetValueAction<T extends FieldName> = { field: T; value: State[T] };

export type Action =
  | ActionWithPayload<
      'SignUp/Set/Value',
      SetValueAction<StringField> | SetValueAction<BooleanField>
    >
  | ActionWithPayload<'SignUp/Set/Errors', State['errors']>
  | ActionWithPayload<'SignUp/Set/Error', { field: ErrorFieldName; value: ReactNode | null }>;

export type State = Record<StringField, string> &
  Record<BooleanField, boolean> & {
    errors: Record<ErrorFieldName, ReactNode | null>;
  };

export const initialState: State = {
  firstName: '',
  lastName: '',
  institution: '',
  email: '',
  password: '',
  repeatPassword: '',
  newsletter: false,
  tos: false,
  errors: {
    firstName: null,
    lastName: null,
    email: null,
    institution: null,
    newsletter: null,
    password: null,
    repeatPassword: null,
    tos: null,
    form: null,
  },
};
