import { isNotNull } from 'apiServices/common/guards';

export const allCountries = [
  { id: 'G101', name: 'Afghanistan', code: 'AF' } as const,
  { id: 'G102', name: 'Albania', code: 'AL' } as const,
  { id: 'G103', name: 'Algeria', code: 'DZ' } as const,
  { id: 'G104', name: 'American Samoa', code: 'AS' } as const,
  { id: 'G105', name: 'Andorra', code: 'AD' } as const,
  { id: 'G106', name: 'Angola', code: 'AO' } as const,
  { id: 'G107', name: 'Anguilla', code: 'AI' } as const,
  {
    id: 'G108',
    name: 'Antigua and Barbuda',
    code: 'AG',
    naturalEarthUnitIds: [1159320349, 1159320347],
  } as const,
  { id: 'G109', name: 'Argentina', code: 'AR' } as const,
  { id: 'G306', name: 'Armenia', code: 'AM' } as const,
  { id: 'G110', name: 'Aruba', code: 'AW' } as const,
  { id: 'G111', name: 'Australia', code: 'AU' } as const,
  { id: 'G112', name: 'Austria', code: 'AT' } as const,
  { id: 'G307', name: 'Azerbaijan', code: 'AZ' } as const,
  {
    id: 'G113',
    name: 'Azores',
    code: null,
    naturalEarthUnitIds: [1159321189],
  } as const,
  { id: 'G114', name: 'Bahamas', code: 'BS' } as const,
  { id: 'G115', name: 'Bahrain', code: 'BH' } as const,
  { id: 'G116', name: 'Bangladesh', code: 'BD' } as const,
  { id: 'G117', name: 'Barbados', code: 'BB' } as const,
  { id: 'G308', name: 'Belarus', code: 'BY' } as const,
  {
    id: 'G118',
    name: 'Belgium',
    code: 'BE',
    naturalEarthUnitIds: [1159320397, 1159320395, 1159320391],
  } as const,
  { id: 'G119', name: 'Belize', code: 'BZ' } as const,
  { id: 'G120', name: 'Benin', code: 'BJ' } as const,
  { id: 'G121', name: 'Bermuda', code: 'BM' } as const,
  { id: 'G122', name: 'Bhutan', code: 'BT' } as const,
  { id: 'G123', name: 'Bolivia', code: 'BO' } as const,
  {
    id: 'G320',
    name: 'Bosnia and Herzegovina',
    code: 'BA',
    naturalEarthUnitIds: [1159320423, 1159320421, 1159320419],
  } as const,
  { id: 'G124', name: 'Botswana', code: 'BW' } as const,
  { id: 'G125', name: 'Brazil', code: 'BR' } as const,
  { id: 'G126', name: 'British Virgin Islands', code: 'VG' } as const,
  { id: 'G127', name: 'Brunei', code: 'BN' } as const,
  { id: 'G128', name: 'Bulgaria', code: 'BG' } as const,
  { id: 'G129', name: 'Burkina Faso', code: 'BF' } as const,
  { id: 'G130', name: 'Burundi', code: 'BI' } as const,
  { id: 'G131', name: 'Cambodia', code: 'KH' } as const,
  { id: 'G132', name: 'Cameroon', code: 'CM' } as const,
  { id: 'G133', name: 'Canada', code: 'CA' } as const,
  { id: 'G134', name: 'Canary Islands (Spain)', code: 'IC' } as const,
  { id: 'G135', name: 'Cape Verde', code: 'CV' } as const,
  { id: 'G136', name: 'Cayman Islands', code: 'KY' } as const,
  { id: 'G137', name: 'Central African Republic', code: 'CF' } as const,
  { id: 'G138', name: 'Chad', code: 'TD' } as const,
  { id: 'G139', name: 'Chile', code: 'CL' } as const,
  {
    id: 'G140',
    name: 'China',
    code: 'CN',
    naturalEarthUnitIds: [1159320477],
  } as const,
  { id: 'G141', name: 'Christmas Island', code: 'CX' } as const,
  { id: 'G142', name: 'Colombia', code: 'CO' } as const,
  { id: 'G143', name: 'Comoros', code: 'KM' } as const,
  { id: 'G144', name: 'Congo', code: 'CG' } as const,
  { id: 'G145', name: 'Cook Islands', code: 'CK' } as const,
  { id: 'G146', name: 'Costa Rica', code: 'CR' } as const,
  { id: 'G321', name: 'Croatia', code: 'HR' } as const,
  {
    id: 'G147',
    name: 'Cuba',
    code: 'CU',
    naturalEarthUnitIds: [1159320529],
  } as const,
  { id: 'G148', name: 'Cyprus', code: 'CY' } as const,
  { id: 'G149', name: 'Czech Republic', code: 'CZ' } as const,
  { id: 'G303', name: 'Democratic Rep. of Congo', code: 'CD' } as const,
  { id: 'G150', name: 'Denmark', code: 'DK' } as const,
  { id: 'G151', name: 'Djibouti', code: 'DJ' } as const,
  { id: 'G152', name: 'Dominica', code: 'DM' } as const,
  { id: 'G153', name: 'Dominican Republic', code: 'DO' } as const,
  { id: 'G350', name: 'Timor-Leste', code: 'TL' } as const,
  { id: 'G154', name: 'Ecuador', code: 'EC' } as const,
  { id: 'G155', name: 'Egypt', code: 'EG' } as const,
  { id: 'G156', name: 'El Salvador', code: 'SV' } as const,
  { id: 'G157', name: 'Equatorial Guinea', code: 'GQ' } as const,
  { id: 'G324', name: 'Eritrea', code: 'ER' } as const,
  { id: 'G309', name: 'Estonia', code: 'EE' } as const,
  { id: 'G273', name: 'Eswatini', code: 'SZ' } as const,
  { id: 'G158', name: 'Ethiopia', code: 'ET' } as const,
  { id: 'G159', name: 'Falkland Islands', code: 'FK' } as const,
  { id: 'G160', name: 'Fiji', code: 'FJ' } as const,
  { id: 'G161', name: 'Finland', code: 'FI' } as const,
  { id: 'G162', name: 'France', code: 'FR' } as const,
  { id: 'G163', name: 'French Guiana', code: 'GF' } as const,
  { id: 'G164', name: 'French Polynesia', code: 'PF' } as const,
  { id: 'G165', name: 'Gabon', code: 'GA' } as const,
  { id: 'G166', name: 'Gambia', code: 'GM' } as const,
  {
    id: 'G310',
    name: 'Georgia',
    code: 'GE',
    naturalEarthUnitIds: [1159320783, 1159320781],
  } as const,
  { id: 'G167', name: 'Germany', code: 'DE' } as const,
  { id: 'G168', name: 'Ghana', code: 'GH' } as const,
  { id: 'G169', name: 'Gibraltar', code: 'GI' } as const,
  { id: 'G170', name: 'Greece', code: 'GR' } as const,
  { id: 'G171', name: 'Greenland', code: 'GL' } as const,
  { id: 'G172', name: 'Grenada', code: 'GD' } as const,
  { id: 'G173', name: 'Guadeloupe', code: 'GP' } as const,
  { id: 'G174', name: 'Guam', code: 'GU' } as const,
  { id: 'G175', name: 'Guatemala', code: 'GT' } as const,
  { id: 'G176', name: 'Guinea', code: 'GN' } as const,
  { id: 'G177', name: 'Guinea Bissau', code: 'GW' } as const,
  { id: 'G178', name: 'Guyana', code: 'GY' } as const,
  { id: 'G179', name: 'Haiti', code: 'HT' } as const,
  { id: 'G180', name: 'Honduras', code: 'HN' } as const,
  { id: 'G181', name: 'Hong Kong', code: 'HK' } as const,
  { id: 'G182', name: 'Hungary', code: 'HU' } as const,
  { id: 'G183', name: 'Iceland', code: 'IS' } as const,
  { id: 'G184', name: 'India', code: 'IN' } as const,
  { id: 'G185', name: 'Indonesia', code: 'ID' } as const,
  { id: 'G186', name: 'Iran', code: 'IR' } as const,
  {
    id: 'G187',
    name: 'Iraq',
    code: 'IQ',
    naturalEarthUnitIds: [1159320889, 1159320891],
  } as const,
  { id: 'G188', name: 'Ireland', code: 'IE' } as const,
  {
    id: 'G189',
    name: 'Israel',
    code: 'IL',
    naturalEarthUnitIds: [1159320903, 1159320901],
  } as const,
  { id: 'G190', name: 'Italy', code: 'IT' } as const,
  {
    id: 'G191',
    name: 'Ivory Coast',
    altName: "Côte d'Ivoire",
    code: 'CI',
  } as const,
  { id: 'G192', name: 'Jamaica', code: 'JM' } as const,
  { id: 'G193', name: 'Japan', code: 'JP' } as const,
  { id: 'G194', name: 'Jordan', code: 'JO' } as const,
  {
    id: 'G311',
    name: 'Kazakhstan',
    code: 'KZ',
    naturalEarthUnitIds: [1159320965],
  } as const,
  { id: 'G195', name: 'Kenya', code: 'KE' } as const,
  { id: 'G196', name: 'Kiribati', code: 'KI' } as const,
  { id: 'G198', name: 'Kuwait', code: 'KW' } as const,
  { id: 'G312', name: 'Kyrgyzstan', code: 'KG' } as const,
  { id: 'G199', name: 'Laos', code: 'LA' } as const,
  { id: 'G313', name: 'Latvia', code: 'LV' } as const,
  { id: 'G200', name: 'Lebanon', code: 'LB' } as const,
  { id: 'G201', name: 'Lesotho', code: 'LS' } as const,
  { id: 'G202', name: 'Liberia', code: 'LR' } as const,
  { id: 'G203', name: 'Libya', code: 'LY' } as const,
  { id: 'G204', name: 'Liechtenstein', code: 'LI' } as const,
  { id: 'G314', name: 'Lithuania', code: 'LT' } as const,
  { id: 'G205', name: 'Luxembourg', code: 'LU' } as const,
  { id: 'G206', name: 'Macao', code: 'MO' } as const,
  { id: 'G207', name: 'Madagascar', code: 'MG' } as const,
  { id: 'G208', name: 'Malawi', code: 'MW' } as const,
  { id: 'G209', name: 'Malaysia', code: 'MY' } as const,
  { id: 'G210', name: 'Maldives', code: 'MV' } as const,
  { id: 'G211', name: 'Mali', code: 'ML' } as const,
  { id: 'G212', name: 'Malta', code: 'MT' } as const,
  { id: 'G351', name: 'Marshall Islands', code: 'MH' } as const,
  {
    id: 'G213',
    name: 'Martinique',
    altName: 'Martinique (France)',
    code: 'MQ',
  } as const,
  { id: 'G214', name: 'Mauritania', code: 'MR' } as const,
  { id: 'G215', name: 'Mauritius', code: 'MU' } as const,
  { id: 'G216', name: 'Mexico', code: 'MX' } as const,
  { id: 'G352', name: 'Micronesia', code: 'FM' } as const,
  { id: 'G315', name: 'Moldova', code: 'MD' } as const,
  { id: 'G217', name: 'Monaco', code: 'MC' } as const,
  { id: 'G218', name: 'Mongolia', code: 'MN' } as const,
  { id: 'G219', name: 'Montserrat', code: 'MS' } as const,
  { id: 'G220', name: 'Morocco', code: 'MA' } as const,
  { id: 'G221', name: 'Mozambique', code: 'MZ' } as const,
  { id: 'G222', name: 'Myanmar (Burma)', code: 'MM' } as const,
  { id: 'G223', name: 'Namibia', code: 'NA' } as const,
  { id: 'G224', name: 'Nauru', code: 'NR' } as const,
  { id: 'G225', name: 'Nepal', code: 'NP' } as const,
  {
    id: 'G226',
    name: 'Netherlands',
    code: 'NL',
    naturalEarthUnitIds: [1159321105],
  } as const,
  { id: 'G227', name: 'Netherlands Antilles', code: 'AN' } as const,
  { id: 'G228', name: 'New Caledonia', code: 'NC' } as const,
  { id: 'G229', name: 'New Zealand', code: 'NZ' } as const,
  { id: 'G230', name: 'Nicaragua', code: 'NI' } as const,
  { id: 'G231', name: 'Niger', code: 'NE' } as const,
  { id: 'G232', name: 'Nigeria', code: 'NG' } as const,
  { id: 'G233', name: 'Niue', code: 'NU' } as const,
  { id: 'G234', name: 'Norfolk Island', code: 'NF' } as const,
  { id: 'G322', name: 'North Macedonia', code: 'MK' } as const,
  { id: 'G353', name: 'Northern Marianas', code: 'MP' } as const,
  {
    id: 'G236',
    name: 'Norway',
    code: 'NO',
    naturalEarthUnitIds: [1159321115],
  } as const,
  { id: 'G237', name: 'Oman', code: 'OM' } as const,
  { id: 'G238', name: 'Pakistan', code: 'PK' } as const,
  { id: 'G354', name: 'Palau', code: 'PW' } as const,
  { id: 'G239', name: 'Panama', code: 'PA' } as const,
  {
    id: 'G240',
    name: 'Papua New Guinea',
    code: 'PG',
    naturalEarthUnitIds: [1159321177, 1159321175],
  } as const,
  { id: 'G241', name: 'Paraguay', code: 'PY' } as const,
  { id: 'G326', name: 'Peoples Dem. Rep. Korea', code: 'KP' } as const,
  { id: 'G242', name: 'Peru', code: 'PE' } as const,
  { id: 'G243', name: 'Philippines', code: 'PH' } as const,
  { id: 'G244', name: 'Pitcairn Island', code: 'PN' } as const,
  { id: 'G245', name: 'Poland', code: 'PL' } as const,
  {
    id: 'G246',
    name: 'Portugal',
    code: 'PT',
    naturalEarthUnitIds: [1159321193, 1159321191],
  } as const,
  { id: 'G247', name: 'Puerto Rico', code: 'PR' } as const,
  { id: 'G248', name: 'Qatar', code: 'QA' } as const,
  { id: 'G197', name: 'Republic of Korea', code: 'KR' } as const,
  {
    id: 'G249',
    name: 'Reunion',
    altName: 'Reunion (France)',
    code: 'RE',
  } as const,
  { id: 'G250', name: 'Romania', code: 'RO' } as const,
  { id: 'G251', name: 'Russian Federation', code: 'RU' } as const,
  { id: 'G252', name: 'Rwanda', code: 'RW' } as const,
  { id: 'G253', name: 'Samoa', code: 'WS' } as const,
  { id: 'G254', name: 'San Marino', code: 'SM' } as const,
  { id: 'G255', name: 'Sao Tome and Principe', code: 'ST' } as const,
  { id: 'G256', name: 'Saudi Arabia', code: 'SA' } as const,
  {
    id: 'G327',
    name: 'Scotland',
    code: 'Scotland',
    naturalEarthUnitIds: [1159320747],
  } as const,
  {
    id: 'G357',
    name: 'Northern Ireland',
    code: 'Northern Ireland',
    naturalEarthUnitIds: [1159320745],
  } as const,
  { id: 'G257', name: 'Senegal', code: 'SN' } as const,
  {
    id: 'G302',
    name: 'Serbia',
    code: 'RS',
    naturalEarthUnitIds: [1159321271, 1159321269],
  } as const,
  { id: 'G258', name: 'Seychelles', code: 'SC' } as const,
  { id: 'G259', name: 'Sierra Leone', code: 'SL' } as const,
  { id: 'G260', name: 'Singapore', code: 'SG' } as const,
  { id: 'G325', name: 'Slovakia', code: 'SK' } as const,
  { id: 'G323', name: 'Slovenia', code: 'SI' } as const,
  { id: 'G261', name: 'Solomon Islands', code: 'SB' } as const,
  {
    id: 'G262',
    name: 'Somalia',
    code: 'SO',
    naturalEarthUnitIds: [1159321259, 1159321265, 1159321263],
  } as const,
  { id: 'G263', name: 'South Africa', code: 'ZA' } as const,
  { id: 'G264', name: 'Spain', code: 'ES' } as const,
  { id: 'G265', name: 'Sri Lanka', code: 'LK' } as const,
  { id: 'G266', name: 'St. Helena', code: 'SH' } as const,
  { id: 'G267', name: 'St. Kitts and Nevis', code: 'KN' } as const,
  { id: 'G268', name: 'St. Lucia', code: 'LC' } as const,
  { id: 'G270', name: 'St. Vincent and the Grenadines', code: 'VC' } as const,
  { id: 'G271', name: 'Sudan', code: 'SD' } as const,
  { id: 'G272', name: 'Suriname', code: 'SR' } as const,
  { id: 'G274', name: 'Sweden', code: 'SE' } as const,
  { id: 'G275', name: 'Switzerland', code: 'CH' } as const,
  { id: 'G276', name: 'Syria', code: 'SY' } as const,
  { id: 'G277', name: 'Taiwan', code: 'TW' } as const,
  { id: 'G316', name: 'Tajikistan', code: 'TJ' } as const,
  {
    id: 'G278',
    name: 'Tanzania',
    code: 'TZ',
    naturalEarthUnitIds: [1159321341],
  } as const,
  { id: 'G279', name: 'Thailand', code: 'TH' } as const,
  { id: 'G280', name: 'Togo', code: 'TG' } as const,
  { id: 'G281', name: 'Tokelau', code: 'TK' } as const,
  { id: 'G282', name: 'Tonga', code: 'TO' } as const,
  { id: 'G283', name: 'Trinidad and Tobago', code: 'TT' } as const,
  { id: 'G285', name: 'Tunisia', code: 'TN' } as const,
  {
    id: 'G286',
    name: 'Turkey',
    code: 'TR',
    naturalEarthUnitIds: [1159320531],
  } as const,
  { id: 'G317', name: 'Turkmenistan', code: 'TM' } as const,
  { id: 'G287', name: 'Turks and Caicos Islands', code: 'TC' } as const,
  { id: 'G288', name: 'Tuvalu', code: 'TV' } as const,
  { id: 'G289', name: 'Uganda', code: 'UG' } as const,
  { id: 'G318', name: 'Ukraine', code: 'UA' } as const,
  { id: 'G290', name: 'United Arab Emirates', code: 'AE' } as const,
  {
    id: 'G291',
    name: 'United Kingdom',
    code: 'GB',
    naturalEarthUnitIds: [1159320749, 1159320743, 1159320709],
  } as const,
  { id: 'G292', name: 'United States', code: 'US' } as const,
  { id: 'G293', name: 'Uruguay', code: 'UY' } as const,
  { id: 'G319', name: 'Uzbekistan', code: 'UZ' } as const,
  { id: 'G294', name: 'Vanuatu', code: 'VU' } as const,
  { id: 'G295', name: 'Venezuela', code: 'VE' } as const,
  { id: 'G296', name: 'Vietnam', code: 'VN' } as const,
  { id: 'G297', name: 'Virgin Islands, U.S.', code: 'VI' } as const,
  {
    id: 'G298',
    name: 'Wake Island',
    code: 'UM',
    naturalEarthUnitIds: [1159321391],
  } as const,
  { id: 'G299', name: 'Wallis and Futuna Islands', code: 'WF' } as const,
  { id: 'G300', name: 'Western Sahara', code: 'EH' } as const,
  { id: 'G301', name: 'Yemen', code: 'YE' } as const,
  { id: 'G304', name: 'Zambia', code: 'ZM' } as const,
  { id: 'G305', name: 'Zimbabwe', code: 'ZW' } as const,
  { id: 'G356', name: 'Montenegro', code: 'ME' } as const,
  { id: 'G355', name: 'South Sudan', code: 'SS' } as const,
  { id: 'G358', name: 'Kosovo', code: 'XK' } as const,
  { id: null, name: 'Palestine', code: 'PS' } as const,
  { id: null, name: 'Faeroe Islands', code: 'FO' } as const,
  { id: null, name: 'Saint Martin (French)', code: 'MF' } as const,
  { id: null, name: 'Saint Martin (Dutch)', code: 'SX' } as const,
  { id: null, name: 'Mayotte (France)', code: 'YT' } as const,
  { id: null, name: 'Curaco (Netherlands)', code: 'CW' } as const,
  {
    id: null,
    name: 'Spratly Islands',
    code: null,
    naturalEarthUnitIds: [1159321165],
  } as const,
  {
    id: null,
    name: 'Siachen Glacier',
    code: null,
    naturalEarthUnitIds: [1159320963],
  } as const,
];

export const countryCodes = allCountries.map(c => c.code).filter(isNotNull);
export type CountryCode = typeof countryCodes[number];

export const countryIds = allCountries.map(c => c.id).filter(isNotNull);
export type CountryId = typeof countryIds[number];

export type CountriesByCode = Record<CountryCode, typeof allCountries[number]>;
export const countriesByCode: CountriesByCode = allCountries.reduce<CountriesByCode>((acc, c) => {
  if (!c.code) return acc;
  acc[c.code] = c;
  return acc;
}, {} as CountriesByCode);

export type CountriesById = Record<CountryId, typeof allCountries[number]>;
export const countriesById: CountriesById = allCountries.reduce<CountriesById>((acc, c) => {
  if (!c.id) return acc;
  acc[c.id] = c;
  return acc;
}, {} as CountriesById);

export type CountriesByNaturalEarthId = Record<number, typeof allCountries[number]>;
export const countriesByNaturalEarthId: CountriesByNaturalEarthId = allCountries.reduce<
  CountriesByNaturalEarthId
>((acc, c) => {
  if (!c.naturalEarthUnitIds) return acc;
  c.naturalEarthUnitIds.forEach((id: number) => {
    acc[id] = c;
  });
  return acc;
}, {} as CountriesByNaturalEarthId);
