import { BreadcrumbSelectDropdownIndicator } from 'Atoms/breadcrumb/BreadcrumbSelectDropdownIndicator';
import { SearchSelectMenuList } from 'Atoms/select/SearchSelectMenuList';
import { SearchSelectOption } from 'Atoms/select/SearchSelectOption';
import React, { FC } from 'react';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import styled from 'styled-components/macro';
import { BreadcrumbSelectOption } from 'types/breadcrumb';

interface BreadcrumbSelectProps {
  className?: string;
  classNamePrefix: string;
  onChange?: (
    value: OnChangeValue<BreadcrumbSelectOption, false>,
    actionMeta: ActionMeta<BreadcrumbSelectOption>
  ) => void;
  value?: string;
  options?: BreadcrumbSelectOption[];
  isSearchable?: boolean;
  placeholder?: string;
  noDefaultValue?: boolean;
  filterOption?: (
    option: FilterOptionOption<BreadcrumbSelectOption>,
    inputValue: string
  ) => boolean;
  isLoading?: boolean;
  menuPlacement?: 'top' | 'bottom';
  showValue?: boolean;
  ariaLabel?: string;
  hideSelectedOptions?: boolean;
  inputId?: string;
}

const BreadcrumbSelectBase: FC<BreadcrumbSelectProps> = ({
  className,
  classNamePrefix,
  placeholder,
  onChange,
  value,
  options,
  isSearchable,
  noDefaultValue,
  filterOption,
  isLoading,
  showValue = true,
  menuPlacement = 'bottom',
  ariaLabel,
  hideSelectedOptions = true,
  inputId,
}) => {
  const currentOption = options?.find(o => o.value === value);

  const getDefaultValue = (): BreadcrumbSelectOption | null => {
    if (noDefaultValue || !options) {
      return null;
    }

    return options[0];
  };

  return (
    <Select
      isMulti={false}
      className={className}
      placeholder={placeholder}
      classNamePrefix={classNamePrefix}
      onChange={onChange}
      value={currentOption}
      options={options}
      isSearchable={isSearchable}
      defaultValue={getDefaultValue()}
      components={{
        DropdownIndicator: BreadcrumbSelectDropdownIndicator,
        MenuList: SearchSelectMenuList,
        Option: SearchSelectOption,
      }}
      hideSelectedOptions={hideSelectedOptions}
      isOptionDisabled={option => !!option.disabled}
      filterOption={filterOption}
      isLoading={isLoading}
      menuPlacement={menuPlacement}
      controlShouldRenderValue={showValue}
      aria-label={ariaLabel}
      inputId={inputId}
    />
  );
};

export const BreadcrumbSelect = styled(BreadcrumbSelectBase)`
  font: ${props => props.theme.fonts.normalTextSemiBold};

  .${props => props.classNamePrefix}__control {
    padding: 0 10px 0 0;
    background: ${props => props.theme.colors.breadcrumb.default.backgroundColor};
    border: 1px solid ${props => props.theme.colors.breadcrumb.default.borderColor};
    border-radius: 5px;
    cursor: pointer;
    box-shadow: none;
    transition: none;
    min-height: 30px;
    width: auto;
    min-width: 150px;
    height: 30px;

    flex-wrap: nowrap;

    &--is-focused {
      border: 2px solid ${props => props.theme.colors.focus};
    }

    &:hover,
    &--menu-is-open {
      border-color: ${props => props.theme.colors.breadcrumb.hover.borderColor};
      background: ${props => props.theme.colors.breadcrumb.hover.backgroundColor};

      .${props => props.classNamePrefix}__dropdown-icon {
        fill: ${props => props.theme.colors.breadcrumb.hover.iconColor};
      }

      .${props => props.classNamePrefix}__single-value {
        color: ${props => props.theme.colors.breadcrumb.hover.textColor};
      }
    }

    &--menu-is-open {
      border-radius: ${props => (props.menuPlacement === 'top' ? '0 0 5px 5px' : '5px 5px 0 0')};
      border-width: ${props =>
        props.menuPlacement === 'top' ? '1px 1px 1px 1px' : '1px 1px 0px 1px'};
      padding: 0 10px 1px 0;

      .${props => props.classNamePrefix}__dropdown-indicator {
        transform: rotate(-180deg);
      }
    }
  }

  .${props => props.classNamePrefix}__value-container {
    padding: 0 0 0 8px;
    height: 30px;

    flex-wrap: nowrap;
    overflow: initial;
  }

  .${props => props.classNamePrefix}__dropdown-indicator {
    transition: transform 200ms ease;
    padding: 0;
  }

  .${props => props.classNamePrefix}__dropdown-icon {
    fill: ${props => props.theme.colors.breadcrumb.default.iconColor};
    height: 6px;
    width: 10px;
  }

  .${props => props.classNamePrefix}__indicator-separator {
    display: none;
  }

  .${props => props.classNamePrefix}__indicators {
    margin-left: 10px;
  }

  .${props => props.classNamePrefix}__single-value {
    color: ${props => props.theme.colors.breadcrumb.default.textColor};
    position: relative;
    top: 0;
    transform: none;
    text-overflow: initial;
    overflow: initial;
  }

  .${props => props.classNamePrefix}__input {
    color: ${props => props.theme.colors.breadcrumb.default.textColor};
    margin: 0;
    input {
      font: inherit;
    }
  }

  .${props => props.classNamePrefix}__menu-list {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    overflow-x: hidden;
  }

  .${props => props.classNamePrefix}__menu {
    margin-top: 0;
    margin-bottom: 0;
    z-index: 10;
    border-radius: ${props => (props.menuPlacement === 'top' ? '5px 5px 0 0' : '0 0 5px 5px')};
    border: solid ${props => props.theme.colors.breadcrumb.hover.borderColor};
    border-width: ${props => (props.menuPlacement === 'top' ? '1px 1px 0 1px' : '0 1px 1px 1px')};
    box-shadow: none;
    background-color: ${props => props.theme.colors.breadcrumb.menuBackgroundColor};
  }

  .${props => props.classNamePrefix}__option {
    color: ${props => props.theme.colors.breadcrumb.default.selectOptionColor};
    cursor: pointer;
    padding: 5px 12px;

    &--is-focused,
    &--is-selected {
      background: rgba(127, 207, 234, 0.2);
    }

    &--is-disabled {
      opacity: 0.5;
    }
  }

  .${props => props.classNamePrefix}__option:hover {
    background: ${props => props.theme.colors.select.hover.option};
  }

  .${props => props.classNamePrefix}__placeholder {
    color: ${props => props.theme.colors.select.default.placeholder};
  }
`;
