import { AZList } from 'Molecules/az/AZList';
import React, { FC } from 'react';
import { match } from 'react-router-dom';
import { useCountriesGrouped } from 'store/countriesStore/hooks';
import { useDiseasesGrouped } from 'store/diseasesStore/hooks';
import { useDrugsGrouped } from 'store/drugsStore/hooks';
import { useVaccinesGrouped } from 'store/vaccinesStore/hooks';

import { AZMicrobesDesktop } from './AZMicrobesDesktop';
import { AZMicrobesMobile } from './AZMicrobesMobile';

export interface Props {
  match: match;
  isMobile?: boolean;
  name: string;
}

export const AZDiseases: FC<Props> = ({ match, isMobile = false, name }) => {
  const diseases = useDiseasesGrouped();

  return (
    <AZList data={diseases} category="diseases" match={match} isMobile={isMobile} title={name} />
  );
};

export const AZDrugs: FC<Props> = ({ match, isMobile = false, name }) => {
  const drugs = useDrugsGrouped();

  return <AZList data={drugs} category="drugs" match={match} isMobile={isMobile} title={name} />;
};

export const AZVaccines: FC<Props> = ({ match, isMobile = false, name }) => {
  const vaccines = useVaccinesGrouped();

  return (
    <AZList data={vaccines} category="vaccines" match={match} isMobile={isMobile} title={name} />
  );
};

export const AZMicrobes: FC<Props> = ({ match, isMobile = false, name }) => {
  if (isMobile) {
    return <AZMicrobesMobile match={match} title={name} />;
  } else {
    return <AZMicrobesDesktop match={match} title={name} />;
  }
};

export const AZCountries: FC<Props> = ({ match, isMobile = false, name }) => {
  const countries = useCountriesGrouped();

  return (
    <AZList data={countries} category="countries" match={match} isMobile={isMobile} title={name} />
  );
};
