import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { MinWidth, TextAlign } from 'types/table';

interface Props {
  className?: string;
  minWidth?: MinWidth;
  children?: ReactNode;
  wrap?: boolean;
  textAlign?: TextAlign;
}

const TDBase: FC<Props> = ({ className, children }) => {
  return <td className={className}>{children}</td>;
};

export const TD = styled(TDBase)`
  min-width: ${props => (props.minWidth ? props.minWidth : 'unset')};
  white-space: ${props => (props.wrap ? 'normal' : 'nowrap')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  border: 1px solid ${props => props.theme.colors.table.border};
  color: ${props => props.theme.colors.table.row.text};
  padding: 8px;
`;
