import { isNotNull } from 'apiServices/common/guards';
import { DiseaseOutbreak, Outbreak } from 'types/disease';
import { DiseaseOutbreaksData, OutbreaksData } from 'types/map';

export const compareDiseaseOutbreak = (a: DiseaseOutbreak, b: DiseaseOutbreak): number => {
  if (!a.startYear && !b.endYear) {
    return 1;
  }

  const aYearToSortBy = a.endYear ? a.endYear : a.startYear;
  const bYearToSortBy = b.endYear ? b.endYear : b.startYear;

  if (aYearToSortBy > bYearToSortBy) {
    return -1;
  } else if (aYearToSortBy < bYearToSortBy) {
    return 1;
  } else {
    return 0;
  }
};

export const getMarkersFromDiseaseOutbreaks = (
  outbreakDetails: DiseaseOutbreak[]
): DiseaseOutbreaksData[] =>
  outbreakDetails
    .map((outbreak): DiseaseOutbreaksData | null => {
      const lat = outbreak.lat;
      const lng = outbreak.lng;
      const countryId = outbreak.countryId;
      const countryName = outbreak.countryName;

      if (!lat || !lng) {
        return null;
      }

      return {
        countryId,
        countryName,
        data: {
          ...outbreak,
          region: countryId === 'G292' && outbreak.state ? null : outbreak.region,
        },
        lat: parseFloat(lat),
        lng: parseFloat(lng),
        stateName: outbreak.state || undefined,
      };
    })
    .filter(isNotNull);

export const getMarkersFromOutbreaks = (outbreakDetails: Outbreak[]): OutbreaksData[] => {
  return outbreakDetails
    .map((outbreak): OutbreaksData | null => {
      const lat = outbreak.latitude;
      const lng = outbreak.longitude;
      const countryId = outbreak.countryId;
      const countryName = outbreak.countryName;

      if (!lat || !lng) {
        return null;
      }

      return {
        countryId,
        countryName,
        data: {
          ...outbreak,
          region: countryId === 'G292' && outbreak.state ? null : outbreak.region,
        },
        lat: parseFloat(lat),
        lng: parseFloat(lng),
        stateName: outbreak.state || undefined,
      };
    })
    .filter(isNotNull);
};
