import { ErrorPageImage } from 'Atoms/ErrorPageImage';
import { H1, H2, P } from 'Atoms/text';
import { ReportButton } from 'Molecules/buttons/ReportButton';
import React, { FC, ReactNode, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';
import { CustomError, Errors, isAxiosError } from 'types/errorTypes';

const ErrorPageStyled = styled.div`
  height: 100%;
  display: grid;
  flex: 1 0 auto;

  grid-template-columns: 70% 15% 15%;
  grid-template-rows: auto auto 50px;

  padding: 10% 0 0 4%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 10% 0 0 6%;
  }
`;

const GeneralContainer = styled.section`
  grid-row: 1 / 2;
  grid-column: 1 / 2;
`;

const ErrorPageImageStyled = styled(ErrorPageImage)`
  grid-column: 2 / 4;
  grid-row: 1 / 4;
  justify-self: end;
  align-self: end;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    grid-row: 2 / 3;
  }
`;

const ExplanationText = styled(P)`
  grid-row: 3 / 4;
  grid-column: 1 / 3;
  text-align: end;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    grid-column: 1 / 4;
    margin-right: 10px;
  }
`;

const TitleStyled = styled(H1)`
  text-align: unset;
  margin: 0 0 40px 0;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin: 0 0 20px 0;
  }
  @media (min-width: ${props => props.theme.breakpoints.s}) and (max-width: ${props =>
      props.theme.breakpoints.m}) {
    margin: 0 0 30px 0;
  }
`;

const SubTitleStyled = styled(H2)`
  text-align: left;
  margin: 0;
`;

const ReportContainer = styled.div`
  margin-top: 40px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 27px;
  }
`;

interface Props {
  error?: Errors;
}

export const ErrorPage: FC<Props> = ({ error }) => {
  const [reported, setReported] = useState<boolean>(false);

  const errorCode = useMemo((): string | number | undefined => {
    if (!error) {
      return;
    }

    if (isAxiosError(error)) {
      return error.response?.status;
    } else if (error instanceof CustomError) {
      return error.code;
    } else {
      return error?.name;
    }
  }, [error]);

  const errorContent = useMemo((): ReactNode => {
    if (errorCode?.toString() === '429') {
      return (
        <GeneralContainer>
          <TitleStyled
            weight="600"
            font="Inter"
            size="veryBig"
            color="errorTitle"
            lineHeight="unset"
          >
            You have exceeded the maximum number of times you can perform this action today.
          </TitleStyled>
        </GeneralContainer>
      );
    }

    return (
      <GeneralContainer>
        <TitleStyled weight="600" font="Inter" size="veryBig" color="errorTitle" lineHeight="unset">
          Oh no! You have discovered Disease X on our servers.
        </TitleStyled>
        <SubTitleStyled weight="600" size="medium" color="navbar" font="Inter">
          Report it to prevent an epidemic.
        </SubTitleStyled>
        <ReportContainer>
          {reported ? (
            <P weight="700" color="mainBold">
              Disaster averted!
            </P>
          ) : (
            <ReportButton onClick={() => setReported(true)}>Report</ReportButton>
          )}
        </ReportContainer>
      </GeneralContainer>
    );
  }, [errorCode, reported]);

  return (
    <ErrorPageStyled>
      <Helmet>
        <title>Error - GIDEON</title>
      </Helmet>
      {errorContent}
      <ErrorPageImageStyled />
      {errorCode && (
        <ExplanationText size="big" color="inactive">
          {errorCode}
        </ExplanationText>
      )}
    </ErrorPageStyled>
  );
};
