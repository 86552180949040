import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React from 'react';
import { DynamicContent } from 'types/dynamicContent';
import { Crossborders, Outbreaks, Survey } from 'types/surveyTables';
import { CellData, Column, Row } from 'types/table';
import { filterEmptyTableColumns } from 'utils/filterEmptyTableColumns';
import { formatNumber } from 'utils/formatNumber';
import { sortTableRows } from 'utils/sortTableRows';

const surveyColumns: Column[] = [
  { name: 'Years', isSortable: true, textAlign: 'right' },
  { name: 'Region', isSortable: true },
  { name: 'Study group', isSortable: true },
  { name: '%', isSortable: true, textAlign: 'right' },
  { name: 'Notes', enableWrap: true, minWidth: '300px' },
];

const outbreaksColumns: Column[] = [
  { name: 'Years', isSortable: true, textAlign: 'right' },
  { name: 'Region', isSortable: true },
  { name: 'Setting', isSortable: true },
  { name: 'Cases', isSortable: true, textAlign: 'right' },
  { name: 'Deaths', isSortable: true, textAlign: 'right' },
  { name: 'Source', isSortable: true },
  { name: 'Notes', enableWrap: true, minWidth: '300px' },
];

const crossBordersColumns: Column[] = [
  { name: 'Years', isSortable: true, textAlign: 'right' },
  { name: 'Acquired by **', isSortable: true },
  { name: 'Originated in **', isSortable: true },
  { name: 'Setting', isSortable: true },
  { name: 'Cases', isSortable: true, textAlign: 'right' },
  { name: 'Deaths', isSortable: true, textAlign: 'right' },
  { name: 'Notes', enableWrap: true, minWidth: '300px' },
];

type SurveyNumber = number | null;

const getYearsCell = (
  publication: SurveyNumber,
  start: SurveyNumber,
  end: SurveyNumber
): CellData => {
  if (publication !== null) {
    return { value: publication.toString(), label: `${publication} *` };
  }
  if (start !== null && end !== null) {
    return { value: start.toString(), label: `${start} - ${end}` };
  }
  if (start !== null) {
    return { value: start.toString(), label: `${start}` };
  }
  if (end !== null) {
    return { value: end.toString(), label: `${end}` };
  }

  return { value: '', label: '-' };
};

const getPercentCell = (percent1: SurveyNumber, percent2: SurveyNumber): CellData => {
  if (percent1 !== null && percent2 !== null) {
    return { value: percent1, label: `${percent1} - ${percent2}` };
  }
  if (percent1 !== null) return { value: percent1, label: percent1.toString() };
  if (percent2 !== null) return { value: percent2, label: percent2.toString() };
  return { value: '', label: '-' };
};

const getTextCell = (data: string | null): CellData => {
  if (data !== null) return { value: data.toLowerCase(), label: data.toString() };
  return { value: '', label: '-' };
};

const getNumberCell = (data: number | null): CellData => {
  if (data !== null) return { value: data, label: formatNumber(data) };
  return { value: '', label: '-' };
};

const getNotesCell = (notes: DynamicContent | null): CellData => {
  if (notes && notes.length > 0)
    return { value: 'notes', label: <DynamicHtml key="TableNotes" content={notes} /> };
  return { value: '', label: '-' };
};

const showPublicationYearFootnote = (data: { publicationYear: number | null }[]): boolean => {
  return data.some(d => d.publicationYear !== null);
};

export const getSurveyTableData = (data: Survey[]): [Column[], Row[], boolean] => {
  const initialRows = data.map(row => [
    getYearsCell(row.publicationYear, row.surveyStart, row.surveyEnd),
    getTextCell(row.region),
    getTextCell(row.population),
    getPercentCell(row.percent1, row.percent2),
    getNotesCell(row.notes),
  ]);
  const [columns, rows] = filterEmptyTableColumns(surveyColumns, initialRows);
  const sortedRows = sortTableRows(rows, true, 0);
  const showFootnote = showPublicationYearFootnote(data);

  return [columns, sortedRows, showFootnote];
};

export const getOutbreaksTableData = (data: Outbreaks[]): [Column[], Row[], boolean] => {
  const initialRows = data.map(row => [
    getYearsCell(row.publicationYear, row.outbreakStart, row.outbreakEnd),
    getTextCell(row.region),
    getTextCell(row.setting),
    getNumberCell(row.cases),
    getNumberCell(row.deaths),
    getTextCell(row.vehicle),
    getNotesCell(row.notes),
  ]);
  const [columns, rows] = filterEmptyTableColumns(outbreaksColumns, initialRows);
  const sortedRows = sortTableRows(rows, true, 0);
  const showFootnote = showPublicationYearFootnote(data);

  return [columns, sortedRows, showFootnote];
};

export const getCrossBordersTableData = (data: Crossborders[]): [Column[], Row[], boolean] => {
  const initialRows = data.map(row => [
    getYearsCell(row.publicationYear, row.startYear, row.endYear),
    getTextCell(row.acquisitionCountry),
    getTextCell(row.originationCountry),
    getTextCell(row.setting),
    getNumberCell(row.cases),
    getNumberCell(row.deaths),
    getNotesCell(row.notes),
  ]);
  const [columns, rows] = filterEmptyTableColumns(crossBordersColumns, initialRows);
  const showFootnote = showPublicationYearFootnote(data);

  return [columns, rows, showFootnote];
};
