import { getVaccineSynonymsPublic } from 'apiServices/Vaccines/vaccines';
import { ReactComponent as ContraindicationsIcon } from 'assets/Button/ContraindicationsIcon.svg';
import { ReactComponent as ToxicityIcon } from 'assets/Button/ToxicityIcon.svg';
import { ExploreLayout } from 'layouts/ExploreLayout';
import { SearchByTypeButton } from 'Molecules/buttons/SearchByTypeButton';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { LoginModalContext } from 'services/loginModal.service';
import { useContextAssert } from 'services/useContextAssert.hook';
import { useMobile } from 'services/useMobile';
import { useVaccinesList } from 'store/vaccinesStore/hooks';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';
import { mapToSearchSelectOption } from 'utils/mapResource';

const ButtonStyled = styled(SearchByTypeButton)`
  max-height: 44px;
  margin: 10px 10px 0;
  padding: 10px;

  opacity: 0.5;
`;

export const VaccinesPublic: FC = () => {
  const isMobile = useMobile('s');
  const vaccinesList = useVaccinesList();

  const vaccineOptions = mapToSearchSelectOption(vaccinesList);

  const { setOpen } = useContextAssert(LoginModalContext);

  const loadSynonymOptions = async (inputValue: string): Promise<SelectOption[]> => {
    const synonyms = await getVaccineSynonymsPublic(inputValue);

    return synonyms
      .filter(s => vaccineOptions.some(so => so.value === s.id.toString()))
      .map(x => ({
        label: x.name,
        value: x.id.toString(),
        synonymOf: vaccinesList.find(vaccine => vaccine.id === x.id)?.name,
      }));
  };

  return (
    <>
      <Helmet>
        <title>Explore vaccines - GIDEON</title>
        <meta
          name="description"
          content="GIDEON vaccines database contains data on vaccines and immunoglobulins. It also includes tools to find vaccines by toxicity and contraindications."
        />
      </Helmet>
      <ExploreLayout
        title="Explore GIDEON data on vaccines and immunoglobulins"
        imageName="vaccines"
        placeholder={isMobile ? 'Enter generic | trade name' : 'Start typing generic or trade name'}
        section="vaccines"
        defaultOptions={vaccineOptions}
        loadSynonymOptions={loadSynonymOptions}
        optionTitle="Search by..."
        selectLabel="Filter and search across all vaccine records"
      >
        <ButtonStyled Icon={ToxicityIcon} onClick={() => setOpen(true)}>
          Toxicity
        </ButtonStyled>
        <ButtonStyled Icon={ContraindicationsIcon} onClick={() => setOpen(true)}>
          Contraindications
        </ButtonStyled>
      </ExploreLayout>
    </>
  );
};
