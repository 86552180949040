import { Label } from 'Atoms/Label';
import { H1 } from 'Atoms/text';
import { MapMainLayout } from 'layouts/MapMainLayout';
import { AsyncSelect } from 'Molecules/select/AsyncSelect';
import { CountriesOutbreaksPublicMap } from 'Organisms/outbreaksMap/CountriesOutbreaksPublicMap';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { OnChangeValue } from 'react-select';
import { useCountrySelectOptions } from 'services/useCountrySelectOptions';
import { usePushState } from 'services/usePushState.hook';
import styled from 'styled-components/macro';
import { CountrySelectOption } from 'types/country';
import { RecordLocationState } from 'types/record';
import { countriesSelectFilter, onCountryInputChange } from 'utils/countriesSelectFilter';

const SelectStyled = styled(AsyncSelect<CountrySelectOption, false>())`
  && {
    position: absolute;
    top: 250px;
    left: 0;
    right: 0;

    max-width: 400px;
    margin: 0 auto;
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      width: 98%;
    }
  }

  z-index: 100;
`;

const Title = styled(H1)`
  margin: 0;
  background: ${props => props.theme.colors.countriesTitle};

  display: inline;
  position: absolute;
  top: 75px;

  left: 50%;
  transform: translateX(-50%);

  border-radius: 4px;
  padding: 5px;

  z-index: 100;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    top: 125px;
  }
`;

const LabelStyled = styled(Label)`
  position: absolute;

  top: 150px;
  left: 50%;
  transform: translateX(-50%);

  z-index: 100;
  border-radius: 4px;
  padding: 5px;
  background: ${props => props.theme.colors.countriesTitle};

  white-space: nowrap;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    top: 200px;
  }
`;
const Container = styled(MapMainLayout)`
  min-height: 800px;
`;

export const CountriesPublic: FC = () => {
  const { push } = usePushState();
  const { countriesOptions, loadCountriesOptions } = useCountrySelectOptions();

  const onChange = (selected: OnChangeValue<CountrySelectOption, false>): void => {
    if (!selected) {
      return;
    }

    const pushState: RecordLocationState = {
      synonymName: selected.label,
    };

    push(`/explore/countries/${selected.value}`, selected.synonymOf ? pushState : undefined);
  };

  return (
    <Container>
      <Helmet>
        <title>Explore countries - GIDEON</title>
        <meta
          name="description"
          content="Explore epidemiological data covering 235 countries and territories. Includes an interactive global outbreaks map for each year between now to 1900."
        />
      </Helmet>
      <Title>Explore countries</Title>
      <LabelStyled size="small" color="main" htmlFor="explore-select-input">
        Filter and search across all country records
      </LabelStyled>
      <SelectStyled
        classNamePrefix="explore-countries-select"
        placeholder="Search country records"
        loadOptions={loadCountriesOptions}
        onChange={onChange}
        onInputChange={value => onCountryInputChange(value, countriesOptions)}
        showIcon
        cacheOptions
        defaultOptions={countriesOptions}
        showDropdown
        openMenuOnClick={false}
        filterOption={countriesSelectFilter}
        aria-label="Country"
        inputId="explore-select-input"
      />
      <CountriesOutbreaksPublicMap />
    </Container>
  );
};
