import { Action as SimpleAction, ActionWithPayload } from 'store/types';
import { ComparisonLists } from 'types/comparisonLists';

export type ComparisonSection = Omit<State, 'activeSection'>;

export type ComparisonSectionKeys = keyof ComparisonSection;

type ComparePayload = {
  section: keyof ComparisonSection;
  id: number;
};

export type Action =
  | ActionWithPayload<'ComparisonLists/AddToCompare', ComparePayload>
  | ActionWithPayload<'ComparisonLists/RemoveFromCompare', ComparePayload>
  | ActionWithPayload<'ComparisonLists/ClearList', keyof State>
  | ActionWithPayload<'ComparisonLists/SetActiveSection', ComparisonSectionKeys>
  | SimpleAction<'ComparisonLists/Reset'>;

export type State = ComparisonLists & {
  activeSection: ComparisonSectionKeys | null;
};

export const defaultInitialState: State = {
  diseases: [],
  drugs: [],
  bacteria: [],
  mycobacteria: [],
  yeasts: [],
  virus: [],
  activeSection: null,
};
