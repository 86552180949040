import { CompareButtonsSection } from 'Molecules/compare/CompareButtonsSection';
import { CompareMicrobeInformation } from 'Molecules/compare/CompareMicrobeInformation';
import { CompareMicrobeOptions } from 'Molecules/compare/CompareMicrobeOptions';
import { CompareTopCaption } from 'Molecules/compare/CompareTopCaption';
import { CompareBacteriaTable } from 'Organisms/compare/CompareBacteriaTable';
import { CompareTable } from 'Organisms/compare/CompareTable';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { OnChangeValue } from 'react-select';
import {
  getCompareRows,
  getProbability,
  probabilities,
} from 'services/compare/getCompareMicrobeData';
import { useCompareList, useState as useCompareListState } from 'store/ComparisonListsStore/hooks';
import { assertIsNotStoreError, isStoreError } from 'store/storeError';
import { isLoading, Resource } from 'store/types';
import {
  Column,
  CompareColumnDefinition,
  CompareMicrobe as CompareMicrobeType,
  ProbabilityType,
} from 'types/compare';
import { MicrobeType } from 'types/microbeDetails';
import { SelectOption } from 'types/select';
import { MicrobeLists } from 'types/simpleMicrobe';

interface Props {
  microbeType: MicrobeType;
  microbes: Resource<MicrobeLists>;
  comparedMicrobes: Resource<CompareMicrobeType>[];
}

export const CompareMicrobe: FC<Props> = ({ microbeType, microbes, comparedMicrobes }) => {
  const [showMatches, setShowMatches] = useState<boolean>(false);

  const [probabilityType, setProbabilityType] = useState<ProbabilityType>('words');
  const { toggleComparison, isFull, clearList, isItemInList } = useCompareList(microbeType);
  const compareListState = useCompareListState();

  const compareState = compareListState[microbeType];

  assertIsNotStoreError(microbes);

  if (isLoading(microbes)) {
    return (
      <CompareTopCaption
        countText={`${compareState.length} ${microbeType}`}
        options={[]}
        isListFull={isFull}
        isLoading
        compareCategory={microbeType}
      />
    );
  }

  const microbeList = microbes[microbeType];

  const tableEmpty = comparedMicrobes.length === 0;

  const selectOptions = microbeList.map(x => ({
    value: x.id.toString(),
    label: x.name,
    disabled: isItemInList(x.id),
  }));

  const addToCompare = (selected: OnChangeValue<SelectOption, false>): void => {
    if (selected) {
      toggleComparison(parseInt(selected.value));
    }
  };

  if (tableEmpty) {
    return (
      <CompareTopCaption
        countText={`${compareState.length} ${microbeType}`}
        options={selectOptions}
        onChange={addToCompare}
        compareCategory={microbeType}
      />
    );
  }

  const onProbabilityTypeChange = (selected: OnChangeValue<SelectOption, false>): void => {
    if (selected) {
      setProbabilityType(selected.value as ProbabilityType);
    }
  };

  const colsData: Column[] = comparedMicrobes.map(x => {
    if (isStoreError(x)) {
      throw new Error(`Failed to retrieve ${microbeType}`);
    }

    if (isLoading(x) || x === undefined) {
      return 'Loading';
    }

    return {
      id: x.id,
      category: `microbes/${microbeType}`,
      name: microbeList.find(y => y.id === x.id)?.name || '',
    };
  });

  const mappedColumns: CompareColumnDefinition[] = colsData.map(x => {
    return {
      column: x,
      enableWrap: true,
    };
  });

  const showAll = compareState.length === 1 ? true : showMatches;
  const filterSameGroup = probabilityType === 'words' && compareState.length > 1;
  const rows = getCompareRows(comparedMicrobes, showAll, filterSameGroup);

  const getTitle = (): string => {
    if (microbeType === 'bacteria') {
      return 'Compare bacteria - GIDEON';
    } else if (microbeType === 'mycobacteria') {
      return 'Compare mycobacteria - GIDEON';
    } else {
      return 'Compare yeasts - GIDEON';
    }
  };

  const getDescription = (): string => {
    if (microbeType === 'bacteria') {
      return 'Generate a side-by-side phenotypic comparison table of 1,800+ bacteria species. Download the table or share your insights with peers!';
    } else if (microbeType === 'mycobacteria') {
      return 'Generate a side-by-side phenotypic comparison table of 150+ mycobacteria species. Download the table or share your insights with peers!';
    } else {
      return 'Generate a side-by-side phenotypic comparison table of 130+ yeasts and algae species. Download the table or share your insights with peers!';
    }
  };

  return (
    <>
      <Helmet>
        <title>{getTitle()}</title>
        <meta name="description" content={getDescription()} />
      </Helmet>
      <CompareTopCaption
        countText={`${compareState.length} ${microbeType}`}
        options={selectOptions}
        onChange={addToCompare}
        isListFull={isFull}
        onClear={clearList}
        compareCategory={microbeType}
      />
      <CompareMicrobeOptions
        onProbabilityChange={onProbabilityTypeChange}
        probabilities={probabilities}
        showMatches={showAll}
        onSwitchChange={() => setShowMatches(!showMatches)}
      />

      {microbeType === 'bacteria' ? (
        <CompareBacteriaTable
          columns={tableEmpty ? [] : mappedColumns}
          rows={rows}
          probabilityType={probabilityType}
          onRemove={toggleComparison}
        />
      ) : (
        <CompareTable
          columns={tableEmpty ? [] : mappedColumns}
          rows={rows}
          probabilityType={probabilityType}
          firstColumnTitle="Tests"
          onRemove={toggleComparison}
          getProbability={getProbability}
        />
      )}
      <CompareMicrobeInformation selectedIds={compareState} type={microbeType} />
      <CompareButtonsSection category={microbeType} ids={compareState} />
    </>
  );
};
