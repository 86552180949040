import { Link } from 'Atoms/links/Link';
import { Switch } from 'Atoms/Switch';
import { H1, H2, Span } from 'Atoms/text';
import { SpecialLink } from 'Auth/Molecules/Styles';
import { AccessExpiration, AccessExpired } from 'Organisms/AccessExpiration';
import { SignInForm } from 'Organisms/SignInForm';
import React, { FC, useCallback, useContext, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ThemeModeContext } from 'services/theme';
import { ErrorFields, useAccountController } from 'store/accountStore/hooks';
import styled, { ThemeContext } from 'styled-components/macro';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  padding: 50px 0;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 90%;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    height: unset;
    padding: 20px 0;
  }
`;

const TopContent = styled.div`
  margin: 75px 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin: 20px 0 50px;
  }
`;

const StyledH1 = styled(H1)`
  margin-bottom: 10px;
`;

const StyledH2 = styled(H2)`
  margin: 0;
`;

const Logo = styled.img`
  width: 45px;
  height: 50px;
`;

const SwitchContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    justify-content: center;
  }
`;

const SignInFormStyled = styled(SignInForm)`
  margin-top: 100px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 50px;
  }
`;

const MainStyled = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Banner = styled.div`
  width: 100%;
`;

export const Login: FC = () => {
  const { isDarkMode, setIsDarkMode } = useContext(ThemeModeContext);
  const toggleTheme = useCallback(() => setIsDarkMode(!isDarkMode), [isDarkMode, setIsDarkMode]);
  const theme = useContext(ThemeContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [accountExpired, setAccountExpired] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ErrorFields>({
    username: null,
    password: null,
    form: null,
  });
  const [isSigningIn, setIsSigningIn] = useState(false);

  const usernameInput = useRef<HTMLInputElement>(null);
  const passwordInput = useRef<HTMLInputElement>(null);

  const {
    signIn,
    isLoading: authenticationIsLoading,
    isAuthenticated,
    ipLoginStatus,
  } = useAccountController();

  const isFormLoading = isSigningIn || authenticationIsLoading;
  const isPageLoading = authenticationIsLoading || ipLoginStatus === 'initial';

  if (accountExpired) {
    return <AccessExpired />;
  }

  if (isAuthenticated) {
    return <AccessExpiration />;
  }

  return (
    <Wrapper>
      <Helmet>
        <title>Sign in - GIDEON</title>
      </Helmet>
      <Banner role="banner">
        <SwitchContainer>
          <Switch
            label={`Switch to ${isDarkMode ? 'light' : 'dark'} mode`}
            checked={!isDarkMode}
            onClick={toggleTheme}
          />
        </SwitchContainer>
        <TopContent>
          <Logo src={theme.images.mobileLogo} alt="Gideon logo" />
          <StyledH1 size="big" weight="700" color="lightLink">
            Access GIDEON
          </StyledH1>
          <StyledH2 color="inactive" weight="500" size="regular">
            the most comprehensive infectious diseases database in the world
          </StyledH2>
        </TopContent>
      </Banner>
      <MainStyled>
        <SpecialLink as={Link} color="special" to="/start" noUnderline>
          <Span weight="500">I’m new to GIDEON. Let me look around</Span>
        </SpecialLink>
        <SignInFormStyled
          onSubmit={e =>
            signIn(e, {
              username,
              password,
              isFormLoading,
              setAccountExpired,
              setErrorMessage,
              setIsSigningIn,
              usernameInput,
              passwordInput,
            })
          }
          errorMessage={errorMessage}
          onUsernameChange={value => {
            setUsername(value);
            setErrorMessage({ ...errorMessage, username: null, form: null });
          }}
          onPasswordChange={value => {
            setPassword(value);
            setErrorMessage({ ...errorMessage, password: null, form: null });
          }}
          username={username}
          password={password}
          isFormLoading={isFormLoading}
          isPageLoading={isPageLoading}
          usernameInput={usernameInput}
          passwordInput={passwordInput}
        />
      </MainStyled>
    </Wrapper>
  );
};
