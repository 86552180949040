import { Loader } from 'Atoms/Loader';
import { P } from 'Atoms/text';
import { ShadowButton } from 'Molecules/buttons/ShadowButton';
import { HorizontalScrollbarShadows } from 'Organisms/HorizontalScrollbarShadows';
import {
  GraphsTableHead,
  TableHeaderColumn,
  TableHeaderRow,
} from 'Quantify/Molecules/GraphsTableHead';
import { ColumnHeader } from 'Quantify/types/graphTable';
import React, { FC, useMemo, useState } from 'react';
import { CellMeasurerCache } from 'react-virtualized';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { Row, SortState } from 'types/table';
import { LatestDataTableBody } from 'Updates/Molecules/LatestDataTableBody';
import { getLatestDataRows } from 'Updates/services/getLatestDataRows';
import { UpdatedField } from 'Updates/types/updatedField';
import { sortTableRows } from 'utils/sortTableRows';

const StyledP = styled(P)`
  margin: 15px auto 0;
  width: 80%;
  text-align: center;
  line-height: 25px;
`;

export const HorizontalScrollbarShadowsStyled = styled(HorizontalScrollbarShadows)`
  width: 100%;
  z-index: 1;
  ${ShadowButton} {
    z-index: 400;
  }
`;

export const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 1px 3px rgba(0, 0, 0, 0.12);
  min-width: 360px;
`;

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 20px;
`;

const LoaderWrapper = styled.div`
  min-height: 120px;
`;

const TableHead = styled(GraphsTableHead)`
  ${TableHeaderRow} {
    border-top: none;
    border-left: none;
    border-right: none;
  }

  ${TableHeaderColumn}:nth-child(1) {
    width: 15%;
    min-width: 65px;
    padding-left: 3px;
  }

  ${TableHeaderColumn}:nth-child(2) {
    width: 20%;
    min-width: 105px;
  }
`;

interface Props {
  className?: string;
  columns: ColumnHeader[];
  data: UpdatedField[] | 'Loading' | null;
  total?: number;
  cache: CellMeasurerCache;
  clearCache: () => void;
}

export const LatestDataTable: FC<Props> = ({ className, columns, data, cache, clearCache }) => {
  const [sort, setSort] = useState<SortState>({ asc: false, columnIndex: 0 });

  const onClickSort = (index: number): void => {
    setSort({ asc: index === sort.columnIndex ? !sort.asc : true, columnIndex: index });
    clearCache();
  };

  const actualRows = useMemo((): Row[] => {
    if (!isLoading(data) && data) {
      const rows = getLatestDataRows(data);
      const sortedRows = sortTableRows(rows, sort.asc, sort.columnIndex);

      return sortedRows;
    }
    return [];
  }, [sort.asc, sort.columnIndex, data]);

  return (
    <HorizontalScrollbarShadowsStyled className={className} fullHeight>
      <TableContainer role="table">
        <TableHead
          columns={columns}
          onClickSort={onClickSort}
          asc={sort.asc}
          columnIndex={sort.columnIndex}
        />
        {actualRows.length > 0 ? (
          <LatestDataTableBody rows={actualRows} cache={cache} />
        ) : isLoading(data) ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          <StyledP color="inactive" size="medium">
            GIDEON does not have any updates for your selected periods. Try selecting a different
            period.
          </StyledP>
        )}
      </TableContainer>
    </HorizontalScrollbarShadowsStyled>
  );
};
